import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Select,
  TextareaAutosize,
  FormLabel,
  MenuItem,
  InputLabel,
  FormControl,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  IconButton,
  Breadcrumbs,
  Alert,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import * as yup from "yup";
import { PermissionContext } from "app/contexts/PermissionContext";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import axios from "axios";
import { consultarCEP } from "app/utils/consultarCEP";
import { tiposArquivos } from "app/utils/tiposArquivos";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const validationSchema = yup.object().shape({
  descricao: yup
    .string("Insira o nome do cliente")
    .required("Preenchimento obrigatório"),
  cep: yup
    .string()
    .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP Inválido")
    .nullable(),
  documento: yup
    .string()
    .max(18)
    .nullable()
    .typeError("O documento deve ter no máximo 18 caracteres"),
  cadastro: yup.string().nullable(),
  tipo_documento: yup.string().required("Campo obrigatório"),
  cpf: yup
    .string()
    .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, {
      message: "CPF Inválido",
      excludeEmptyString: true,
    }),
    // .nullable()
    // .when("tipo_documento", {
    //   is: "fisica",
    //   then: yup.string().required("Campo obrigatório"),
    // }),
  cnpj: yup
    .string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
      message: "CNPJ Inválido",
      excludeEmptyString: true,
    })
    // .nullable()
    // .when("tipo_documento", {
    //   is: "juridica",
    //   then: yup.string().required("Campo obrigatório"),
    // }),
});

const validationSchemaEdit = yup.object().shape({
  descricao: yup
    .string("Insira o nome do imóvel")
    .required("Preenchimento obrigatório"),
  cep: yup
    .string()
    .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP Inválido")
    .nullable(),
  documento: yup
    .string()
    .max("18")
    .nullable()
    .typeError("O documento deve ter no máximo 18 caracteres"),
  cadastro: yup.string().nullable(),
  tipo_documento: yup.string().required("Campo obrigatório"),
  cpf: yup
    .string()
    .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, {
      message: "CPF Inválido",
      excludeEmptyString: true,
    })
    .nullable()
    .when("tipo_pessoa", {
      is: "fisica",
      then: yup.string().required("CPF é obrigatório para pessoa física"),
    }),
  cnpj: yup
    .string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
      message: "CNPJ Inválido",
      excludeEmptyString: true,
    })
    .nullable()
    .when("tipo_pessoa", {
      is: "juridica",
      then: yup.string().required("CNPJ é obrigatório para pessoa jurídica"),
    }),
});

const ESTADOS = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

const ImovelClienteForm = () => {
  const initialValues = {
    descricao: "",
    cep: "",
    bairro: "",
    logradouro: "",
    cidade: "",
    numero: "",
    complemento: "",
    uf: "",
    documento: "",
    cadastro: "",
    tipo_documento: "fisica",
    cpf: "",
    cnpj: "",
  };
  const { id, clienteId, unidadeId } = useParams();
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [imovel, setImovel] = useState(initialValues);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [formattedCEP, setFormatedCEP] = useState("");
  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const formikRef = useRef();
  const [formattedCPF, setFormattedCPF] = useState("");
  const [formattedCNPJ, setFormattedCNPJ] = useState("");
  const [isDocumentoAnexado, setIsDocumentoAnexado] = useState(false);
  const [documentoSelecionado, setDocumentosSelecionado] = useState(null);

  const location = useLocation();
  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Clientes", "create")) {
    navigate("/app/listar-clientes");
  }

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteImovel(id);
      }
    });
  };

  const deleteImovel = useCallback(async (id) => {
    try {
      ApiService.delete(`/imoveis/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          navigate(`/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis`);
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getImovel = useCallback(async () => {
    try {
      ApiService.get(`/imoveis/${id}`)
        .then((response) => {
          if (response.status === 200) {
            setImovel({
              bairro: response.data?.bairro,
              cep: response.data?.cep,
              cidade: response.data?.cidade,
              complemento: response.data?.complemento,
              descricao: response.data?.descricao,
              logradouro: response.data?.logradouro,
              numero: response.data?.numero,
              uf: response.data?.uf,
              documento: response.data?.documento,
              documento_unidade: response.data?.documento_unidade,
              cadastro: response.data?.cadastro,
              tipo_documento: response.data?.tipo_documento,
            });

            response.data?.tipo_documento === "fisica"
              ? setFormattedCPF(response.data?.documento)
              : setFormattedCNPJ(response.data?.documento);
            setFormatedCEP(response.data?.cep);

            if (response.data?.documento_unidade !== "") {
              setIsDocumentoAnexado(true);
              setDocumentosSelecionado({
                name: response.data?.documento_unidade,
              });
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getImovel();
    }
    if (location && String(location.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [id]);

  const getEstados = useCallback(async () => {
    try {
      await axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then((response) => {
          if (response.status === 200) {
            setEstados(response.data);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getMunicipios = useCallback(
    async (sigla) => {
      await axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios
  `
        )
        .then((response) => {
          if (response.status === 200) {
            setMunicipios(response.data);
          }
        });
    },
    [estados]
  );

  const handleChangeCEP = async (cep) => {
    formikRef.current.setFieldValue("cep", cep);
    if (cep.length === 9) {
      try {
        const cepConsultado = await consultarCEP(cep);
        if (cepConsultado) {
          formikRef.current.setFieldValue(
            "uf",
            cepConsultado.dados.stateShortname
          );
          await getMunicipios(cepConsultado.dados.stateShortname);
          formikRef.current.setFieldValue("cidade", cepConsultado.dados.city);
          formikRef.current.setFieldValue(
            "logradouro",
            cepConsultado.dados.street
          );
          formikRef.current.setFieldValue(
            "bairro",
            cepConsultado.dados.district
          );
        }
        setFormatedCEP(
          String(cep)
            .slice(0, 5)
            .concat(`-${String(cep).slice(5, 9)}`)
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDocumentoAnexado = (event) => {
    const documento = event.target.files[0];
    console.log(documento);
    if (documento) {
      setDocumentosSelecionado(documento);
      setIsDocumentoAnexado(true);
    }
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const allValuesEdit = {
      ...values,
    };
    delete allValuesEdit.id;
    delete allValuesEdit.documento;

    const chavesValorValuesEdit = Object.entries(allValuesEdit);

    const formData = new FormData();

    for (const values of chavesValorValuesEdit) {
      formData.append(values[0], values[1]);
    }

    formData.append("clienteId", Number(clienteId));
    formData.append("unidadeId", Number(unidadeId));
    formData.append(
      "documento",
      values.tipo_documento === "fisica" ? formattedCPF : formattedCNPJ
    );

    console.log(formData.get("clienteId"));

    if (documentoSelecionado && documentoSelecionado.type) {
      console.log("Documento Selecionado:", documentoSelecionado);
      formData.append("documento_unidade", documentoSelecionado);
    }

    if (id) {
      ApiService.put(`/imoveis/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          toast("success", "Atualizado com sucesso!");
          if (response.status === 200) {
            navigate(`/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis`);
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/imoveis", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          toast("success", "Criado com sucesso");
          resetForm();
          if (response.status === 201) {
            navigate(`/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis`);
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setSubmitting(false);
  };

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/clientes/breadcrumbs?clienteId=${clienteId}&unidadeId=${unidadeId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBreadCrumbsInfo();
    getEstados();
  }, [unidadeId]);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Stack width={"100%"}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to={"/app/listar-clientes"} color="success" style={linkStyle}>
            Clientes
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/editar-cliente/${clienteId}`}
              color="success"
              style={linkStyle}
            >
              {breadCrumbsInfo?.nome}
            </Link>
          )}
          <Link
            to={`/app/cliente/unidades/${clienteId}`}
            color="primary"
            style={linkStyle}
          >
            Unidades
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/unidade/${unidadeId}`}
              color="primary"
              style={linkStyle}
            >
              {breadCrumbsInfo?.unidades?.[0]?.nomeUnidade}
            </Link>
          )}
          <Link
            to={`/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis`}
            color="primary"
            style={linkStyle}
          >
            Imóveis
          </Link>
          <Link
            to={
              id
                ? `/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis/novo`
                : `/app/cliente/${clienteId}/unidade/${unidadeId}/imovel/${id}/editar`
            }
            color="primary"
            style={linkStyle}
          >
            {isDeleteMode
              ? "Deletar imóvel"
              : id
              ? "Editar Imóvel"
              : "Novo imóvel"}
          </Link>
        </Breadcrumbs>
      </Stack>
      {isDeleteMode && (
        <Stack width={"100%"} p={1}>
          <Alert severity="error">
            Você está prestes a excluir o imóvel abaixo:
          </Alert>
        </Stack>
      )}
      <Box my={5}>
        <Formik
          innerRef={formikRef}
          initialValues={imovel}
          validationSchema={id ? validationSchemaEdit : validationSchema}
          enableReinitialize
          validateOnChange={true}
          validateOnBlur
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            updateInputValue,
            setFieldValue,
            errors,
          }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid container width={"100%"}>
                <Paper
                  style={{ p: 2, my: "10px", width: "100%" }}
                  elevation={1}
                >
                  <Typography variant="h4" fontWeight={500} m={3}>
                    Dados do imóvel
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    padding={2}
                    alignContent={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item lg={3} xs={12}>
                      <TextField
                        fullWidth
                        name="descricao"
                        label="Nome / Descrição"
                        required
                        disabled={isDeleteMode}
                        value={values.descricao}
                        onChange={handleChange}
                        sx={{ background: "#ffffff" }}
                      />
                      {errors.descricao && (
                        <div style={{ color: "red" }}>{errors.descricao}</div>
                      )}
                    </Grid>
                    <Grid item lg={2} xs={12}>
                      <FormGroup>
                        <FormLabel id="tipo-documento-label">
                          Tipo documento
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="tipo-documento-label"
                          value={values.tipo_documento}
                          name="tipo_documento"
                          onChange={(event) => {
                            setFieldValue("tipo_documento", event.target.value);
                            setFieldValue("cpf", "");
                            setFieldValue("cnpj", "");
                          }}
                          row
                        >
                          <FormControlLabel
                            value="fisica"
                            control={<Radio color="success" />}
                            label="CPF"
                          />
                          <FormControlLabel
                            value="juridica"
                            control={<Radio color="success" />}
                            label="CNPJ"
                          />
                        </RadioGroup>
                      </FormGroup>
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      {values.tipo_documento === "fisica" ? (
                        <>
                          <ReactInputMask
                            mask="999.999.999-99"
                            maskChar=""
                            value={formattedCPF}
                            onChange={(e) => {
                              setFormattedCPF(e.target.value);
                              setFieldValue("cpf", e.target.value);
                            }}
                          >
                            {(inputProps) => (
                              <TextField
                                fullWidth
                                name="cpf"
                                label="CPF"
                                sx={{ background: "#ffffff" }}
                                {...inputProps}
                              />
                            )}
                          </ReactInputMask>
                          <ErrorMessage
                            component={"div"}
                            name="cpf"
                            style={{ color: "red" }}
                          />
                        </>
                      ) : (
                        <>
                          <ReactInputMask
                            mask="99.999.999/9999-99"
                            maskChar=""
                            value={formattedCNPJ}
                            onChange={(e) => {
                              setFormattedCNPJ(e.target.value);
                              setFieldValue("cnpj", e.target.value);
                            }}
                          >
                            {(inputProps) => (
                              <TextField
                                fullWidth
                                name="cnpj"
                                label="CNPJ"
                                sx={{ background: "#ffffff" }}
                                {...inputProps}
                              />
                            )}
                          </ReactInputMask>
                          {errors.cnpj && (
                            <div style={{ color: "red" }}>{errors.cnpj}</div>
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <TextField
                        fullWidth
                        name="cadastro"
                        label="Matrícula"
                        disabled={isDeleteMode}
                        value={values.cadastro}
                        onChange={handleChange}
                        sx={{ background: "#ffffff" }}
                      />
                      {errors.cadastro && (
                        <div style={{ color: "red" }}>{errors.cadastro}</div>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid container width="100%" my={2}>
                <Paper elevation={1} sx={{ p: 3, my: 2, width: "100%" }}>
                  <Typography variant="h4" fontWeight={500} mb={3}>
                    Documento de posse
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    padding={2}
                    justifyContent="center"
                    alignItems={"center"}
                    flexDirection={"column"}
                  >
                    <Grid
                      item
                      lg={5}
                      xs={12}
                      my={2}
                      display="flex"
                      justifyContent="center"
                    >
                      <label
                        htmlFor="upload-button-file"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <input
                          id="upload-button-file"
                          name="documento"
                          hidden
                          accept="image/*, .pdf, .docx, .xls"
                          type="file"
                          onChange={handleDocumentoAnexado}
                        />
                        <Button
                          variant="contained"
                          color="info"
                          disabled={isDeleteMode}
                          component="span"
                        >
                          Fazer upload
                        </Button>
                        <ErrorMessage
                          component="div"
                          name="arquivo"
                          style={{ color: "red", marginTop: "2px" }}
                        />
                      </label>
                    </Grid>

                    {isDocumentoAnexado && (
                      <Grid
                        item
                        lg={10}
                        xs={12}
                        my={1}
                        display="flex"
                        justifyContent="center"
                      >
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          borderRadius={1}
                          sx={{
                            p: 2,
                            border: "1px solid #0e0e0e",
                          }}
                        >
                          <Box
                            sx={{
                              background:
                                tiposArquivos[
                                  String(documentoSelecionado?.name).split(
                                    "."
                                  )[1]
                                ]?.cor,
                              color: "#FFF",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "50px",
                              height: "50px",
                            }}
                            borderRadius={1}
                          >
                            {
                              tiposArquivos[
                                String(documentoSelecionado?.name).split(".")[1]
                              ]?.nome
                            }
                          </Box>
                          <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              window.open(
                                `${process.env.REACT_APP_API_KEY}/${documentoSelecionado?.name}`,
                                "_blank"
                              );
                            }}
                          >
                            {documentoSelecionado?.name.includes("\\") ||
                            documentoSelecionado?.name.includes("/")
                              ? documentoSelecionado.name.split(/(\\|\/)/).pop()
                              : documentoSelecionado?.name}
                          </Box>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>

              <Grid container width={"100%"}>
                <Paper my={2} width={"100%"}>
                  <Typography variant="h4" fontWeight={500} m={3}>
                    Endereço do imóvel
                  </Typography>
                  <Grid
                    container
                    spacing={2}
                    padding={2}
                    alignContent={"center"}
                    justifyContent={"center"}
                  >
                    <Grid item lg={3} xs={12}>
                      <ReactInputMask
                        mask="99999-999"
                        value={formattedCEP}
                        disabled={isDeleteMode}
                        onChange={(e) => {
                          handleChangeCEP(e.target.value);
                          setFormatedCEP(e.target.value);
                          setFieldValue("cep", e.target.value);
                        }}
                      >
                        {(inputProps) => (
                          <TextField
                            fullWidth
                            disabled={isDeleteMode}
                            name="cep"
                            label="CEP"
                            sx={{ background: "#ffffff" }}
                            {...inputProps}
                          />
                        )}
                      </ReactInputMask>
                      {errors.cep && (
                        <div style={{ color: "red" }}>{errors.cep}</div>
                      )}
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <TextField
                        fullWidth
                        disabled={isDeleteMode}
                        name="logradouro"
                        label="Logradouro"
                        value={values.logradouro}
                        onChange={handleChange}
                        sx={{ background: "#ffffff" }}
                      />
                    </Grid>
                    <Grid item lg={2} xs={12}>
                      <TextField
                        fullWidth
                        name="numero"
                        disabled={isDeleteMode}
                        label="Número"
                        value={values.numero}
                        onChange={handleChange}
                        sx={{ background: "#ffffff" }}
                      />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <TextField
                        fullWidth
                        disabled={isDeleteMode}
                        name="complemento"
                        label="Complemento"
                        value={values.complemento}
                        onChange={handleChange}
                        sx={{ background: "#ffffff" }}
                      />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <TextField
                        fullWidth
                        disabled={isDeleteMode}
                        name="bairro"
                        label="Bairro"
                        value={values.bairro}
                        onChange={handleChange}
                        sx={{ background: "#ffffff" }}
                      />
                    </Grid>
                    <Grid item lg={3} xs={12}>
                      <TextField
                        fullWidth
                        name="cidade"
                        disabled={isDeleteMode}
                        label="Cidade"
                        value={values.cidade}
                        onChange={handleChange}
                        sx={{ background: "#ffffff" }}
                      />
                    </Grid>
                    <Grid item lg={2} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="select-estado-label">UF</InputLabel>
                        <Select
                          labelId="select-estado-label"
                          id="select-estado"
                          value={values.uf}
                          label={"UF"}
                          disabled={isDeleteMode}
                          name={"uf"}
                          onChange={handleChange}
                          fullWidth
                        >
                          {Object.entries(ESTADOS).map(([sigla, estado]) => (
                            <MenuItem value={sigla}>{estado}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    marginY={3}
                  >
                    {isDeleteMode ? (
                      <Stack direction="row" gap={2} display={"flex"}>
                        <Box>
                          <Button
                            color="error"
                            variant="contained"
                            onClick={() => {
                              modalAlert(id);
                            }}
                          >
                            Excluir imóvel
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              navigate(
                                `/app/cliente/${clienteId}/unidade/${unidadeId}/imoveis`
                              );
                            }}
                          >
                            Cancelar
                          </Button>
                        </Box>
                      </Stack>
                    ) : (
                      <LoadingButton
                        loading={isSubmitting}
                        type="submit"
                        style={{ margin: "auto", display: "block" }}
                        variant="contained"
                        color="primary"
                      >
                        {id ? "Atualizar" : "Criar"}
                      </LoadingButton>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </JumboContentLayout>
  );
};

export default ImovelClienteForm;
