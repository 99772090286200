import React from "react";

const ListaRelatóriosFinanceiroPDF = ({ contratos }) => {
  const totalContratos = contratos?.length || 0;

  const somaValorTotal =
    contratos?.reduce((total, contrato) => {
      const valorContrato =
        contrato.servicos?.reduce((soma, servico) => {
          return (
            soma + (Number(servico.quantidade) * Number(servico.valor) || 0)
          );
        }, 0) || 0;
      return total + valorContrato;
    }, 0) || 0;

  const somaValorPago =
    contratos?.reduce((total, contrato) => {
      const valorPagoContrato =
        contrato.pagamentoscontrato?.reduce((soma, pagamento) => {
          return soma + (Number(pagamento.valor) || 0);
        }, 0) || 0;
      return total + valorPagoContrato;
    }, 0) || 0;

  const somaFaltaPagar = somaValorTotal > 0 ? Math.abs(somaValorTotal - somaValorPago) : 0;
  return (
    <table
      className="tabela-contratos"
      style={{ width: "100mm", tableLayout: "auto" }}
    >
      <thead className="cabecalho-contratos">
        <tr>
          <th>Contrato</th>
          <th>Cliente</th>
          <th>Valor Acertado</th>
          <th>Valor Pago</th>
        </tr>
      </thead>
      <tbody>
        {contratos?.map((contrato, contratoIndex) => {
          const valorTotal =
            contrato.servicos?.reduce((total, servico) => {
              return total + Number(servico.quantidade) * Number(servico.valor);
            }, 0) || 0;

          const valorPago =
            contrato.pagamentoscontrato?.reduce((total, pagamento) => {
              return total + Number(pagamento.valor); // Supondo que `pagamento.valor` exista
            }, 0) || 0;

          const faltaPagar = valorTotal - valorPago;
          const isFixo = contrato.tipocontratocontrato?.some(
            (tipo) =>
              tipo.tipos_contratos?.descricao?.toLowerCase() === "contrato fixo"
          );

          return (
            <tr key={contratoIndex}>
              <td>{contrato?.descricao}</td>
              <td>{contrato?.Cliente?.nome}</td>
              <td>
                {isFixo ? (
                  "Contrato fixo"
                ) : (
                  <>
                    {valorTotal.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </>
                )}
              </td>
              <td>
                {isFixo ? (
                  "Contrato fixo"
                ) : (
                  <>
                    {valorPago.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}{" "}
                    <br />
                    {faltaPagar > 0 ? (
                      <>
                        <span style={{ fontSize: "6px", fontWeight: "600" }}>
                          Falta:
                        </span>
                        <span style={{ fontSize: "6px" }}>
                          {faltaPagar.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ fontSize: "6px", fontWeight: "600" }}>
                          Falta:
                        </span>
                        <span style={{ fontSize: "6px" }}>R$ 0,00</span>
                      </>
                    )}
                  </>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={2} style={{ background: "#0B9300", color: "#FFF" }}>
            <strong>Total:</strong> <strong>{totalContratos}</strong>
          </td>

          <td colSpan={1} style={{ background: "#0B9300", color: "#FFF" }}>
            <strong>
              {somaValorTotal.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </strong>
          </td>
          <td colSpan={1} style={{ background: "#0B9300", color: "#FFF" }}>
            <strong>
              {somaValorPago.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}{" "}
              <br />
              <span style={{ fontSize: "6px", fontWeight: "600" }}>Falta:</span>
            </strong>
            <span style={{ fontSize: "6px" }}>
              {somaFaltaPagar.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default ListaRelatóriosFinanceiroPDF;
