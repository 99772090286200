import React from "react";

const ListaContratosPDF = ({ contratos }) => {
  return (
    <>
      {contratos?.map((contrato) => (
        <tr>
          <td>{contrato?.descricao}</td>
          <td>{contrato?.Cliente?.nome}</td>
          <td>
            {contrato?.unidades?.length > 0
              ? contrato?.unidades
                  ?.map((unidade) => unidade?.nomeUnidade)
                  .join(", ")
              : "N/I"}
          </td>
          <td>
            {contrato?.processos_contratos?.length > 0
              ? contrato?.processos_contratos
                  ?.map(
                    (processo) => processo?.processo_administrativo?.descricao
                  )
                  .join(", ")
              : "N/I"}
          </td>
          <td>
            {contrato?.orientadores?.length > 0
              ? contrato?.orientadores
                  ?.map((orientador) => orientador?.usuario?.name)
                  .join(", ")
              : "N/I"}
          </td>
        </tr>
      ))}
    </>
  );
};

export default ListaContratosPDF;
