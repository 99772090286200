const mapeamentoNomeFiltros = {
  chaves: {
    tipoContrato: "Tipo de Contrato",
    situacao: "Situação",
    tipoOrcamento: "Tipo de Orçamento",
    statusOrcamento: "Status Orçamentário",
    elaborador: "Elaborador",
    periodo: "Filtro por período",
    depende: "Depende",
    setores: "Setor (es)",
    data_inicio: "Data de início",
    data_final: "Data final",
    startDate: "Data de início",
    endDate: "Data final",
    colaboradoresServico: "Colaboradores",
    situacao: "Situação",
    status: "Status",
    searchTerm: "Termo de busca"
  },
  valores: {
    selecionar: "Geral",
    todos: "Todos os contratos",
    padrao: "Contratos Padrão",
    fixo: "Contratos Fixos",
    renovacao: "Contratos de Renovação",
    revisado: "Contratos a serem revisados",
    agronegocio: "Agronegócio",
    mineracao: "Mineração",
    postos: "Postos de Combustíveis",
    servicos: "Prestação de Serviços",
    aprovado: "Aprovado",
    nao_aprovado: "Não aprovado",
    finalizado: "Finalizado",
    aguardando_resposta: "Aguardando resposta",
    finalizado_completo: "Finalizado completo",
    cancelado: "Cancelado",
    createdAt: "Data de criação",
    data_aprovacao: "Data de aprovação",
    aguardando_analise: "Aguardando análise",
    renovacaoIndividual: "Renovação",
    fixoIndividual: "Fixo",
    renovacaoFixoIndividual: "Fixo - Renovação",
    padraoIndividual: "Padrão",
    em_andamento: "Em andamento",
    nao_iniciados: "Não iniciados",
    finalizados: "Finalizados",
    paralisados: "Paralisados",
    ambiental: "Ambiental",
    orgao: "Órgão",
    protocolado: "Protocolado",
    cliente: "Cliente",
    data_conclusao: "Data de conclusão",
    data_registro: "Data de registro",
    data_inicio: "Data de início",
    prazo: "Prazo",
    status: "Status dos serviços",
    renovacao: "Renovação",
    fixo: "Fixo"
  },
  statusOrcamento: {
    1: "Aprovado",
    2: "Não aprovado",
    3: "Finalizado",
    4: "Paralisado",
    5: "Aguardando resposta",
    6: "À ser revisado",
    7: "Finalizado completo",
    8: "Cancelado",
  },
  tipoOrcamento: {
    1: "Agronegócio",
    2: "Mineração",
    3: "Postos de combustíveis",
    4: "Prestações de serviços",
  }
};

export default mapeamentoNomeFiltros;
