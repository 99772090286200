import React from "react";

const ContratosFixoIndividual = ({
  isModoPaisagem,
  contrato,
}) => {
  return (
    <table
      className="tabela-contratos"
      style={{ width: isModoPaisagem ? "150mm" : "100mm", tableLayout: 'auto' }}
    >
      {contrato && contrato?.processos_contratos?.length > 0 ? (
        <>
          {contrato?.processos_contratos
            .filter((processo) => processo.servicos.length > 0)
            .map((processo, index) => {
              const totalProcesso = processo?.servicos.reduce(
                (acc, servico) => acc + servico?.valor * servico?.quantidade,
                0
              );

              return (
                <React.Fragment key={index}>
                  <thead>
                    <tr>
                      <th
                        style={{ background: "#024900", color: "#FFF" }}
                        colSpan={3}
                      >
                        Processo administrativo:{" "}
                        {processo?.processo_administrativo?.descricao}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: "#0B9300", color: "#FFF", width: "15%" }}>
                        Contrato
                      </th>
                      <th style={{ background: "#0B9300", color: "#FFF", width: "65%" }}>
                        Tarefa
                      </th>
                      <th style={{ background: "#0B9300", color: "#FFF", width: "20%" }}>
                        Quantidade
                      </th>
                    </tr>
                  </thead>
                  <tbody className="body-contratos">
                    {processo?.servicos.map((servico, servicoIndex) => (
                      <tr key={servicoIndex}>
                        <td>{contrato?.descricao}</td>
                        <td>{servico?.tarefas_servico[0]?.descricao}</td>
                        <td>{servico?.quantidade}</td>
                      </tr>
                    ))}
                  </tbody>
                </React.Fragment>
              );
            })}
        </>
      ) : null}
    </table>
  );
};

export default ContratosFixoIndividual;
