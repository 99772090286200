import ApiService from "app/services/config";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import mapeamentoNomeFiltros from "../mapeamentoNomeFiltros";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const RESPONSAVEIS = {
  ambiental: "Ambiental",
  cliente: "Cliente",
  orgao: "Órgão",
  protocolado: "Protocolado",
};

const gerarRelatorioContratosPadraoPDF = async (filtros, isPadrao) => {
  const obterUrlImagem = async (path) => {
    const blogImage = await fetch(process.env.REACT_APP_ROOT_URL + path).then(
      (data) => data.blob()
    );
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blogImage);
    });
    return dataUrl;
  };

  const imagemIcone = await obterUrlImagem("/images/pdf/icone.png");
  const imagemTexto = await obterUrlImagem("/images/pdf/nome.png");

  const getContratos = async () => {
    try {
      let url = `/contratos/all?tipoContrato=padrao`;
      if (filtros?.startDate && filtros?.endDate) {
        url += `&dataInicio=${moment(filtros?.startDate._d).format(
          "YYYY-MM-DD"
        )}&dataFim=${moment(filtros?.endDate._d).format("YYYY-MM-DD")}`;
      }

      if (filtros?.situacao) {
        url += `&situacao=${filtros?.tipoContrato}`;
      }

      if (
        filtros?.statusOrcamento &&
        filtros?.statusOrcamento !== "selecionar"
      ) {
        url += `&statusOrcamento=${filtros?.statusOrcamento}`;
      }

      if (filtros?.elaborador && filtros?.elaborador !== "selecionar") {
        url += `&elaborador=${filtros?.elaborador}`;
      }

      if (filtros?.periodo && filtros?.periodo !== "selecionar") {
        url += `&periodo=${filtros?.periodo}`;
      }

      if (filtros?.tipoOrcamento && filtros?.tipoOrcamento !== "selecionar") {
        url += `&tipoOrcamento=${Number(filtros?.tipoOrcamento)}`;
      }

      if (filtros?.searchTerm && filtros?.searchTerm !== "") {
        url += `&searchTerm=${filtros?.searchTerm}`;
      }

      const response = await ApiService.get(url);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  const contratos = await getContratos();

  const documentDefinition = {
    content: [
      {
        columns: [
          {
            image: imagemIcone,
            alignment: "left",
            width: 55,
          },
          {
            text: "",
            width: "*",
          },
          {
            image: imagemTexto,
            alignment: "right",
            width: 150,
          },
        ],
        margin: [0, 0, 0, 10],
      },
      {
        text: "\n",
      },
      {
        text: "\n",
      },
      {
        text: `Contratos ${isPadrao ? "padrão" : ""}`,
        fontSize: 14,
        bold: true,
      },
      {
        text: "\n",
      },
    ],
    pageOrientation: "portrait",
    fontSize: 10,
    styles: {
      header: {
        bold: true,
        fontSize: 10,
        margin: [0, 10, 0, 5],
        color: "white",
        fillColor: "#024900",
        alignment: "left",
        padding: [6, 4, 0, 4],
      },
      subheader: {
        bold: true,
        fontSize: 10,
        margin: [0, 5, 0, 5],
        color: "white",
        fillColor: "#0B9300",
        alignment: "left",
        padding: [6, 4, 0, 4],
      },
      tableHeader: {
        fillColor: "#2BC900",
        color: "white",
        bold: true,
        alignment: "center",
        margin: [0, 5, 0, 5],
        padding: [6, 4],
        fontSize: 10,
      },
      tableCell: {
        margin: [0, 15, 0, 15],
        padding: [8, 4],
        fontSize: 10,
        alignment: "left",
        baseline: "middle",
      },
      bordered: {
        margin: [0, 0, 0, 0],
        table: {
          widths: ["20%", "20%", "20%", "20%", "20%"],
          body: [],
        },
        layout: {
          hLineWidth: () => 1,
          vLineWidth: () => 1,
          hLineColor: () => "#000",
          vLineColor: () => "#000",
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 6,
          paddingBottom: () => 6,
        },
      },
    },
  };

  if (filtros && Object.keys(filtros).length > 0) {
    documentDefinition.content.push({
      text: "Filtros:",
      fontSize: 11,
      bold: true,
    });
    Object.entries(filtros).forEach((filtro) => {
      if (
        filtro[1] ||
        (filtro[1]?.length > 1 &&
          filtro[1] !== "selecionar" &&
          filtro[1] !== null) ||
        filtro[0] === "searchTerm"
      ) {
        let chaveDinamica = "";
        switch (mapeamentoNomeFiltros.chaves[filtro[0]]) {
          case "Filtro por período":
            chaveDinamica = mapeamentoNomeFiltros.valores[filtro[1]];
            break;
          case "Data de início":
            chaveDinamica = moment.utc(filtro[1]).format("DD/MM/YYYY");
            break;
          case "Data final":
            chaveDinamica = moment.utc(filtro[1]).format("DD/MM/YYYY");
            break;
          case "Elaborador":
            const elaborador = contratos[0]?.elaboradores_orcamento.find(
              (elaborador) => elaborador?.usuario?.id === filtro[1]
            );
            chaveDinamica = contratos[0]?.elaboradores_orcamento.find(
              (elaborador) => elaborador?.usuario?.id === filtro[1]
            )?.usuario?.name ?? 'Nenhum contrato encontrado para o elaborador';
            break;
          case "Tipo de Orçamento":
            chaveDinamica = mapeamentoNomeFiltros.tipoOrcamento[filtro[1]];
            break;
          case "Status Orçamentário":
            chaveDinamica = mapeamentoNomeFiltros.statusOrcamento[filtro[1]];
            break;
          case "Termo de busca":
            chaveDinamica =
              filtro[1] !== "" && filtro[1] !== undefined
                ? `'${filtro[1]}'`
                : '" "';
            break;
          case "Situação":
            chaveDinamica = mapeamentoNomeFiltros.valores[filtro[1]];
            break;
        }
        documentDefinition.content.push({
          text: [
            {
              text: `${mapeamentoNomeFiltros.chaves[filtro[0]]}: `,
              bold: true,
              fontSize: 9,
            },
            {
              text: chaveDinamica,
              bold: false,
              fontSize: 9,
            },
            "\n",
          ],
        });
      }
    });
    documentDefinition.content.push({
      text: "\n",
    });
  }

  documentDefinition.content.push({
    text: "\n",
  });

  if (contratos?.length > 0) {
    const tableBody = [
      [
        { text: "Contrato", style: "subheader" },
        { text: "Cliente", style: "subheader" },
        { text: "Unidade (s)", style: "subheader" },
        { text: "Processos", style: "subheader" },
        { text: "Colaboradores", style: "subheader" },
      ],
    ];

    contratos?.forEach((contrato) => {
      contrato?.processos_contratos?.map((processoContrato) => {
        const colaboradoresDoServico = [
          ...new Map(
            processoContrato?.servicos
              ?.flatMap(
                (servicoDoContrato) => servicoDoContrato?.servicocolaborador
              )
              ?.map((colaborador) => [
                colaborador?.users?.id,
                colaborador?.users,
              ])
          ).values(),
        ];
        const colaboradoresNomes = colaboradoresDoServico.map(
          (colaborador) => colaborador?.name
        );
        tableBody.push([
          { text: contrato?.descricao ?? "N/I", style: "tableCell" },
          { text: contrato?.Cliente?.nome ?? "N/I", style: "tableCell" },
          {
            text:
              contrato?.unidades?.length > 0
                ? contrato.unidades
                    .map((unidade) => unidade?.nomeUnidade)
                    .join(", ")
                : "N/I",
            style: "tableCell",
          },
          {
            text: processoContrato?.processo_administrativo?.descricao ?? "N/I",
            style: "tableCell",
          },
          {
            text:
              colaboradoresNomes?.length > 0
                ? colaboradoresNomes
                    .map((colaborador) => colaborador)
                    .join(", ")
                : "N/I",
            style: "tableCell",
          },
        ]);
      });
    });

    documentDefinition.content.push({
      style: "bordered",
      table: {
        widths: ["20%", "20%", "20%", "20%", "20%"],
        body: tableBody,
      },
    });
  } else {
    documentDefinition.content.push({
      text: "Nenhum contrato disponível",
      style: "subheader",
    });
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  pdfDocGenerator.getBlob((blob) => {
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  });
};

export default gerarRelatorioContratosPadraoPDF;
