import React from "react";
import moment from "moment";

const ListaContratosRenovacao = ({ contratos }) => {
  return (
    <>
      {contratos.map((contrato) =>
        contrato?.processos_contratos?.map((processo) => (
          <tr key={contrato?.id}>
            <td>{contrato?.Cliente?.nome ?? "N/I"}</td>
            <td>{contrato?.descricao}</td>
            <td>{processo?.processo_administrativo?.descricao}</td>
            <td>
              {moment.utc(contrato?.dataVencimento).format("DD/MM/YYYY")}
            </td>
            <td>
              {moment.utc(contrato?.prazoRenovacao).format("DD/MM/YYYY")}
            </td>
            <td>{processo?.status?.descricao}</td>
          </tr>
        ))
      )}
    </>
  );
};

export default ListaContratosRenovacao;
