import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const ContratoDetalhesServicos = ({ contrato }) => {
  const uniqueProcessos = new Set();
  return (
    <>
      <Grid item xs={4}>
        <Typography variant={"h4"} fontWeight={600}>
          Processos Administrativos e Serviços:
        </Typography>{" "}
      </Grid>
      <Grid item xs={12}>
        
        <TableContainer component={Paper}>
          {contrato?.processos_contratos &&
          contrato.processos_contratos.length > 0 ? (
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Tarefa</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Setor responsável</TableCell>
                  <TableCell align="right">Colaboradores</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contrato.processos_contratos.map((processo, index) => {
                  return processo?.processo_administrativo?.Servico?.map(
                    (servico) => {
                      const processoServicoId = `${processo.id}-${servico.id}`;

                      if (!uniqueProcessos.has(processoServicoId)) {
                        uniqueProcessos.add(processoServicoId);

                        return (
                          <TableRow
                            key={servico.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {processo?.processo_administrativo?.descricao ||
                                "Descrição indisponível"}
                            </TableCell>

                            <TableCell>
                              {servico?.tarefas_servico?.[0]?.descricao ??
                                "Nenhuma tarefa"}
                            </TableCell>

                            <TableCell align="right">
                              {servico?.status_andamento_servico?.descricao ||
                                "Status não disponível"}
                            </TableCell>

                            <TableCell align="right">
                              {servico?.setor_responsavel?.descricao ||
                                "Setor não disponível"}
                            </TableCell>

                            <TableCell align="right">
                              {servico?.servicocolaborador?.length > 0
                                ? servico.servicocolaborador
                                    .map((colab) => colab.users?.name)
                                    .join(", ")
                                : "Colaboradores não disponíveis"}
                            </TableCell>
                          </TableRow>
                        );
                      }

                      return null;
                    }
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Stack
              width={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              Nenhum serviço cadastrado para esse contrato.
            </Stack>
          )}
        </TableContainer>
      </Grid>
    </>
  );
};

export default ContratoDetalhesServicos;
