import React, { useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import { Box, Button, Stack, Typography } from "@mui/material";
import "./style.css";
import ApiService from "app/services/config";
import moment from "moment";
import ContratosRenovacaoIndividual from "./components/ContratosRenovacaoIndividual/ContratosRenovacaoIndividual";
import CabecalhoDocumentoPDF from "./components/CabecalhoDocumentoPDF/CabecalhoDocumentoPDF";
import ListaContratosPDF from "./components/ListaContratosPDF/ListaContratosPDF";
import ListaContratosRenovacao from "./components/ListaContratosRenovacao/ListaContratosRenovacao";
import ContratosFixoIndividual from "./components/ContratosFixoIndividual/ContratosFixoIndividual";
import FixoRenovacaoIndividualPDF from "./components/FixoRenovacaoIndividualPDF/FixoRenovacaoIndividualPDF";
import ContratoPadraoIndividualPDF from "./components/ContratoPadraoIndividualPDF/ContratoPadraoIndividualPDF";
import ListaRelatóriosFinanceiroPDF from "./components/ListaRelatóriosFinanceiroPDF/ListaRelatóriosFinanceiroPDF";
import RelatorioCompletoProcessosAdminPDF from "./components/RelatorioCompletoProcessosAdminPDF/RelatorioCompletoProcessosAdminPDF";
import ListaRelatoriosResumidoPDF from "./components/ListaRelatoriosResumidoPDF/ListaRelatoriosResumidoPDF";

const FILTROS_CHAVES_VALORES = {
  chaves: {
    tipoContrato: "Tipo de Contrato",
    situacao: "Situação",
    tipoOrcamento: "Tipo de Orçamento",
    statusOrcamento: "Status Orçamentário",
    elaborador: "Elaborador",
    periodo: "Filtro por período",
  },
  valores: {
    todos: "Todos os contratos",
    padrao: "Contratos Padrão",
    fixo: "Contratos Fixos",
    renovacao: "Contratos de Renovação",
    revisado: "Contratos a serem revisados",
    agronegocio: "Agronegócio",
    mineracao: "Mineração",
    postos: "Postos de Combustíveis",
    servicos: "Prestação de Serviços",
    aprovado: "Aprovado",
    nao_aprovado: "Não aprovado",
    finalizado: "Finalizado",
    aguardando_resposta: "Aguardando resposta",
    finalizado_completo: "Finalizado completo",
    cancelado: "Cancelado",
    createdAt: "Data de criação",
    data_aprovacao: "Data de aprovação",
    aguardando_analise: "Aguardando análise",
    renovacaoIndividual: "Renovação",
    fixoIndividual: "Fixo",
    renovacaoFixoIndividual: "Fixo - Renovação",
    padraoIndividual: "Padrão",
  },
};

const COLUNAS_LISTA_CONTRATOS = [
  "Contrato",
  "Cliente",
  "Unidade (s)",
  "Processos",
  "Colaboradores",
];

const COLUNAS_CONTRATOS_RENOVACAO = [
  "Cliente",
  "Contrato",
  "Processo Administrativo",
  "Vencimento da licença",
  "Prazo de renovação",
  "Status",
];

const PDFContratos = () => {
  const [filtros, setFiltros] = useState([]);
  const [isModoPaisagem, setIsModoPaisagem] = useState(false);
  const [isCarregandoDados, setIsCarregandoDados] = useState(true);
  const [contratos, setContratos] = useState([]);
  const [contrato, setContrato] = useState({});
  const [objetoFiltros, setObjetoFiltros] = useState({});
  const [tituloDocumento, setTituloDocumento] = useState("Contratos");
  const [colunasDoPDF, setColunasDoPDF] = useState(COLUNAS_LISTA_CONTRATOS);
  const [tipoDoContrato, setTipoDoContrato] = useState("padrao");
  const pdfRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      gerarPDF();
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleGetContratos = async () => {
    try {
      let url = `/contratos/all?tipo`;

      if (objetoFiltros?.tipoOrcamento) {
        let idTipoOrcamento = null;
        switch (objetoFiltros.tipoOrcamento) {
          case "agronegocio":
            idTipoOrcamento = 1;
            break;
          case "mineracao":
            idTipoOrcamento = 2;
            break;
          case "postos":
            idTipoOrcamento = 3;
            break;
          case "servicos":
            idTipoOrcamento = 4;
            break;
          default:
            idTipoOrcamento = 1;
        }
        url += `&tipoOrcamento=${idTipoOrcamento}`;
      }

      if (objetoFiltros?.statusOrcamento) {
        let idStatusOrcamento = null;
        switch (objetoFiltros.statusOrcamento) {
          case "aprovado":
            idStatusOrcamento = 1;
            break;
          case "nao_aprovado":
            idStatusOrcamento = 2;
            break;
          case "finalizado":
            idStatusOrcamento = 3;
            break;
          case "aguardando_resposta":
            idStatusOrcamento = 5;
            break;
          case "finalizado_completo":
            idStatusOrcamento = 7;
            break;
          case "cancelado":
            idStatusOrcamento = 8;
            break;
          default:
            idStatusOrcamento = 1;
        }
        url += `&statusOrcamento=${idStatusOrcamento}`;
      }

      if (objetoFiltros?.tipoContrato) {
        let tipoContrato = null;
        switch (objetoFiltros.tipoContrato) {
          case "renovacao":
            tipoContrato = "renovacao";
            setTituloDocumento("Contratos de Renovação");
            setColunasDoPDF(COLUNAS_CONTRATOS_RENOVACAO);
            setTipoDoContrato("renovacao");
            break;
          case "fixo":
            tipoContrato = "fixo";
            setTituloDocumento("Contratos Fixos");
            setTipoDoContrato("fixos");
            break;
          case "financeiro":
            tipoContrato = "todos";
            setTituloDocumento("Relatório Financeiro");
            setTipoDoContrato("financeiro");
            break;
          case "relatorioCompleto":
            tipoContrato = "todos";
            setTituloDocumento(
              "Relatório completo de processos administrativos"
            );
            setTipoDoContrato("relatorioCompleto");
            break;
          case "relatorioResumido":
            tipoContrato = "todos";
            setTituloDocumento(
              "Relatório resumido de processos administrativos"
            );
            setTipoDoContrato("relatorioResumido");
            break;
          case "renovacaoIndividual":
            setTipoDoContrato("renovacaoIndividual");
          case "renovacaoFixoIndividual":
            setTipoDoContrato("renovacaoFixoIndividual");
          case "padraoIndividual":
            setTipoDoContrato("padraoIndividual");
          case "todos":
            tipoContrato = "todos";
            break;
          default:
            tipoContrato = "todos";
        }
        url += `&tipoContrato=${tipoContrato}`;
      }

      if (objetoFiltros?.situacao) {
        let situacao = null;
        switch (objetoFiltros.situacao) {
          case "padrao":
            situacao = "padrao";
            break;
          case "aguardando_analise":
            situacao = "aguardando_analise";
            break;
          default:
            situacao = "padrao";
        }
        url += `&situacao=${situacao}`;
      }

      if (objetoFiltros?.elaborador) {
        let idElaborador = objetoFiltros?.elaborador;
        url += `&idElaborador=${idElaborador}`;
      }

      if (
        objetoFiltros?.periodo &&
        objetoFiltros?.dataInicio &&
        objetoFiltros?.dataFim
      ) {
        let periodo = null;
        switch (objetoFiltros.periodo) {
          case "createdAt":
            periodo = "createdAt";
            break;
          case "data_aprovacao":
            periodo = "data_aprovacao";
            break;
          default:
            periodo = null;
        }
        url += `&periodo=${periodo}&dataInicio=${objetoFiltros?.dataInicio}&dataFim=${objetoFiltros?.dataFim}`;
      }

      await ApiService.get(url).then((response) => {
        if (response.status === 200) {
          setContratos(response.data);
          setIsCarregandoDados(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetContratoById = async (contratoId) => {
    try {
      await ApiService.get(`/contratos/exportar/pdf/${contratoId}`).then(
        (response) => {
          if (response.status === 200) {
            setContrato(response.data);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const url = window.location.search;

    let filtrosUrl = [];
    let filtrosObjeto = {};
    new URLSearchParams(url).forEach((value, key) => {
      let objFiltro = {};
     
      
      objFiltro[key] = value;
      if (key !== "orientacao") {
        if (
          key !== "dataInicio" &&
          key !== "dataFim" &&
          value !== "financeiro" &&
          key !== "contratoId" &&
          value !== "relatorioCompleto" &&
          key !== "elaborador" &&
          key !== "formatoContrato"
        ) {
          filtrosUrl.push(objFiltro);
        }
        filtrosObjeto[key] = value;
        setObjetoFiltros(filtrosObjeto);
      }
      if (key === "orientacao" && value === "paisagem") {
        setIsModoPaisagem(true);
      }
    });

    setFiltros(filtrosUrl);
  }, []);

  useEffect(() => {
    if (objetoFiltros?.contratoId) {
      handleGetContratoById(objetoFiltros?.contratoId);
      setTipoDoContrato(objetoFiltros?.tipoContrato);
    } else {
      handleGetContratos();
    }
  }, [objetoFiltros, filtros, isCarregandoDados]);

  const gerarPDF = () => {
    const orientacao =
      objetoFiltros?.orientacao === "paisagem" ||
      objetoFiltros?.tipoContrato === "relatorioCompleto" ||
      objetoFiltros?.tipoContrato === "relatorioResumido"
        ? "l"
        : "p";
    const pdf = new jsPDF(orientacao, "px", "a4");

    pdf.setFont('times');
    pdf.internal.write(0, "Tw");

    pdf.html(pdfRef.current, {
      callback: (pdf) => {
        const pdfBlob = pdf.output("blob");
        const pdfURL = URL.createObjectURL(pdfBlob);
        window.open(pdfURL);
      },
      x: 20,
      y: 10,
      margin: [10, 10, 10, 10],
      autoPaging: "slice",
      html2canvas: {
        scale: 1,
      },
    });
  };

  return (
    <>
      <div>
        <h1>Feche a janela após o PDF ser gerado...</h1>
        <Typography>Caso tenha problemas ao gerar, clique no botão abaixo</Typography>
        <Button variant="contained" color="primary" onClick={gerarPDF}>
          Gerar PDF
        </Button>
        <div
          ref={pdfRef}
          style={{
            padding: "5px",
            backgroundColor: "#fff",
            width:
              isModoPaisagem ||
              tipoDoContrato === "relatorioCompleto" ||
              tipoDoContrato === "relatorioResumido"
                ? "297mm"
                : "210mm",
            height: "auto",
            boxSizing: "border-box",
            fontSize: "8px",
            color: "#000",
            wordSpacing: "0.02px",
            letterSpacing: "0.02px",
          }}
          className="div-pdf"
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={isModoPaisagem ? "150mm" : "100mm"}
            spacing={2}
          >
            <Box>
              <img
                src={"/images/pdf/icone.png"}
                alt={"Ícone Ambiental"}
                width={isModoPaisagem ? "50px" : "40px"}
                height={"auto"}
              />
            </Box>
            <Box>
              <img
                src={"/images/pdf/nome.png"}
                alt={"Logo Ambiental"}
                width={isModoPaisagem ? "120px" : "100px"}
                height={"auto"}
              />
            </Box>
          </Stack>
          <br />
          <br />
          <CabecalhoDocumentoPDF
            contrato={contratos[0]}
            FILTROS_CHAVES_VALORES={FILTROS_CHAVES_VALORES}
            objetoFiltros={objetoFiltros}
            contratos={contratos}
            filtros={filtros}
            tituloDocumento={tituloDocumento}
          />
          <br />
          {tipoDoContrato === "renovacaoIndividual" ||
          tipoDoContrato === "fixoIndividual" ||
          tipoDoContrato === "renovacaoFixoIndividual" ||
          tipoDoContrato === "padraoIndividual" ||
          objetoFiltros?.formatoContrato === "financeiro" ? (
            <p>
              <span className="filtros-contratos-pdf">MATRIZ DE CUSTO</span>
            </p>
          ) : null}
          {tipoDoContrato === "relatorioCompleto" ||
          tipoDoContrato === "relatorioResumido" ? (
            <p>
              <span className="filtros-contratos-pdf">MATRIZ DE SERVIÇOS</span>
            </p>
          ) : null}
          <table
            className="tabela-contratos"
            cellSpacing={0}
            style={{ width: isModoPaisagem ? "150mm" : "100mm" }}
          >
            {objetoFiltros?.tipoContrato !== "renovacaoIndividual" &&
            objetoFiltros?.tipoContrato !== "fixoIndividual" &&
            objetoFiltros?.tipoContrato !== "renovacaoFixoIndividual" &&
            objetoFiltros?.tipoContrato !== "padraoIndividual" &&
            objetoFiltros?.formatoContrato !== "financeiro" &&
            objetoFiltros?.tipoContrato !== "relatorioCompleto" &&
            objetoFiltros?.tipoContrato !== "relatorioResumido" ? (
              <>
                <thead className="cabecalho-contratos">
                  <tr>
                    {colunasDoPDF.map((coluna) => (
                      <th key={coluna}>{coluna}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="body-contratos">
                  {tipoDoContrato === "todos" ? (
                    <ListaContratosPDF contratos={contratos} />
                  ) : null}
                  {tipoDoContrato === "padrao" ? (
                    <ListaContratosPDF contratos={contratos} />
                  ) : null}
                  {tipoDoContrato === "fixos" ? (
                    <ListaContratosPDF contratos={contratos} />
                  ) : null}
                  {tipoDoContrato === "renovacao" ? (
                    <ListaContratosRenovacao contratos={contratos} />
                  ) : null}
                </tbody>
              </>
            ) : null}
            {objetoFiltros?.tipoContrato === "renovacaoIndividual" && (
              <>
                <ContratosRenovacaoIndividual
                  isModoPaisagem={isModoPaisagem}
                  contrato={contrato}
                />
              </>
            )}
            {objetoFiltros?.tipoContrato === "fixoIndividual" && (
              <>
                <ContratosFixoIndividual
                  isModoPaisagem={isModoPaisagem}
                  contrato={contrato}
                />
              </>
            )}
            {objetoFiltros?.tipoContrato === "renovacaoFixoIndividual" && (
              <>
                <FixoRenovacaoIndividualPDF contrato={contrato} />
              </>
            )}
            {objetoFiltros?.tipoContrato === "padraoIndividual" && (
              <>
                <ContratoPadraoIndividualPDF contrato={contrato} />
              </>
            )}
            {objetoFiltros?.formatoContrato === "financeiro" && (
              <>
                <ListaRelatóriosFinanceiroPDF contratos={contratos} />
              </>
            )}
            {objetoFiltros?.tipoContrato === "relatorioCompleto" && (
              <>
                <RelatorioCompletoProcessosAdminPDF contratos={contratos} />
              </>
            )}
            {objetoFiltros?.tipoContrato === "relatorioResumido" && (
              <>
                <ListaRelatoriosResumidoPDF contratos={contratos} />
              </>
            )}
          </table>
        </div>

      </div>
      {/* <div className="loader">
        <svg className="circular-loader" viewBox="25 25 50 50">
          <circle className="loader-path" cx="50" cy="50" r="20" />
        </svg>
      </div> */}
    </>
  );
};

export default PDFContratos;
