import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Select,
  TextareaAutosize,
  FormLabel,
  MenuItem,
  InputLabel,
  FormControl,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  IconButton,
  Alert,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import * as yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CreatableSelect from "react-select/creatable";
import { PermissionContext } from "app/contexts/PermissionContext";
import ListImoveisCliente from "app/components/ListImoveisCliente/ListImoveisCliente";
import ModalImovelForm from "app/components/ModalImovelForm/ModalImovelForm";
import { Delete, PhotoCamera } from "@mui/icons-material";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchema = yup.object().shape({
  nome: yup
    .string("Insira o nome do cliente")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Preenchimento obrigatório")
    .required("Preenchimento obrigatório"),
  tipo_pessoa: yup.string("Campo obrigatório").required("Campo obrigatório"),
  cpf: yup
    .string()
    .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, {
      message: "CPF Inválido",
      excludeEmptyString: true,
    })
    .nullable()
    .when("tipo_pessoa", {
      is: "fisica",
      then: yup.string().required("CPF é obrigatório para pessoa física"),
    }),
  telefone: yup
    .string()
    .matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, "Telefone inválido")
    .required("Preenchimento obrigatório")
    .typeError("Telefone inválido"),

  cnpj: yup
    .string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
      message: "CNPJ Inválido",
      excludeEmptyString: true,
    })
    .nullable()
    .when("tipo_pessoa", {
      is: "juridica",
      then: yup.string().required("CNPJ é obrigatório para pessoa jurídica"),
    }),
  segmento_cliente_id: yup
    .number("ID Inválido")
    .required("Selecione um Segmento de Cliente"),
});

const validationSchemaEdit = yup.object().shape({
  nome: yup
    .string("Insira o nome do cliente")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Preenchimento obrigatório")
    .required("Preenchimento obrigatório"),
  telefone: yup
    .string()
    .matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, "Telefone inválido")
    .required("Preenchimento obrigatório")
    .typeError("Telefone inválido"),
  cpf: yup
    .string()
    .matches(
      /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
      "Insira um CPF no formato XXX.XXX.XXX-XX"
    )
    .nullable(),
  cnpj: yup
    .string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
      message: "CNPJ Inválido",
      excludeEmptyString: true,
    })
    .nullable(),

  cep: yup
    .string()
    .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP Inválido")
    .nullable(),
  segmento_cliente_id: yup
    .number("ID Inválido")
    .required("Selecione um Segmento de Cliente"),
});

const ESTADOS = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

const ClienteForm = () => {
  const initialValues = {
    nome: "",
    email: "",
    telefone: "",
    cep: "",
    cpf: "",
    cnpj: "",
    bairro: "",
    logradouro: "",
    cidade: "",
    numero: "",
    complemento: "",
    uf: "",
    tipo_pessoa: "fisica",
    segmento_cliente_id: "",
    imoveis: [],
  };
  const { id } = useParams();
  const [cliente, setCliente] = useState(initialValues);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const location = useLocation();
  const [segmentosCliente, setSegmentosCliente] = useState([]);
  const [segmentoSelected, setSegmentoSelected] = useState(null);
  const [imoveisCliente, setImoveisCliente] = useState([]);
  const [formattedCPF, setFormattedCPF] = useState("");
  const [formattedCNPJ, setFormattedCNPJ] = useState("");
  const [formattedCEP, setFormatedCEP] = useState("");
  const [formattedTelefone, setFormatedTelefone] = useState("");
  const [mascaraTelefone, setMascaraTelefone] = useState("(99) 99999-9999");
  const [isModalImovelAberto, setIsModalImovelAberto] = useState(false);
  const [imovelSelecionado, setImovelSelecionado] = useState(null);
  const [indiceImovelSelecionado, setIndiceImovelSelecionado] = useState(0);
  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const [previewFotoPerfil, setPreviewFotoPerfil] = useState(null);
  const [erroUploadFotoPerfil, setErroUploadFotoPerfil] = useState({
    status: false,
    mensagem: "",
  });

  const formRef = useRef();

  const { theme } = useJumboTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Clientes", "create")) {
    navigate("/app/listar-clientes");
  }

  const handleTelefoneChange = (e) => {
    const telefone = e.target.value.replace(/\D/g, "");
    console.log("Chamou o telefone");
    if (telefone.length > 10) {
      setMascaraTelefone("(99) 99999-9999");
    } else {
      setMascaraTelefone("(99) 9999-9999");
    }

    formRef.current.setFieldValue("telefone", e.target.value);
    setFormatedTelefone(e.target.value);
  };

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const handleOpenModalImovel = () => {
    setIsModalImovelAberto(!isModalImovelAberto);
  };

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteUser(id);
      }
    });
  };

  const getSegmentosCliente = useCallback(async () => {
    try {
      const response = await ApiService.get("/segmentos/all");
      setSegmentosCliente(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCreateSegmentoCliente = useCallback(
    async (descricao) => {
      try {
        const response = await ApiService.post("/segmentos/", { descricao });
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          const novoSegmento = {
            id: response.data.id,
            descricao: response.data.descricao,
          };
          setSegmentosCliente([...segmentosCliente, novoSegmento]);
          setSegmentoSelected(novoSegmento);
          return novoSegmento;
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    },
    [segmentosCliente]
  );
  useEffect(() => {
    getSegmentosCliente();
  }, []);

  const deleteUser = useCallback(async (id) => {
    try {
      ApiService.delete(`/clientes/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          navigate(`/app/listar-clientes`);
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getCliente = useCallback(async () => {
    try {
      ApiService.get(`/clientes/cliente/${id}`)
        .then((response) => {
          const { cep } = response.data?.enderecos[0];
          const { telefone, cpf, cnpj } = response.data;
          setCliente({
            nome: response.data?.nome,
            email: response.data?.email,
            telefone: response.data?.telefone,
            bairro: response.data?.enderecos[0]?.vizinhanca,
            logradouro: response.data?.enderecos[0]?.rua,
            cidade: response.data?.enderecos[0]?.cidade,
            numero: response.data?.enderecos[0]?.numero,
            complemento: response.data?.enderecos[0]?.complemento,
            uf: response.data?.enderecos[0]?.uf,
            tipo_pessoa: response.data?.tipo_pessoa,
            segmento_cliente_id: response.data?.segmento_cliente?.id,
            imoveis: [],
          });
          setImoveisCliente(response.data.imoveis || []);
          setFormattedCPF(cpf);
          setFormattedCNPJ(cnpj);
          setFormatedCEP(cep);
          setFormatedTelefone(telefone ?? "");
          setSegmentoSelected({
            id: response.data.segmento_cliente.id,
            descricao: response.data.segmento_cliente.descricao,
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getImoveisCliente = useCallback(async () => {
    try {
      const response = await ApiService.get(`/imoveis/cliente/${Number(id)}`);
      setImoveisCliente(response.data);
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  const handleImovelChange = (index, field, value) => {
    const imoveisAtualizados = imoveisCliente.map((imovel, i) =>
      i === index
        ? {
            ...imovel,
            [field]: value,
          }
        : imovel
    );
    setImoveisCliente(imoveisAtualizados);
  };

  const handleChangeImovelSelecionado = (imovel) => {
    setImovelSelecionado(imovel);
  };

  const handleDeleteImovel = (imovel, remove) => {
    if (imovel.id) {
      modalAlert(imovel.id);
    } else {
      remove(indiceImovelSelecionado);
    }
  };

  const handlePreviewFotoPerfil = async (event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      if (
        selectedFile.type !== "image/jpeg" &&
        selectedFile.type !== "image/png"
      ) {
        setPreviewFotoPerfil(null);
        setErroUploadFotoPerfil({
          status: true,
          mensagem: "Formato de imagem inválido. Só é permitido .jpeg ou .png",
        });
        console.log("Tipo de imagem inválida", selectedFile.type);
        return;
      } else {
        setErroUploadFotoPerfil({
          status: false,
          mensagem: "",
        });

        reader.onloadend = () => {
          setPreviewFotoPerfil(reader.result);
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const handleUploadImagemPerfil = async (event) => {
    const fotoPerfil = event.target.files[0];
    const formData = new FormData();
    formData.append("foto", fotoPerfil);
    try {
      await ApiService.put(
        `users/upload/foto/perfil/${Number(cliente.user_id)}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Foto de perfil atualizada!");
          setCliente((prevCliente) => ({
            ...prevCliente,
            avatarUrl: response.data.avatarUrl,
          }));
          localStorage.setItem("avatarUrl", response.data.avatarUrl);
        }
      });
    } catch (error) {
      console.error("Erro ao fazer upload da imagem: ", error);
    }
  };

  const handleRemoveFotoPerfil = async () => {
    setPreviewFotoPerfil(null);
    setErroUploadFotoPerfil({
      status: false,
      mensagem: "",
    });
    try {
      await ApiService.put(
        `/users/foto/remover/${Number(cliente?.user_id)}`
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Foto removida com sucesso!");
          setPreviewFotoPerfil(null);
          setCliente((prevCliente) => ({
            ...prevCliente,
            avatarUrl: null,
          }));
        }
      });
    } catch (error) {
      console.log(error);
      toast("error", "Erro ao remover a imagem de perfil");
    }
  };

  useEffect(() => {
    if (id) {
      getCliente();
    }
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [id]);

  useEffect(() => {
    setImovelSelecionado(imoveisCliente[indiceImovelSelecionado]);
  }, [imoveisCliente]);

  const optionsSegmentos = segmentosCliente?.map((segmento) => {
    return {
      id: segmento.id,
      descricao: segmento.descricao,
    };
  });

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    const imoveisDoCliente = values.imoveis.concat(imoveisCliente);
    const allValuesEdit = {
      ...values,
      imoveis: imoveisDoCliente,
      segmento_cliente: segmentoSelected.id,
    };
    delete allValuesEdit.segmento_cliente_id;
    delete allValuesEdit.id;
    for (const imovel of imoveisCliente) {
      if (!imovel.descricao) {
        toast("warning", "Preencha o campo de descrição do imóvel!", "warning");
        return;
      }
    }
    for (const imovel of imoveisDoCliente) {
      if (imovel.descricao === "") {
        toast("warning", "Preencha o campo de descrição do imóvel!", "warning");
        return;
      }
    }
    if (id) {
      delete allValuesEdit.avatarUrl;
      allValuesEdit.endereco = {
        uf: allValuesEdit.uf,
        cidade: allValuesEdit.cidade,
        numero: allValuesEdit.numero,
        vizinhanca: allValuesEdit.bairro,
        complemento: allValuesEdit.complemento,
        rua: allValuesEdit.logradouro,
        cep: allValuesEdit.cep,
      };

      delete allValuesEdit.uf;
      delete allValuesEdit.cidade;
      delete allValuesEdit.numero;
      delete allValuesEdit.vizinhanca;
      delete allValuesEdit.complemento;
      delete allValuesEdit.logradouro;
      delete allValuesEdit.cep;
      delete allValuesEdit.bairro;
      delete allValuesEdit.passwordConfirm;

      ApiService.put(`/clientes/${id}`, allValuesEdit)
        .then((response) => {
          toast("success", "Atualizado com sucesso!");
          if (response.status === 200) {
            navigate("/app/listar-clientes");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      delete allValuesEdit.passwordConfirm;
      delete allValuesEdit.cep;
      delete allValuesEdit.logradouro;
      delete allValuesEdit.cidade;
      delete allValuesEdit.bairro;
      delete allValuesEdit.uf;
      delete allValuesEdit.complemento;
      delete allValuesEdit.telefone;
      delete allValuesEdit.numero;
      delete allValuesEdit.imoveis;
      await ApiService.post("/clientes", allValuesEdit)
        .then((response) => {
          toast("success", "Criado com sucesso");
          resetForm();
          if (response.status === 201) {
            navigate("/app/listar-clientes");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }
    setIsModalImovelAberto(false);
    if (!id) setSegmentoSelected(null);
    setSubmitting(false);
  };

  useEffect(() => {
    getSegmentosCliente();
  }, [id]);

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={"Clientes"}
          subtitle={
            isDeleteMode
              ? "Deletar cliente"
              : id
              ? "Editar"
              : "Cadastro de Clientes"
          }
          titleUrl={"/app/listar-clientes"}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      {isDeleteMode && (
        <Stack width={"100%"} p={1}>
          <Alert severity="error">
            Você está prestes a excluir o cliente abaixo:
          </Alert>
        </Stack>
      )}
      <Paper sx={{ p: "15px" }}>
        <Formik
          innerRef={formRef}
          initialValues={cliente}
          validationSchema={id ? validationSchemaEdit : validationSchema}
          enableReinitialize
          validateOnChange={true}
          validateOnBlur
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            updateInputValue,
            setFieldValue,
            errors,
          }) => (
            <Form style={{ width: "100%" }} noValidate autoComplete="off">
              <Grid
                container
                spacing={2}
                padding={2}
                alignContent={"center"}
                justifyContent={"center"}
              >
                {id && (
                  <Grid
                    item
                    lg={10}
                    height={"100%"}
                    justifyContent={"flex-start"}
                    flexDirection={"column"}
                  >
                    <form>
                      <Box
                        width={"100%"}
                        height={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"flex-start"}
                      >
                        <Typography fontSize={"16px"} style={{ margin: 2 }}>
                          Foto de perfil
                        </Typography>
                        <Avatar
                          alt="Foto do perfil"
                          src={
                            cliente.avatarUrl
                              ? `${process.env.REACT_APP_API_KEY}/public/uploads/${cliente.avatarUrl}`
                              : previewFotoPerfil
                          }
                          sx={{
                            width: 140,
                            height: 140,
                            marginTop: 2,
                            bgcolor: "success",
                          }}
                        >
                          {cliente.name
                            ? cliente.name.charAt(0).toUpperCase()
                            : "P"}
                        </Avatar>
                      </Box>
                      <Box
                        width={"100%"}
                        display={"flex"}
                        marginTop={2}
                        justifyContent={"center"}
                        gap={1}
                      >
                        <IconButton
                          color="info"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            name="foto"
                            hidden
                            accept="image/png, image/jpg, image/jpeg"
                            type="file"
                            onChange={(event) => {
                              handlePreviewFotoPerfil(event);
                              handleUploadImagemPerfil(event);
                            }}
                          />
                          <PhotoCamera />
                        </IconButton>
                        {previewFotoPerfil && (
                          <>
                            <IconButton onClick={modalAlert}>
                              <Delete color="error" />
                            </IconButton>
                          </>
                        )}
                      </Box>
                      {erroUploadFotoPerfil.status && (
                        <Box sx={{ textAlign: "center" }}>
                          <Typography color="error">
                            {erroUploadFotoPerfil.mensagem}
                          </Typography>
                        </Box>
                      )}
                    </form>
                  </Grid>
                )}
                <Grid item lg={10} marginTop={5}>
                  <Grid
                    container
                    alignContent={"center"}
                    justifyContent={"center"}
                    spacing={2}
                  >
                    <Grid item lg={5} xs={12}>
                      <JumboTextField
                        fullWidth
                        name="nome"
                        label="Nome"
                        required
                        value={values.nome}
                        onChange={handleChange}
                        sx={{ background: "#ffffff" }}
                      />
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      <JumboTextField
                        fullWidth
                        name="email"
                        label="Email"
                        required
                        value={values.email}
                        onChange={handleChange}
                        sx={{ background: "#ffffff" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignContent={"center"}
                    justifyContent={"center"}
                    spacing={2}
                    my={1}
                  >
                    <Grid item lg={5} xs={12}>
                      <FormGroup>
                        <FormLabel id="tipo-pessoa-label">
                          Tipo pessoa
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="tipo-pessoa-label"
                          value={values.tipo_pessoa}
                          name="tipo_pessoa"
                          onChange={handleChange}
                          row
                        >
                          <FormControlLabel
                            value="fisica"
                            control={<Radio color="success" />}
                            label="Pessoa física"
                          />
                          <FormControlLabel
                            value="juridica"
                            control={<Radio color="success" />}
                            label="Pessoa jurídica"
                          />
                        </RadioGroup>
                      </FormGroup>
                    </Grid>
                    <Grid item lg={5} xs={12}>
                      {values.tipo_pessoa === "fisica" ? (
                        <>
                          <ReactInputMask
                            mask="999.999.999-99"
                            maskChar=""
                            value={formattedCPF}
                            onChange={(e) => {
                              setFormattedCPF(e.target.value);
                              setFieldValue("cpf", formattedCPF);
                            }}
                          >
                            {(inputProps) => (
                              <TextField
                                fullWidth
                                name="cpf"
                                label="CPF"
                                sx={{ background: "#ffffff" }}
                                {...inputProps}
                              />
                            )}
                          </ReactInputMask>
                          <ErrorMessage
                            component={"div"}
                            name="cpf"
                            style={{ color: "red" }}
                          />
                        </>
                      ) : (
                        <>
                          <ReactInputMask
                            mask="99.999.999/9999-99"
                            maskChar=""
                            value={formattedCNPJ}
                            onChange={(e) => {
                              setFormattedCNPJ(e.target.value);
                              setFieldValue("cnpj", formattedCNPJ);
                            }}
                          >
                            {(inputProps) => (
                              <TextField
                                fullWidth
                                name="cnpj"
                                label="CNPJ"
                                sx={{ background: "#ffffff" }}
                                {...inputProps}
                              />
                            )}
                          </ReactInputMask>
                          {errors.cnpj && (
                            <div style={{ color: "red" }}>{errors.cnpj}</div>
                          )}
                        </>
                      )}

                      <Divider />
                    </Grid>
                    <Grid item lg={10} xs={12}>
                      <div style={{ position: "relative" }}>
                        <CreatableSelect
                          isClearable
                          styles={customStyles}
                          name={`segmento_cliente_id`}
                          onChange={(option) => {
                            if (option) {
                              setSegmentoSelected({
                                id: option.id,
                                descricao: option.descricao,
                              });
                              setFieldValue(
                                `segmento_cliente_id`,
                                option ? option.id : ""
                              );
                            }
                          }}
                          onCreateOption={async (value) => {
                            const { id, descricao } =
                              await handleCreateSegmentoCliente(value);
                            setSegmentoSelected({ id, descricao });
                            setFieldValue(`segmento_cliente_id`, id);
                          }}
                          options={optionsSegmentos}
                          getOptionLabel={(option) =>
                            option.__isNew__ ? option.label : option.descricao
                          }
                          value={segmentoSelected}
                          placeholder={"Selecione o segmento do cliente"}
                          menuPosition="top"
                          menuPlacement="auto"
                          maxMenuHeight={115}
                        />
                        <ErrorMessage
                          name="segmento_cliente_id"
                          component={"div"}
                          style={{ color: "red" }}
                        />
                      </div>
                    </Grid>
                    {id && (
                      <>
                        <Grid item lg={5} xs={12}>
                          <Typography component={"h4"} variant="h4" my={2}>
                            Informações complementares
                          </Typography>
                          <ReactInputMask
                            mask={mascaraTelefone}
                            maskChar=""
                            value={formattedTelefone}
                            onChange={handleTelefoneChange}
                          >
                            {(inputProps) => (
                              <TextField
                                fullWidth
                                name="telefone"
                                label="Telefone"
                                sx={{ background: "#ffffff" }}
                                {...inputProps}
                              />
                            )}
                          </ReactInputMask>

                          {errors.telefone && (
                            <div style={{ color: "red" }}>
                              {errors.telefone}
                            </div>
                          )}
                        </Grid>
                        <Grid item lg={5} xs={12} marginTop={6.6}>
                          <ReactInputMask
                            mask="99999-999"
                            value={formattedCEP}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFormatedCEP(value);
                              setFieldValue("cep", value);
                            }}
                          >
                            {(inputProps) => (
                              <TextField
                                fullWidth
                                name="cep"
                                label="CEP"
                                sx={{ background: "#ffffff" }}
                                {...inputProps}
                              />
                            )}
                          </ReactInputMask>
                          {errors.cep && (
                            <div style={{ color: "red" }}>{errors.cep}</div>
                          )}
                        </Grid>
                        <Grid item lg={5} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="logradouro"
                            label="Logradouro"
                            value={values.logradouro}
                            onChange={handleChange}
                            sx={{ background: "#ffffff" }}
                          />
                        </Grid>
                        <Grid item lg={2} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="numero"
                            label="Número"
                            value={values.numero}
                            onChange={handleChange}
                            sx={{ background: "#ffffff" }}
                          />
                        </Grid>
                        <Grid item lg={3} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="complemento"
                            label="Complemento"
                            value={values.complemento}
                            onChange={handleChange}
                            sx={{ background: "#ffffff" }}
                          />
                        </Grid>
                        <Grid item lg={3} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="bairro"
                            label="Bairro"
                            value={values.bairro}
                            onChange={handleChange}
                            sx={{ background: "#ffffff" }}
                          />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          <JumboTextField
                            fullWidth
                            name="cidade"
                            label="Cidade"
                            value={values.cidade}
                            onChange={handleChange}
                            sx={{ background: "#ffffff" }}
                          />
                        </Grid>
                        <Grid item lg={3} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="select-estado-label">UF</InputLabel>
                            <Select
                              labelId="select-estado-label"
                              id="select-estado"
                              value={values.uf}
                              label={"UF"}
                              name={"uf"}
                              onChange={handleChange}
                              fullWidth
                            >
                              {Object.entries(ESTADOS).map(
                                ([sigla, estado]) => (
                                  <MenuItem value={sigla}>{estado}</MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={10} xs={12}>
                          <Stack
                            direction="row"
                            justifyContent={"space-between"}
                          >
                            <Typography component={"h4"} variant="h4" my={2}>
                              Imóveis
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item lg={8} xs={12}>
                          <FieldArray name="imoveis">
                            {({ push, remove }) => (
                              <Box width={"100%"} sx={{ marginX: 5 }}>
                                <>
                                  <Button
                                    type="button"
                                    sx={{ marginY: 2 }}
                                    onClick={() => {
                                      push({
                                        descricao: "",
                                        cidade: "",
                                        uf: "",
                                        logradouro: "",
                                        cep: "",
                                        bairro: "",
                                        numero: "",
                                        complemento: "",
                                      });
                                      setIndiceImovelSelecionado(
                                        values.imoveis.length
                                      );
                                      handleOpenModalImovel();
                                    }}
                                  >
                                    <AddIcon /> Adicionar imóvel
                                  </Button>

                                  {id && (
                                    <>
                                      <ListImoveisCliente
                                        imoveis={imoveisCliente}
                                        setImovelSelecionado={
                                          handleChangeImovelSelecionado
                                        }
                                        setIndiceImovelSelecionado={
                                          setIndiceImovelSelecionado
                                        }
                                        handleOpenModal={handleOpenModalImovel}
                                        handleDelete={handleDeleteImovel}
                                      />
                                    </>
                                  )}
                                  <ListImoveisCliente
                                    imoveis={values.imoveis}
                                    setImovelSelecionado={
                                      handleChangeImovelSelecionado
                                    }
                                    setIndiceImovelSelecionado={
                                      setIndiceImovelSelecionado
                                    }
                                    handleOpenModal={handleOpenModalImovel}
                                    removeImovel={remove}
                                    handleDelete={handleDeleteImovel}
                                  />
                                  <ModalImovelForm
                                    aberto={isModalImovelAberto}
                                    handleClose={handleOpenModalImovel}
                                    handleChange={handleChange}
                                    handleImovelJaCadastradoChange={
                                      handleImovelChange
                                    }
                                    imovelSelecionado={imovelSelecionado}
                                    index={indiceImovelSelecionado}
                                    values={values}
                                  />
                                </>
                              </Box>
                            )}
                          </FieldArray>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item lg={12} xs={12}></Grid>
                <Grid>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    marginTop={3}
                  >
                    {isDeleteMode ? (
                      <Stack direction="row" gap={2} display={"flex"}>
                        <Box>
                          <Button
                            color="error"
                            variant="contained"
                            onClick={() => {
                              modalAlert(id);
                            }}
                          >
                            Excluir imóvel
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              navigate(`/app/listar-clientes`);
                            }}
                          >
                            Cancelar
                          </Button>
                        </Box>
                      </Stack>
                    ) : (
                      <LoadingButton
                        loading={isSubmitting}
                        type="submit"
                        style={{ margin: "auto", display: "block" }}
                        variant="contained"
                        color="primary"
                      >
                        {id ? "Atualizar" : "Criar"}
                      </LoadingButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default ClienteForm;
