import moment from "moment";
import React from "react";

const RESPONSAVEIS = {
    ambiental: "Ambiental",
    cliente: "Cliente",
    orgao: "Órgão",
    protocolado: "Protocolado"
}

const ListaRelatoriosResumidoPDF = ({ contratos }) => {

  return (
    <table
      className="tabela-contratos"
      style={{ width: "150mm", tableLayout: "auto" }}
    >
      {contratos && contratos.length > 0 ? (
        contratos.map((contrato, contratoIndex) => (
          <React.Fragment key={contratoIndex}>
            {contrato.unidades.map((unidade, unidadeIndex) => (
              <React.Fragment key={unidadeIndex}>
                {unidade.imoveis.map((imovel, imovelIndex) => (
                  <React.Fragment key={imovelIndex}>
                    {imovel.processos_contratos
                      .filter(
                        (processo) =>
                          processo.servicos && processo.servicos.length > 0
                      )
                      .map((processo, processoIndex) => {

                        return (
                          <React.Fragment key={processoIndex}>
                            <tr>
                              <td
                                colSpan={5}
                                style={{
                                  background: "#024900",
                                  color: "white",
                                }}
                              >
                                <strong>Contrato: {contrato.descricao}</strong>
                                <br />
                                <strong>Unidade:</strong> {unidade?.nomeUnidade}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  background: "#2BC900",
                                  color: "white",
                                }}
                              >
                                Processo
                              </td>
                              <td
                                style={{
                                  background: "#2BC900",
                                  color: "white",
                                }}
                              >
                                Imóvel
                              </td>
                              <td
                                style={{
                                  background: "#2BC900",
                                  color: "white",
                                }}
                              >
                                Aprovação
                              </td>
                              <td
                                style={{
                                  background: "#2BC900",
                                  color: "white",
                                }}
                              >
                                Pendências
                              </td>
                              <td
                                style={{
                                  background: "#2BC900",
                                  color: "white",
                                }}
                              >
                                Colaboradores
                              </td>
                            </tr>
                            {processo.servicos.map((servico, servicoIndex) => {
                              return (
                                <tr key={servicoIndex}>
                                  <td>
                                    {processo?.processo_administrativo?.descricao ??
                                      "N/I"}
                                  </td>
                                  <td>
                                    {
                                      imovel?.descricao
                                    }
                                  </td>
                                  <td>
                                    {contrato?.data_aprovacao
                                      ? moment
                                          .utc(contrato?.data_aprovacao)
                                          .format("DD/MM/YYYY")
                                      : "Sem data"}
                                  </td>
                                  <td>
                                    {RESPONSAVEIS[servico?.responsavel] ?? "N/I"}
                                  </td>
                                  <td>
                                    {servico?.servicocolaborador?.length > 0
                                      ? servico?.servicocolaborador
                                          ?.map(
                                            (colaborador) =>
                                              colaborador?.users?.name
                                          )
                                          .join(", ")
                                      : "N/I"}
                                  </td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </React.Fragment>
        ))
      ) : (
        <tr>
          <td colSpan={5}>Nenhum contrato disponível</td>
        </tr>
      )}
    </table>
  );
};

export default ListaRelatoriosResumidoPDF;
