import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Card } from "@mui/material";
import React, { forwardRef, useEffect, useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReplayIcon from "@mui/icons-material/Replay";
import StarIcon from "@mui/icons-material/Star";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const OBJETO_RESPONSAVEIS = {
  cliente: "Cliente",
  orgao: "Órgão",
  ambiental: "Ambiental",
  protocolado: "Protocolado",
};

const ListaServicosDashboard = forwardRef(
  (
    {
      processo,
      iniciarServico,
      concluirServico,
      aumentarPrioridade,
      diminuirPrioridade,
      paralisarServico,
      scrollToServicoId,
      setScrollToServicoId,
      servicosRefs,
      abaSelecionada,
      imoveisDosContratos
    },
    ref
  ) => {
    const handleChangeScrollToServicoId = (servicoId) => {
      setScrollToServicoId(servicoId);
    };

    const navigate = useNavigate();

    return (
      <Card sx={{ mb: 1, p: 2 }} style={{ width: "100%" }}>
        <Accordion
          key={processo?.id}
          p={2}
          style={{ width: "100%" }}
          defaultExpanded={true}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item lg={4}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4
                  }}
                >
                  <Typography fontSize={"24px"} style={{ fontWeight: "500" }}>
                    <Chip
                      color="success"
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "18px"
                          }}
                        >
                          {processo?.processos[0]?.contrato?.descricao}
                          {processo?.processos[0]?.contrato?.tipocontratocontrato?.some(
                            (tipoContrato) =>
                              String(
                                tipoContrato.tipos_contratos.descricao
                              ).toLowerCase() === "Renovação".toLowerCase()
                          ) && <ReplayIcon fontSize={"small"} gap={10} />}
                          {processo?.processos[0]?.contrato.tipocontratocontrato?.some(
                            (tipoContrato) =>
                              String(
                                tipoContrato.tipos_contratos.descricao
                              ).toLowerCase() === "contrato fixo"
                          ) && <StarIcon fontSize={"small"} gap={10} />}
                        </Box>
                      }
                    />
                  </Typography>
                  {imoveisDosContratos
                    .filter(item => item.contrato_id === processo?.processos[0]?.contrato?.id)
                    .map((imovel) => (
                      <>
                        <Typography key={imovel.descricao} fontSize={12} fontWeight={500}>
                          <Chip
                            color="warning"
                            label={
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography fontSize={12} fontWeight={500}>
                                  {imovel.descricao}
                                </Typography>
                              </Box>
                            }
                            my={2}
                          />
                        </Typography>
                        <Typography key={imovel.unidade} fontSize={12} fontWeight={500}>
                          <Chip
                            color="warning"
                            label={
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography fontSize={12} fontWeight={500}>
                                  {imovel.unidade}
                                </Typography>
                              </Box>
                            }
                            my={2}
                          />
                        </Typography>
                      </>
                    ))
                  }
                </Box>
              </Grid>
              <Grid item lg={4}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontSize={"20px"} style={{ fontWeight: "500" }}>
                    {processo?.processos[0]?.processo_administrativo?.descricao ?? "N/I"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontSize={"18px"}>
                    Cliente
                  </Typography>
                  <Typography fontSize={"20px"} style={{ fontWeight: "500" }}>
                    {processo?.processos[0]?.contrato?.Cliente?.nome ?? "N/I"}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography fontSize={"18px"} style={{ fontWeight: "500" }}>
                    Ações
                  </Typography>
                  <Stack direction={"row"} gap={1}>
                    <Tooltip
                      title="Aumentar prioridade"
                      onClick={() => {
                        aumentarPrioridade(
                          processo?.id
                        );
                      }}
                    >
                      <ArrowCircleUpIcon color="info" cursor="pointer" />
                    </Tooltip>
                    <Tooltip
                      title="Diminuir prioridade"
                      onClick={() => {
                        diminuirPrioridade(
                          processo?.id
                        );
                      }}
                    >
                      <ArrowCircleDownIcon color="info" cursor="pointer" />
                    </Tooltip>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            {processo?.processos[0]?.servicos && processo?.processos[0]?.servicos.length > 0 ? (
              processo?.processos[0]?.servicos.map((servico, index) => (
                <>
                  <Grid
                    container
                    justifyContent="space-around"
                    alignItems="center"
                    gap={1}
                    ref={(el) => (servicosRefs.current[servico.id] = el)}
                    id={servico.id}
                  >
                    <Grid item lg={3}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          fontSize={"18px"}
                          style={{ fontWeight: "500" }}
                        >
                          Tarefa
                        </Typography>
                        <Typography fontSize={"16px"}>
                          {servico?.tarefas_servico[0]?.descricao ?? "N/I"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={2}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          fontSize={"18px"}
                          style={{ fontWeight: "500" }}
                        >
                          Situação
                        </Typography>
                        <Typography fontSize={"16px"}>
                          {servico?.status_andamento_servico?.descricao ??
                            "N/I"}
                        </Typography>{" "}
                        {abaSelecionada === "em_andamento" ? <Typography>{Number(servico?.porcentagem).toFixed(2)}%</Typography> : null}
                      </Box>
                    </Grid>
                    <Grid item lg={2}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          fontSize={"18px"}
                          style={{ fontWeight: "500" }}
                        >
                          Datas
                        </Typography>
                        <Typography fontSize={"16px"}>
                          Início:{" "}
                          {servico?.data_inicio
                            ? moment
                                .utc(servico?.data_inicio)
                                .format("DD/MM/YYYY")
                            : "Não iniciado"}
                        </Typography>
                        <Typography fontSize={"16px"}>
                          Conclusão:{" "}
                          {servico?.data_conclusao
                            ? moment
                                .utc(servico?.data_conclusao)
                                .format("DD/MM/YYYY")
                            : "Sem data"}
                        </Typography>
                        <Typography fontSize={"16px"}>
                          Prazo:{" "}
                          {servico?.prazo
                            ? moment.utc(servico?.prazo).format("DD/MM/YYYY")
                            : "Sem data"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={2}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          fontSize={"18px"}
                          style={{ fontWeight: "500" }}
                        >
                          Responsabilidade
                        </Typography>
                        <Typography fontSize={"16px"}>
                          {OBJETO_RESPONSAVEIS[servico?.responsavel]}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={2}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          fontSize={"18px"}
                          style={{ fontWeight: "500" }}
                        >
                          Ações
                        </Typography>
                        <Stack direction={"row"} gap={1}>
                          <Tooltip title="Acompanhar Serviço">
                            <VisibilityIcon cursor="pointer" color="info" onClick={() => {
                              navigate(`/app/contrato/${processo?.contrato?.id}/cliente/${processo?.contrato?.Cliente?.id}/processos/${processo?.id}/procadmin/${processo?.processo_administrativo?.id}/servicos/${servico?.id}/detalhes`)
                            }} />
                          </Tooltip>
                          <Tooltip
                            title={
                              servico.status_andamento_servico.id === 1
                                ? "Serviço já iniciado"
                                : "Iniciar Serviço"
                            }
                            onClick={() => {
                              iniciarServico(
                                servico.id,
                                servico.status_andamento_servico.id
                              );
                              handleChangeScrollToServicoId(servico.id);
                            }}
                          >
                            <PlayArrowIcon
                              color={
                                abaSelecionada === "em_andamento"
                                  ? "success"
                                  : "disabled"
                              }
                              cursor="pointer"
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              servico.status_andamento_servico.id === 6 &&
                              !servico.is_paralisado
                                ? "Serviço já paralisado"
                                : "Paralisar Serviço"
                            }
                            onClick={() => {
                              paralisarServico(
                                servico.id,
                                servico.status_andamento_servico.id
                              );
                              handleChangeScrollToServicoId(servico.id);
                            }}
                          >
                            <PauseIcon
                              color={
                                servico.status_andamento_servico.id === 6 &&
                                !servico.is_paralisado
                                  ? "warning"
                                  : "disabled"
                              }
                              cursor="pointer"
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              servico.status_andamento_servico.id === 12 || servico.status_andamento_servico.id === 13
                                ? "Serviço já concluído"
                                : "Concluir Serviço"
                            }
                            onClick={() => {
                              concluirServico(
                                servico.id,
                                servico.status_andamento_servico.id,
                                servico?.Contrato?.id
                              );
                              handleChangeScrollToServicoId(servico.id);
                            }}
                          >
                            <StopIcon
                              color={
                                servico.status_andamento_servico.id === 12 || 
                                servico.status_andamento_servico.id === 13
                                  ? "error"
                                  : "disabled"
                              }
                              cursor="pointer"
                            />
                          </Tooltip>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </>
              ))
            ) : (
              <Box width="100%" p={2} textAlign={"center"}>
                Nenhum serviço encontrado.
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Card>
    );
  }
);

export default ListaServicosDashboard;
