import React from "react";

const ContratosRenovacaoIndividual = ({
  isModoPaisagem,
  contrato,
}) => {
  return (
    <table
      className="tabela-contratos"
      style={{ width: isModoPaisagem ? "150mm" : "100mm" }}
    >
      {contrato && contrato?.processos_contratos?.length > 0 ? (
        <>
          {contrato?.processos_contratos
            .filter((processo) => processo.servicos.length > 0)
            .map((processo, index) => {
              const totalProcesso = processo?.servicos.reduce(
                (acc, servico) => acc + servico?.valor * servico?.quantidade,
                0
              );

              return (
                <React.Fragment key={index}>
                  <thead>
                    <tr>
                      <th
                        style={{ background: "#024900", color: "#FFF" }}
                        colSpan={5}
                      >
                        Processo administrativo:{" "}
                        {processo?.processo_administrativo?.descricao}
                      </th>
                    </tr>
                    <tr>
                      <th style={{ background: "#0B9300", color: "#FFF" }}>
                        Contrato
                      </th>
                      <th style={{ background: "#0B9300", color: "#FFF" }}>
                        Tarefa
                      </th>
                      <th style={{ background: "#0B9300", color: "#FFF" }}>
                        Quantidade
                      </th>
                      <th style={{ background: "#0B9300", color: "#FFF" }}>
                        Valor
                      </th>
                      <th style={{ background: "#0B9300", color: "#FFF" }}>
                        Valor Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="body-contratos">
                    {processo?.servicos.map((servico, servicoIndex) => (
                      <tr key={servicoIndex}>
                        <td>{contrato?.descricao}</td>
                        <td>{servico?.tarefas_servico[0]?.descricao}</td>
                        <td>{servico?.quantidade}</td>
                        <td>
                          {Number(servico?.valor).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                        <td>
                          {Number(
                            servico?.valor * servico?.quantidade
                          ).toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan={4}
                        style={{
                          background: "#0B9300",
                          color: "#FFF",
                          textAlign: "right",
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          background: "#0B9300",
                          color: "#FFF",
                        }}
                      >
                        {Number(totalProcesso).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                    </tr>
                  </tbody>
                </React.Fragment>
              );
            })}
        </>
      ) : null}
    </table>
  );
};

export default ContratosRenovacaoIndividual;
