import ApiService from "app/services/config";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const gerarPDFContratoFixoRenovacaoIndividual = async (contratoId) => {
  const obterUrlImagem = async (path) => {
    const blogImage = await fetch(process.env.REACT_APP_ROOT_URL + path).then(
      (data) => data.blob()
    );
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blogImage);
    });
    return dataUrl;
  };

  const imagemIcone = await obterUrlImagem("/images/pdf/icone.png");
  const imagemTexto = await obterUrlImagem("/images/pdf/nome.png");

  const getContrato = async () => {
    try {
      const response = await ApiService.get(
        `/contratos/exportar/pdf/${contratoId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const contrato = await getContrato();
  let valorTotalContrato = 0;

  const documentDefinition = {
    content: [
      {
        columns: [
          {
            image: imagemIcone,
            alignment: "left",
            width: 55,
          },
          {
            text: "",
            width: "*",
          },
          {
            image: imagemTexto,
            alignment: "right",
            width: 150,
          },
        ],
        margin: [0, 0, 0, 10],
      },
      {
        text: "\n",
      },
      {
        text: "\n",
      },
      {
        text: `PROPOSTA DE ORÇAMENTO - ${contrato?.descricao} - Fixo - Renovação`,
        fontSize: 10,
        bold: true,
      },
      {
        text: [
          { text: "Cliente: ", bold: true },
          {
            text: `${contrato.Cliente?.nome ?? 'N/I'} - ${String(
              contrato?.tipo_orcamento?.descricao
            ).toUpperCase()}`,
            bold: false,
          },
        ],
        fontSize: 10,
      },
      {
        text: [
          { text: "Data de vencimento da renovação: ", bold: true },
          {
            text: contrato?.dataVencimento
              ? `${moment
                  .utc(contrato?.dataVencimento)
                  .format("DD [de] MMMM [de] YYYY")}`
              : "Sem data",
            bold: false,
          },
        ],
        fontSize: 10,
      },
      {
        text: "\n",
      },
    ],
    pageOrientation: "portrait",
    fontSize: 10,
    styles: {
      header: {
        bold: true,
        fontSize: 10,
        margin: [0, 10, 0, 5],
        color: "white",
        fillColor: "#024900",
        alignment: "left",
        padding: [6, 4, 0, 4],
      },
      subheader: {
        bold: true,
        fontSize: 10,
        margin: [0, 5, 0, 5],
        color: "white",
        fillColor: "#0B9300",
        alignment: "left",
        padding: [6, 4, 0, 4],
      },
      tableHeader: {
        fillColor: "#2BC900",
        color: "white",
        bold: true,
        alignment: "center",
        margin: [0, 5, 0, 5],
        padding: [6, 4],
        fontSize: 10,
      },
      tableCell: {
        margin: [0, 15, 0, 15],
        padding: [8, 4],
        fontSize: 10,
        alignment: "left",
        baseline: "middle",
      },
      bordered: {
        margin: [0, 0, 0, 0],
        table: {
          widths: ["20%", "20%", "20%"],
          body: [],
        },
        layout: {
          hLineWidth: () => 1,
          vLineWidth: () => 1,
          hLineColor: () => "#000",
          vLineColor: () => "#000",
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 6,
          paddingBottom: () => 6,
        },
      },
    },
  };

  documentDefinition.content.push(
    {
      text: "MATRIZ DE SERVIÇOS",
      fontSize: 10,
      bold: true,
    },
    {
      text: "\n",
    }
  );

  if (contrato && contrato?.unidades?.length > 0) {
    const tableBody = [];

    contrato.unidades.forEach((unidade) => {
      if (unidade.imoveis.length > 0) {
        unidade.imoveis.forEach((imovel) => {
          const processos = imovel.processos_contratos.filter(
            (processo) => processo.servicos && processo.servicos.length > 0
          );

          if (processos.length > 0) {
            processos.forEach((processo) => {
              tableBody.push([
                {
                  text: [
                    { text: "Processo administrativo: ", fontWeight: 1200 },
                    {
                      text: processo?.processo_administrativo?.descricao,
                      bold: false,
                    },
                  ],
                  style: "header",
                  colSpan: 3,
                  alignment: "left",
                },
                {},
                {},
              ]);
              tableBody.push([
                {
                  text: [
                    { text: "Imóvel: ", fontWeight: 1200 },
                    { text: imovel?.descricao, bold: false },
                  ],
                  style: "subheader",
                  colSpan: 3,
                  alignment: "left",
                },
                {},
                {},
              ]);
              tableBody.push([
                { text: "Contrato", style: "tableHeader" },
                { text: "Tarefa", style: "tableHeader" },
                { text: "Quantidade", style: "tableHeader" },
              ]);

              imovel?.processos_contratos?.forEach((processoContrato) =>
                processoContrato?.servicos?.forEach((servicoContrato) => {
                  if (servicoContrato?.processo_contrato_id === processo?.id) {
                    const valorTotalServico =
                      servicoContrato?.valor * servicoContrato?.quantidade;
                    valorTotalContrato += valorTotalServico;

                    tableBody.push([
                      {
                        text: contrato?.descricao ?? "N/I",
                        style: "tableCell",
                      },
                      {
                        text:
                          servicoContrato?.tarefas_servico[0]?.descricao ??
                          "N/I",
                        style: "tableCell",
                      },
                      {
                        text: servicoContrato?.quantidade ?? "N/I",
                        style: "tableCell",
                      },
                    ]);
                  }
                })
              );
            });
          }
        });
      }
    });

    if (contrato?.renegociacoescontrato.length > 0 && Number(contrato?.renegociacoescontrato[0]?.valor) < Number(valorTotalContrato)) {
      tableBody.push([
        {
          text: "Total",
          alignment: "left",
          bold: true,
          fontSize: 10,
          style: "subheader",
          colSpan: 2,
        },
        {},
        {
          text: valorTotalContrato.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
          alignment: "right",
          bold: true,
          fontSize: 10,
          style: "subheader",
          decoration:
          contrato?.renegociacoescontrato?.length > 0 && Number(contrato?.renegociacoescontrato[0]?.valor) < Number(valorTotalContrato) ? "lineThrough" : "",
        },
      ]);
    }

    if (contrato?.renegociacoescontrato.length > 0) {
      tableBody.push([
        {
          text: "Total consolidado",
          alignment: "left",
          bold: true,
          fontSize: 10,
          style: "subheader",
          colSpan: 2,
        },
        {},
        {
          text: contrato?.renegociacoescontrato[0]?.valor?.toLocaleString(
            "pt-BR",
            {
              style: "currency",
              currency: "BRL",
            }
          ),
          alignment: "right",
          bold: true,
          fontSize: 10,
          style: "subheader",
        },
      ]);
    }

    documentDefinition.content.push({
      style: "bordered",
      table: {
        widths: ["*", "*", "*"],
        body: [...tableBody],
      },
    });
  } else {
    documentDefinition.content.push({
      text: "Nenhum contrato disponível",
      style: "subheader",
    });
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  pdfDocGenerator.getBlob((blob) => {
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  });
};

export default gerarPDFContratoFixoRenovacaoIndividual;
