import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import { LoadingButton } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ModalCadastroImovelCliente from "app/components/ModalCadastroImovelCliente/ModalCadastroImovelCliente";
import EditIcon from "@mui/icons-material/Edit";

const ClientesList = () => {
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [clientes, setClientes] = useState([]);
  const [segmentosCliente, setSegmentosCliente] = useState([]);
  const [selectedClienteIds, setSelectedClienteIds] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState("");
  const [clienteSelecionado, setClienteSelecionado] = useState({
    id: null,
    nome: null,
  });
  const [todosClientesSelecionados, setTodosClientesSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [
    isModalAdicionarImovelClienteAberto,
    setIsModalAdicionarImovelClienteAberto,
  ] = useState(false);

  const navigate = useNavigate();
  const { hasPermission } = useContext(PermissionContext);
  const permission = hasPermission("Clientes", "create");
  const permissionUpdate = hasPermission("Clientes", "update");
  const permissionDelete = hasPermission("Clientes", "delete");
  if (!hasPermission("Clientes", "read")) {
    navigate("/app");
  }

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleSelecionarTodosClientes = () => {
    const todosIdsClientes = clientes.map((cliente) => cliente.id);
    if (!todosClientesSelecionados) {
      setSelectedClienteIds(todosIdsClientes);
    } else {
      setSelectedClienteIds([]);
    }
    setTodosClientesSelecionados(!todosClientesSelecionados);
  };

  const handleDeleteMuitosClientes = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os clientes selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosClientes();
      }
    });
  };

  const deleteMuitosClientes = useCallback(async () => {
    try {
      await ApiService.delete("/clientes/delete/many", {
        data: { ids: selectedClienteIds },
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Clientes removidos com sucesso!");
          getClientes();
          setSelectedClienteIds([]);
        }
      });
    } catch (error) {
      toast("error", "Não foi possível deletar os clientes selecionados");
      console.log(error);
    }
  }, [selectedClienteIds]);

  

  const getClientes = useCallback(async () => {
    try {
      let apiUrl = `/clientes?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }
      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.clientes.map((cliente) => ({
            ...cliente,
            isSelected: selectedClienteIds.includes(cliente.id),
          }));
          setClientes(initialData);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page, perPage, sortBy, sortOrder, searchTerm, selectedClienteIds]);

  // Nessa função, os dados de segmentos de cliente são puxados da API
  // Para depois o nome do segmento do cliente ser exibido no frontend
  const getSegmentosCliente = useCallback(async () => {
    try {
      await ApiService.get("/segmentos/all").then((response) => {
        setSegmentosCliente(response.data);
      });
    } catch (err) {
      console.log(err);
    }
  });

  useEffect(() => {
    getClientes();
    getSegmentosCliente();
  }, [getClientes]);

  const handleCheckboxChange = (clienteId) => {
    setClientes((prevClientes) =>
      prevClientes.map((cliente) =>
        cliente.id === clienteId
          ? { ...cliente, isSelected: !cliente.isSelected }
          : cliente
      )
    );

    setSelectedClienteIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(clienteId)) {
        return prevSelectedIds.filter((id) => id !== clienteId);
      } else {
        return [...prevSelectedIds, clienteId];
      }
    });

    setMostrarBotaoDeletarMuitos(selectedClienteIds.length > 0);
    setTodosClientesSelecionados(false);
  };

  const handleOpenModalAdicionarImovelCliente = () => {
    setIsModalAdicionarImovelClienteAberto(
      !isModalAdicionarImovelClienteAberto
    );
  };

  const columns = [
    {
      name: "selecionar",
      label: "Selecionar",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const clienteId = tableMeta.rowData[1];
          const cliente = clientes.find((cliente) => cliente.id === clienteId);
          return (
            <Checkbox
              checked={cliente.isSelected}
              onChange={() => handleCheckboxChange(clienteId)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "nome",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "segmento_cliente_id",
      label: "Segmento",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const idSegmento = tableMeta.rowData[4];
          const segmentoCliente = segmentosCliente.find(
            (segmento) => segmento.id === idSegmento
          );
          if (segmentoCliente) {
            return <span>{segmentoCliente.descricao}</span>;
          } else {
            return <span>Não encontrado</span>;
          }
        },
      },
    },
    {
      name: "action",
      label: "Ações",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const clienteId = tableMeta.rowData[1];
          return (
            <Box display={"flex"} gap={"1rem"}>
              <Tooltip title="Editar Cliente">
                <EditIcon
                  cursor={permissionUpdate ? "pointer" : "not-allowed"}
                  color="info"
                  style={{ opacity: permissionUpdate ? 1 : 0.5 }}
                  disabled={!permissionUpdate}
                  onClick={() => {
                    if (permissionUpdate) {
                      navigate(`/app/editar-cliente/${clienteId}`);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip title="Unidades">
                <MapsHomeWorkIcon
                  // cursor={permissionUpdate ? "pointer" : "not-allowed"}
                  cursor={"pointer"}
                  color="success"
                  // style={{ opacity: permissionUpdate ? 1 : 0.5 }}
                  onClick={() => {
                    // if (permissionUpdate) {
                      navigate(`/app/cliente/unidades/${clienteId}`, {
                        state: {
                          nomeCliente: tableMeta.rowData[2]
                        }
                      })
                    // }
                  }}
                />
              </Tooltip>

              <Tooltip title="Excluir Cliente">
                <DeleteIcon 
                  cursor={permissionDelete ? "pointer" : "not-allowed"}
                  color="error"
                  style={{ opacity: permissionDelete ? 1 : 0.5 }}
                  onClick={() => {
                    if (permissionDelete) {
                      navigate(`/app/cliente/${clienteId}/deletar`);
                    }
                  }}
                />
              </Tooltip>
            </Box>
          );
        },
      },
    },
  ];

  const options = {
    changeRowsPerPage: perPage,
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchAlwaysOpen: true,
    searchable: true,
    serverSide: true,
    page: page,
    count: count,
    selectToolbarPlacement: "above",

    onTableChange: (action, tableState) => {
      console.log(action);
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "sort":
          setSortBy(tableState.sortOrder.name);
          setSortOrder(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearch(tableState.searchText);
          break;
        case "changeRowsPerPage":
          setPerPage(tableState.rowsPerPage);
          break;
        default:
          console.log("action not handled.");
      }
    },
    customToolbar: ({ displayData }) => (
      <>
        {selectedClienteIds.length > 0 ? (
          <Toolbar>
            <Box
              display={"flex"}
              gap={"1rem"}
              justifyContent={"flex-end"}
              width={"100%"}
            ></Box>
          </Toolbar>
        ) : null}
      </>
    ),
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima Página",
        previous: "Página Anterior",
        rowsPerPage: "Itens por Página:",
        displayRows: "of",
      },
    },
  };

  return (
    <JumboContentLayout
      header={<PageHeader title={"Clientes"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <LoadingButton
          color="success"
          type="submit"
          variant="contained"
          size="large"
          sx={{
            maxWidth: { md: "200px", mt: "1rem" },
            marginBottom: "2rem",
          }}
          disabled={!permission}
          onClick={() => {
            navigate(`/app/novo-cliente`);
          }}
        >
          Novo Cliente
        </LoadingButton>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        {selectedClienteIds.length > 0 && (
          <Grid item>
            <span style={{ margin: 10 }}>
              {selectedClienteIds.length} cliente(s) selecionado(s)
            </span>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteMuitosClientes}
              disabled={!permission}
            >
              Excluir Selecionados
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelecionarTodosClientes}
          >
            {todosClientesSelecionados ? "Desmarcar Todos" : "Selecionar Todos"}
          </Button>
        </Grid>
      </Grid>

      <React.Fragment>
        <Box mt={3}>
          <MUIDataTable
            title={"Clientes"}
            data={clientes}
            columns={columns}
            options={options}
          />
        </Box>
        <ModalCadastroImovelCliente
          aberto={isModalAdicionarImovelClienteAberto}
          handleClose={handleOpenModalAdicionarImovelCliente}
          clienteSelecionado={clienteSelecionado}
        />
      </React.Fragment>
    </JumboContentLayout>
  );
};

export default ClientesList;
