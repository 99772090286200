import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Select,
  Avatar,
  Box,
  Button,
  IconButton,
  Zoom,
  Autocomplete,
  Tooltip,
  FormControl,
  FormGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Alert,
  InputAdornment,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { ApiService } from "app/services/config";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { updateInputValue } from "../../utils/appHelpers";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import makeAnimated from "react-select/animated";
import ReactInputMask from "react-input-mask";
import { Delete, PhotoCamera } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { PermissionContext } from "app/contexts/PermissionContext";
import CreatableSelect from "react-select/creatable";
import { consultarCEP } from "app/utils/consultarCEP";
import axios from "axios";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
    zIndex: 9999,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const ESTADOS = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

const validationSchema = yup.object().shape({
  nome: yup
    .string("Insira o nome do cliente")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Preenchimento obrigatório")
    .required("Preenchimento obrigatório"),
  tipo_pessoa: yup.string("Campo obrigatório").required("Campo obrigatório"),
  cpf: yup
    .string()
    .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, {
      message: "CPF Inválido",
      excludeEmptyString: true,
    })
    .nullable()
    .when("tipo_pessoa", {
      is: "fisica",
      then: yup.string().required("CPF é obrigatório para pessoa física"),
    })
    .typeError("CPF Inválido"),
  cnpj: yup
    .string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
      message: "CNPJ Inválido",
      excludeEmptyString: true,
    })
    .nullable()
    .when("tipo_pessoa", {
      is: "juridica",
      then: yup.string().required("CNPJ é obrigatório para pessoa jurídica"),
    })
    .typeError("CNPJ Inválido"),
  segmento_cliente: yup
    .number("ID Inválido")
    .required("Selecione um Segmento de Cliente"),
  telefone: yup
    .string()
    .matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, "Telefone inválido")
    .typeError("Telefone inválido"),
  password: yup.string().required("Preenchimento obrigatório"),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
    .required("Preenchimento obrigatório"),
  avatarUrl: yup.string().nullable(),
});

const validationSchemaEdit = yup.object().shape({
  nome: yup
    .string("Insira o nome do cliente")
    .required("Preenchimento obrigatório"),
  email: yup
    .string("Preenchimento obrigatório")
    .required("Preenchimento obrigatório"),
  telefone: yup
    .string()
    .matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, "Telefone inválido")
    .typeError("Telefone inválido"),
  cpf: yup
    .string()
    .matches(
      /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
      "Insira um CPF no formato XXX.XXX.XXX-XX"
    )
    .nullable(),
  cnpj: yup
    .string()
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, {
      message: "CNPJ Inválido",
      excludeEmptyString: true,
    })
    .nullable(),

  cep: yup
    .string()
    .matches(/^[0-9]{5}-[0-9]{3}$/, "CEP Inválido")
    .nullable(),
  segmento_cliente: yup
    .number("ID Inválido")
    .required("Selecione um Segmento de Cliente"),
  avatarUrl: yup.string().nullable(),
  password: yup.string().nullable(),
  passwordConfirm: yup.string().when("password", {
    is: (password) => !!password,
    then: yup
      .string()
      .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
      .required("Preenchimento obrigatório quando a senha é fornecida"),
    otherwise: yup.string().nullable(),
  }),
});

const UsuariosClienteForm = () => {
  const initialValues = {
    nome: "",
    email: "",
    password: "",
    passwordConfirm: "",
    cpf: "",
    cnpj: "",
    bairro: "",
    logradouro: "",
    cidade: "",
    numero: "",
    complemento: "",
    uf: "",
    avatarUrl: "",
    telefone: "",
    tipo_pessoa: "fisica",
    tipo_telefone: "celular",
    user_type: 2,
    segmento_cliente: "",
  };
  const { id } = useParams();
  const [user, setUser] = useState(initialValues);
  const [previewFotoPerfil, setPreviewFotoPerfil] = useState(null);
  const [erroUploadFotoPerfil, setErroUploadFotoPerfil] = useState({
    status: false,
    mensagem: "",
  });
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [roles, setRoles] = useState([]);
  const [activitiesSelected, setActivitiesSelected] = useState(null);
  const [telefoneComMascara, setTelefoneComMascara] = useState("");
  const animatedComponents = makeAnimated();
  const Swal = useSwalWrapper();
  const navigate = useNavigate();
  const [formattedCPF, setFormattedCPF] = useState("");
  const [formattedCNPJ, setFormattedCNPJ] = useState("");
  const [formattedCEP, setFormatedCEP] = useState("");
  const [formattedTelefone, setFormatedTelefone] = useState("");
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [segmentoSelected, setSegmentoSelected] = useState(null);
  const [segmentosCliente, setSegmentosCliente] = useState([]);
  const ROLE_ID = Number(localStorage.getItem("role_id"));
  const USER_ID = Number(localStorage.getItem("cliente_id"));
  const [mascaraTelefone, setMascaraTelefone] = useState("(99) 99999-9999");

  const { theme } = useJumboTheme();
  const location = useLocation();
  const formikRef = useRef();

  const { hasPermission } = useContext(PermissionContext);

  if (!hasPermission("Clientes", "update")) {
    navigate("/app/clientes/usuarios");
  }

  const handleTelefoneChange = (tipo) => {
    if (tipo === "celular") {
      setMascaraTelefone("(99) 99999-9999");
    } else {
      setMascaraTelefone("(99) 9999-9999");
    }
    formikRef.current.setFieldValue("telefone", formattedTelefone);
  };

  const handleRedirectClienteNaoAutorizado = () => {
    if (ROLE_ID === 2 && Number(id) !== USER_ID) {
      navigate("/");
    }
  };

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getUsers = useCallback(async () => {
    try {
      ApiService.get(`/clientes/cliente/${id}`)
        .then((response) => {
          const { user, enderecos, cpf, cnpj, telefone, tipo_telefone } =
            response.data;
          const transformedUser = {
            password: "",
            email: user[0]?.user?.email,
            nome: user[0]?.user?.name,
            avatarUrl: user[0]?.user?.avatarUrl,
            telefone,
            cpf: response?.data?.cpf,
            cnpj: response?.data?.cnpj,
            tipo_pessoa: response?.data?.tipo_pessoa,
            tipo_telefone: response?.data?.tipo_telefone,
            bairro: enderecos[0]?.vizinhanca,
            logradouro: enderecos[0]?.rua,
            cidade: enderecos[0]?.cidade,
            numero: enderecos[0]?.numero,
            complemento: enderecos[0]?.complemento,
            cep: enderecos[0]?.cep,
            uf: enderecos[0]?.uf,
            segmento_cliente: response.data?.segmento_cliente?.id,
            user_id: Number(user[0]?.user?.id),
          };

          handleTelefoneChange(tipo_telefone);
          setUser(transformedUser);
          setTelefoneComMascara(telefone);
          setFormattedCPF(cpf);
          setFormattedCNPJ(cnpj);
          setFormatedCEP(enderecos[0]?.cep);
          setSegmentoSelected({
            id: response.data?.segmento_cliente?.id,
            descricao: response?.data.segmento_cliente?.descricao,
          });
          if (response.data.avatarUrl) {
            setPreviewFotoPerfil(
              `${process.env.REACT_APP_API_KEY}/public/uploads/${response.data.avatarUrl}`
            );
          }
          setActivitiesSelected(response.data.roles[0]?.role_id);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getRoles = useCallback(async () => {
    try {
      ApiService.get(`/roles`)
        .then((response) => {
          console.log(response.data);
          setRoles(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const optionsRoles = roles?.map((result) => {
    return {
      value: result.id,
      label: result.name,
    };
  });

  const modalAlert = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleRemoveFotoPerfil();
      }
    });
  };

  const modalAlertDeleteCliente = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o cliente?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteUser(id);
      }
    });
  };

  const deleteUser = useCallback(async (id) => {
    try {
      ApiService.delete(`/clientes/${id}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          navigate(`/app/listar-clientes`);
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handlePreviewFotoPerfil = async (event) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      if (
        selectedFile.type !== "image/jpeg" &&
        selectedFile.type !== "image/png"
      ) {
        setPreviewFotoPerfil(null);
        setErroUploadFotoPerfil({
          status: true,
          mensagem: "Formato de imagem inválido. Só é permitido .jpeg ou .png",
        });
        console.log("Tipo de imagem inválida", selectedFile.type);
        return;
      } else {
        setErroUploadFotoPerfil({
          status: false,
          mensagem: "",
        });

        reader.onloadend = () => {
          setPreviewFotoPerfil(reader.result);
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const handleUploadImagemPerfil = async (event) => {
    const fotoPerfil = event.target.files[0];
    const formData = new FormData();
    formData.append("foto", fotoPerfil);
    try {
      await ApiService.put(
        `users/upload/foto/perfil/${user?.user_id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ).then((response) => {
        if (response.status === 200) {
          toast("success", "Foto de perfil atualizada!");
          setUser((prevUser) => ({
            ...prevUser,
            avatarUrl: response.data.avatarUrl,
          }));
          localStorage.setItem("avatarUrl", response.data.avatarUrl);
        }
      });
    } catch (error) {
      console.error("Erro ao fazer upload da imagem: ", error);
    }
  };

  const handleRemoveFotoPerfil = async () => {
    setPreviewFotoPerfil(null);
    setErroUploadFotoPerfil({
      status: false,
      mensagem: "",
    });
    try {
      await ApiService.put(`/users/foto/remover/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Foto removida com sucesso!");
          setPreviewFotoPerfil(null);
          setUser((prevUser) => ({
            ...prevUser,
            avatarUrl: null,
          }));
        }
      });
    } catch (error) {
      console.log(error);
      toast("error", "Erro ao remover a imagem de perfil");
    }
  };

  const getEstados = useCallback(async () => {
    try {
      await axios
        .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados")
        .then((response) => {
          if (response.status === 200) {
            setEstados(response.data);
          }
        });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const getMunicipios = useCallback(
    async (sigla) => {
      await axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${sigla}/municipios
  `
        )
        .then((response) => {
          if (response.status === 200) {
            setMunicipios(response.data);
          }
        });
    },
    [estados]
  );

  const handleChangeCEP = async (cep) => {
    formikRef.current.setFieldValue("cep", cep);
    if (cep.length === 9) {
      try {
        const cepConsultado = await consultarCEP(cep);
        if (cepConsultado) {
          formikRef.current.setFieldValue(
            "uf",
            cepConsultado.dados.stateShortname
          );
          await getMunicipios(cepConsultado.dados.stateShortname);
          formikRef.current.setFieldValue("cidade", cepConsultado.dados.city);
          formikRef.current.setFieldValue(
            "logradouro",
            cepConsultado.dados.street
          );
          formikRef.current.setFieldValue(
            "bairro",
            cepConsultado.dados.district
          );
        }
        setFormatedCEP(
          String(cep)
            .slice(0, 5)
            .concat(`-${String(cep).slice(5, 9)}`)
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    let allValuesEdit = {
      ...values,
      user_type: 2,
    };
    delete allValuesEdit.servicoId;
    delete allValuesEdit.dados_cliente;
    allValuesEdit.endereco = {
      uf: allValuesEdit.uf,
      cidade: allValuesEdit.cidade,
      numero: allValuesEdit.numero,
      vizinhanca: allValuesEdit.bairro,
      complemento: allValuesEdit.complemento,
      rua: allValuesEdit.logradouro,
      cep: allValuesEdit.cep,
    };

    delete allValuesEdit.uf;
    delete allValuesEdit.cidade;
    delete allValuesEdit.numero;
    delete allValuesEdit.vizinhanca;
    delete allValuesEdit.complemento;
    delete allValuesEdit.logradouro;
    delete allValuesEdit.cep;
    delete allValuesEdit.bairro;
    delete allValuesEdit.passwordConfirm;
    delete allValuesEdit.avatarUrl;
    if (id) {
      ApiService.put(`/clientes/${id}`, allValuesEdit)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Atualizado com sucesso");
            resetForm();
            navigate("/app/listar-clientes");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response.data) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } else {
      await ApiService.post("/clientes", allValuesEdit)
        .then((response) => {
          if (response.status === 201) {
            toast("success", "Criado com sucesso");
            resetForm();
            navigate("/app/listar-clientes");
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          toast("error", message);

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    }

    setSubmitting(false);
  };

  const getSegmentosCliente = useCallback(async () => {
    try {
      const response = await ApiService.get("/segmentos/all");
      setSegmentosCliente(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleCreateSegmentoCliente = useCallback(
    async (descricao) => {
      try {
        const response = await ApiService.post("/segmentos/", { descricao });
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          const novoSegmento = {
            id: response.data.id,
            descricao: response.data.descricao,
          };
          setSegmentosCliente([...segmentosCliente, novoSegmento]);
          setSegmentoSelected(novoSegmento);
          return novoSegmento;
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    },
    [segmentosCliente]
  );

  useEffect(() => {
    setErroUploadFotoPerfil(erroUploadFotoPerfil);
  }, [erroUploadFotoPerfil]);

  useEffect(() => {
    getRoles();
    getSegmentosCliente();
    getEstados();
    if (id) getUsers();
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [id]);

  const optionsSegmentos = segmentosCliente?.map((segmento) => {
    return {
      id: segmento.id,
      descricao: segmento.descricao,
    };
  });

  useLayoutEffect(() => {
    handleRedirectClienteNaoAutorizado();
  }, []);

  return (
    <>
      {(ROLE_ID === 1 || (ROLE_ID === 2 && USER_ID === Number(id))) && (
        <JumboContentLayout
          header={
            <HeaderBreadcrumbs
              id={id}
              title={"Clientes"}
              subtitle={
                isDeleteMode
                  ? "Deletar cliente"
                  : id
                  ? "Editar"
                  : "Cadastro de Clientes"
              }
              titleUrl={"/app/listar-clientes"}
            />
          }
          layoutOptions={layoutOptions}
        >
          {lg && (
            <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
          )}
          {isDeleteMode && (
            <Stack width={"100%"} p={1}>
              <Alert severity="error">
                Você está prestes a excluir o cliente abaixo:
              </Alert>
            </Stack>
          )}
          <Paper sx={{ p: "15px" }}>
            <Formik
              innerRef={formikRef}
              initialValues={user}
              validationSchema={id ? validationSchemaEdit : validationSchema}
              enableReinitialize
              validateOnChange
              validateOnBlur
              onSubmit={handleSubmit}
            >
              {({
                values,
                isSubmitting,
                setFieldValue,
                handleChange,
                errors,
              }) => (
                <Form style={{ width: "100%" }} noValidate autoComplete="off">
                  <Grid
                    container
                    spacing={2}
                    padding={2}
                    alignContent={"center"}
                    justifyContent={"center"}
                  >
                    {id && (
                      <Grid item lg={4} alignContent={"center"}>
                        <form>
                          <Box
                            width={"100%"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                          >
                            <Typography fontSize={"16px"} style={{ margin: 2 }}>
                              Foto de perfil
                            </Typography>
                            <Avatar
                              alt="Foto do perfil"
                              src={
                                user.avatarUrl
                                  ? `${process.env.REACT_APP_API_KEY}/public/uploads/${user.avatarUrl}`
                                  : previewFotoPerfil
                              }
                              sx={{
                                width: 140,
                                height: 140,
                                marginTop: 2,
                                bgcolor: "success",
                              }}
                            >
                              {user.name
                                ? user.name.charAt(0).toUpperCase()
                                : "P"}
                            </Avatar>
                          </Box>
                          <Box
                            width={"100%"}
                            display={"flex"}
                            marginTop={2}
                            justifyContent={"center"}
                            gap={1}
                          >
                            <form></form>
                            <IconButton
                              color="info"
                              aria-label="upload picture"
                              component="label"
                              disabled={isDeleteMode}
                            >
                              <input
                                name="foto"
                                hidden
                                accept="image/png, image/jpg, image/jpeg"
                                type="file"
                                onChange={(event) => {
                                  handlePreviewFotoPerfil(event);
                                  handleUploadImagemPerfil(event);
                                }}
                              />
                              <PhotoCamera />
                            </IconButton>
                            {previewFotoPerfil && (
                              <>
                                <IconButton onClick={modalAlert}>
                                  <Delete color="error" />
                                </IconButton>
                              </>
                            )}
                          </Box>
                          {erroUploadFotoPerfil.status && (
                            <Box sx={{ textAlign: "center" }}>
                              <Typography color="error">
                                {erroUploadFotoPerfil.mensagem}
                              </Typography>
                            </Box>
                          )}
                        </form>
                      </Grid>
                    )}

                    <Grid item lg={8} marginTop={5}>
                      <Grid container alignContent={"center"} spacing={2}>
                        <Grid item lg={3} xs={12}>
                          <TextField
                            fullWidth
                            name="nome"
                            label="Nome"
                            disabled={isDeleteMode}
                            required
                            onChange={handleChange}
                            value={updateInputValue(user, values, "nome")}
                            sx={{ background: "#ffffff" }}
                          />
                        </Grid>
                        <Grid item lg={5} xs={12}>
                          <TextField
                            fullWidth
                            name="email"
                            label="Email"
                            disabled={isDeleteMode}
                            required
                            onChange={handleChange}
                            value={updateInputValue(user, values, "email")}
                            sx={{ background: "#ffffff" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{fontSize: 10}}>@clienteambiental.com.br</InputAdornment>
                              ),
                            }}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          <FormGroup dir="row">
                            <FormLabel id="tipo-telefone-label">
                              Tipo de telefone
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="tipo-pessoa-label"
                              value={values.tipo_telefone}
                              name="tipo_telefone"
                              disabled={isDeleteMode}
                              onChange={(e) => {
                                const tipo = e.target.value;
                                console.log(`Tipo: ${tipo}`);
                                handleTelefoneChange(tipo);
                                setFieldValue("tipo_telefone", tipo);
                              }}
                              row
                            >
                              <FormControlLabel
                                value="celular"
                                disabled={isDeleteMode}
                                control={<Radio color="success" />}
                                label="Celular"
                              />
                              <FormControlLabel
                                value="fixo"
                                disabled={isDeleteMode}
                                control={<Radio color="success" />}
                                label="Telefone fixo"
                              />
                            </RadioGroup>
                          </FormGroup>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                          <ReactInputMask
                            mask={mascaraTelefone}
                            maskChar=""
                            disabled={isDeleteMode}
                            value={values.telefone}
                            onChange={(event) => {
                              const telefone = event.target.value;
                              setFormatedTelefone(telefone);
                              setFieldValue("telefone", telefone);
                            }}
                          >
                            {(inputProps) => (
                              <TextField
                                fullWidth
                                name="telefone"
                                label="Telefone"
                                sx={{ background: "#ffffff" }}
                                {...inputProps}
                              />
                            )}
                          </ReactInputMask>
                          <ErrorMessage
                            component={"div"}
                            style={{ color: "red" }}
                            name="telefone"
                          />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          <TextField
                            fullWidth
                            name="password"
                            type="password"
                            label="Senha"
                            required
                            disabled={isDeleteMode}
                            onChange={handleChange}
                            value={updateInputValue(user, values, "password")}
                            sx={{ background: "#ffffff" }}
                          />
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          <TextField
                            fullWidth
                            type="password"
                            name="passwordConfirm"
                            label="Confirmação de senha"
                            required
                            onChange={handleChange}
                            disabled={isDeleteMode}
                            value={updateInputValue(
                              user,
                              values,
                              "passwordConfirm"
                            )}
                            sx={{ background: "#ffffff" }}
                          />
                          <ErrorMessage
                            name="passwordConfirm"
                            component={"div"}
                            style={{ color: "red" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={8}>
                      <Divider />
                    </Grid>
                    <Grid item lg={12} marginTop={1}>
                      <Grid
                        container
                        alignContent={"center"}
                        justifyContent={"center"}
                        spacing={1}
                        my={1}
                      >
                        <Grid item lg={4} xs={12}>
                          <FormGroup>
                            <FormLabel id="tipo-pessoa-label">
                              Tipo pessoa
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="tipo-pessoa-label"
                              value={values.tipo_pessoa}
                              name="tipo_pessoa"
                              disabled={isDeleteMode}
                              onChange={handleChange}
                              row
                            >
                              <FormControlLabel
                                value="fisica"
                                disabled={isDeleteMode}
                                control={<Radio color="success" />}
                                label="Pessoa física"
                              />
                              <FormControlLabel
                                value="juridica"
                                disabled={isDeleteMode}
                                control={<Radio color="success" />}
                                label="Pessoa jurídica"
                              />
                            </RadioGroup>
                          </FormGroup>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                          {values.tipo_pessoa === "fisica" ? (
                            <>
                              <ReactInputMask
                                mask="999.999.999-99"
                                maskChar=""
                                disabled={isDeleteMode}
                                value={values.cpf}
                                onChange={(e) => {
                                  const cpf = e.target.value;
                                  setFormattedCPF(cpf);
                                  setFieldValue("cpf", cpf);
                                }}
                              >
                                {(inputProps) => (
                                  <TextField
                                    fullWidth
                                    name="cpf"
                                    label="CPF"
                                    sx={{ background: "#ffffff" }}
                                    {...inputProps}
                                  />
                                )}
                              </ReactInputMask>
                              <ErrorMessage
                                component={"div"}
                                name="cpf"
                                style={{ color: "red" }}
                              />
                            </>
                          ) : (
                            <>
                              <ReactInputMask
                                mask="99.999.999/9999-99"
                                maskChar=""
                                value={formattedCNPJ}
                                disabled={isDeleteMode}
                                onChange={(e) => {
                                  const cnpj = e.target.value;
                                  setFormattedCNPJ(cnpj);
                                  setFieldValue("cnpj", cnpj);
                                }}
                              >
                                {(inputProps) => (
                                  <TextField
                                    fullWidth
                                    name="cnpj"
                                    label="CNPJ"
                                    sx={{ background: "#ffffff" }}
                                    {...inputProps}
                                  />
                                )}
                              </ReactInputMask>
                              {errors.cnpj && (
                                <div style={{ color: "red" }}>
                                  {errors.cnpj}
                                </div>
                              )}
                            </>
                          )}

                          <Divider />
                        </Grid>
                        <Grid item lg={8} xs={12} mb={5}>
                          <div style={{ position: "relative" }}>
                            <CreatableSelect
                              isClearable
                              styles={customStyles}
                              name={`segmento_cliente`}
                              isDisabled={isDeleteMode}
                              onChange={(option) => {
                                if (option) {
                                  setSegmentoSelected({
                                    id: option.id,
                                    descricao: option.descricao,
                                  });
                                  setFieldValue(
                                    `segmento_cliente`,
                                    option ? option.id : ""
                                  );
                                }
                              }}
                              onCreateOption={async (value) => {
                                const { id, descricao } =
                                  await handleCreateSegmentoCliente(value);
                                setSegmentoSelected({ id, descricao });
                                setFieldValue(`segmento_cliente_id`, id);
                              }}
                              options={optionsSegmentos}
                              getOptionLabel={(option) =>
                                option.__isNew__
                                  ? option.label
                                  : option.descricao
                              }
                              value={segmentoSelected}
                              placeholder={"Selecione o segmento do cliente"}
                              menuPosition="top"
                              menuPlacement="auto"
                              maxMenuHeight={115}
                            />
                            <ErrorMessage
                              name="segmento_cliente"
                              component={"div"}
                              style={{ color: "red" }}
                            />
                          </div>
                          <Divider />
                        </Grid>
                        <Grid item lg={8} xs={12}>
                          <Typography component={"h4"} variant="h4" my={2}>
                            Informações complementares
                          </Typography>
                        </Grid>
                        <Grid item lg={8}>
                          <Grid
                            container
                            justifyContent={"flex-start"}
                            spacing={2}
                          >
                            <Grid item lg={4} xs={12}>
                              <ReactInputMask
                                mask="99999-999"
                                value={formattedCEP}
                                disabled={isDeleteMode}
                                onChange={(e) => {
                                  handleChangeCEP(e.target.value);
                                  setFormatedCEP(e.target.value);
                                  setFieldValue("cep", e.target.value);
                                }}
                              >
                                {(inputProps) => (
                                  <TextField
                                    fullWidth
                                    name="cep"
                                    label="CEP"
                                    sx={{ background: "#ffffff" }}
                                    {...inputProps}
                                  />
                                )}
                              </ReactInputMask>
                              {errors.cep && (
                                <div style={{ color: "red" }}>{errors.cep}</div>
                              )}
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <TextField
                                fullWidth
                                disabled={isDeleteMode}
                                name="logradouro"
                                label="Logradouro"
                                value={values.logradouro}
                                onChange={handleChange}
                                sx={{ background: "#ffffff" }}
                              />
                            </Grid>
                            <Grid item lg={2} xs={12}>
                              <TextField
                                fullWidth
                                disabled={isDeleteMode}
                                name="numero"
                                label="Número"
                                value={values.numero}
                                onChange={handleChange}
                                sx={{ background: "#ffffff" }}
                              />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <TextField
                                fullWidth
                                disabled={isDeleteMode}
                                name="complemento"
                                label="Complemento"
                                value={values.complemento}
                                onChange={handleChange}
                                sx={{ background: "#ffffff" }}
                              />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <TextField
                                fullWidth
                                disabled={isDeleteMode}
                                name="bairro"
                                label="Bairro"
                                value={values.bairro}
                                onChange={handleChange}
                                sx={{ background: "#ffffff" }}
                              />
                            </Grid>
                            <Grid item lg={4} xs={12}>
                              <TextField
                                disabled={isDeleteMode}
                                fullWidth
                                name="cidade"
                                label="Cidade"
                                value={values.cidade}
                                onChange={handleChange}
                                sx={{ background: "#ffffff" }}
                              />
                            </Grid>
                            <Grid item lg={3} xs={12}>
                              <FormControl fullWidth>
                                <InputLabel id="select-estado-label">
                                  UF
                                </InputLabel>
                                <Select
                                  labelId="select-estado-label"
                                  id="select-estado"
                                  value={values.uf}
                                  label={"UF"}
                                  name={"uf"}
                                  onChange={handleChange}
                                  disabled={isDeleteMode}
                                  fullWidth
                                >
                                  {Object.entries(ESTADOS).map(
                                    ([sigla, estado]) => (
                                      <MenuItem value={sigla}>
                                        {estado}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    marginTop={3}
                  >
                    {isDeleteMode ? (
                      <Stack direction="row" gap={2} display={"flex"}>
                        <Box>
                          <Button
                            color="error"
                            variant="contained"
                            onClick={() => {
                              modalAlertDeleteCliente(id);
                            }}
                          >
                            Excluir cliente
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              navigate(`/app/listar-clientes`);
                            }}
                          >
                            Cancelar
                          </Button>
                        </Box>
                      </Stack>
                    ) : (
                      <LoadingButton
                        loading={isSubmitting}
                        type="submit"
                        style={{ margin: "auto", display: "block" }}
                        variant="contained"
                        color="primary"
                      >
                        {id ? "Atualizar" : "Criar"}
                      </LoadingButton>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          </Paper>
        </JumboContentLayout>
      )}
    </>
  );
};

export default UsuariosClienteForm;
