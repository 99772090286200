import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import TopicIcon from "@mui/icons-material/Topic";
import TaskIcon from "@mui/icons-material/Task";
import PaidIcon from "@mui/icons-material/Paid";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalDetalhesUnidade from "app/components/ModalDetalhesUnidade/ModalDetalhesUnidade";
import ModalAdicionarDocumentosUnidade from "app/components/ModalAdicionarDocumentosUnidade/ModalAdicionarDocumentosUnidade";
import DescriptionIcon from "@mui/icons-material/Description";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const UnidadeDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const userId = localStorage.getItem("id");
  const { theme } = useJumboTheme();
  const [unidade, setUnidade] = useState();
  const [isDadosCarregados, setIsDadosCarregados] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [abaSelecionada, setAbaSelecionada] = useState("gestao");
  const [submenuAberto, setSubmenuAberto] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalDetalhesAbertos, setIsModalDetalhesAbertos] = useState(false);
  const [accordionId, setAccordionId] = useState(null);
  const accordionRef = useRef(null);
  const [isModalEdicaoDocumentoAberto, setIsModalEdicaoDocumentoAberto] =
    useState(false);
  const [
    isModalAdicionarDocumentosAberto,
    setIsModalAdicionarDocumentosAberto,
  ] = useState(false);
  const navigate = useNavigate();

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );
  const Swal = useSwalWrapper();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const modalAlert = (id, acao, item) => {
    Swal.fire({
      title: `Tem certeza que deseja ${
        acao === "deletar" ? "excluir" : "arquivar"
      }?`,
      text: `Não será póssível reverter a ação!`,
      icon: `warning`,
      showCancelButton: true,
      confirmButtonText: `Sim!`,
      cancelButtonText: `Não!`,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (acao === "deletar" && !item) {
          deleteUnidade(id);
        }
        if (acao === "arquivar" && !item) {
          arquivarUnidade(id);
        }
        if (acao === "deletar" && item === "documento") {
          handleDeleteDocumentoUnidade(id);
        }
        if (acao === "deletar" && item === "condicionante") {
          handleDeleteCondicionante(id);
        }
      }
    });
  };

  const handleDeleteDocumentoUnidade = async (documentoId) => {
    try {
      await ApiService.delete(`/documentocomplementar/${documentoId}`).then(
        (response) => {
          if (response.status === 200) {
            toast("success", "Documento excluído!");
            getUnidade();
          }
        }
      );
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const deleteUnidade = async (id) => {
    try {
      await ApiService.delete(`/unidade/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidade excluída!");
          if (isDeleteMode) {
            navigate(`/app/cliente/unidades/${location?.state?.clienteId}`);
          } else {
            navigate("/app/unidades");
          }
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const arquivarUnidade = async (id) => {
    try {
      await ApiService.put(`/unidade/arquivar/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Unidade arquivada!");
          navigate("/app/unidades");
        }
      });
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleChangeAba = (nomeAba) => {
    setAbaSelecionada(nomeAba);
  };

  const handleClickSubmenu = () => {
    setSubmenuAberto(!submenuAberto);
  };

  const handleCloseSubmenu = () => {
    setSubmenuAberto(false);
  };

  const handleOpenModalDetalhes = () => {
    setIsModalDetalhesAbertos(!isModalDetalhesAbertos);
  };

  const handleCloseModalAdicionarDocumentos = () => {
    setIsModalAdicionarDocumentosAberto(!isModalAdicionarDocumentosAberto);
  };

  const handleDeleteCondicionante = async (id) => {
    try {
      await ApiService.delete(`/condicionante/${id}`).then((response) => {
        if (response.status === 200) {
          toast("success", "Condicionante deletada!");
          getUnidade();
        }
      });
    } catch (error) {
      console.error(error);
      toast("error", "Erro ao deletar a condicionante!");
    }
  };

  const getUnidade = async () => {
    try {
      await ApiService.get(`/unidade/${id}`).then(async (response) => {
        if (response.status === 200) {
          setUnidade({
            ...response.data,
            alvaraLicencas: response.data.alvaraLicenca,
            alvaraLicencasComplementar: response.data.alvaraLicencaComplementar,
          });
        }
      });
    } catch (error) {}
  };

  useEffect(async () => {
    setIsDadosCarregados(false);
    const fetchData = async () => {
      await getUnidade();
    };
    await fetchData();
    setIsDadosCarregados(true);
    if (location && String(location.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [id]);

  useEffect(() => {
    if (
      location.state &&
      location.state.condicionanteId &&
      location.state.aba
    ) {
      setAccordionId(location.state.condicionanteId);
      setAbaSelecionada(location.state.aba);
    }
  }, [location.state]);

  useEffect(() => {
    if (accordionRef.current) {
      accordionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [accordionId]);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      <Stack
        width={"100%"}
        display={"flex"}
        direction={"row"}
        gap={1}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack width={"50%"}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link to={"/app/listar-clientes"} color="success" style={linkStyle}>
              Clientes
            </Link>
            {location.state && (
              <Link
                to={`/app/editar-cliente/${location?.state?.clienteId}`}
                color="success"
                style={linkStyle}
              >
                {location.state?.nomeCliente}
              </Link>
            )}
            <Link
              to={`/app/cliente/unidades/${location?.state?.clienteId}`}
              color="primary"
              style={linkStyle}
            >
              Unidades
            </Link>
            {location.state && (
              <Link
                to={`/app/cliente/${location?.state?.clienteId}/unidades/editar/${location?.state?.unidadeId ?? unidade?.id}`}
                color="success"
                style={linkStyle}
              >
                {location.state?.nomeUnidade ?? unidade?.nomeUnidade}
              </Link>
            )}
          </Breadcrumbs>
        </Stack>
      </Stack>
      {lg && (
        <Stack
          spacing={2}
          direction={"row"}
          sx={{ mb: 1, mt: -2, justifyContent: "space-between" }}
        ></Stack>
      )}
      {isDeleteMode && (
        <Stack width={"100%"} p={1}>
          <Alert severity="error">
            Você está prestes a excluir a unidade abaixo:
          </Alert>
        </Stack>
      )}
      {isDadosCarregados && (
        <>
          <Paper elevation={1} sx={{ p: 3, background: "white" }}>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Box>
                <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
                  {unidade.nomeUnidade}
                </Typography>
                <Typography style={{ fontSize: "16px", color: "#A5A5A5" }}>
                  {unidade.razaoSocial} - CNPJ: {unidade.cnpj}
                </Typography>
                <Typography
                  fontSize={"18px"}
                  style={{ fontWeight: "500", marginTop: 10, marginBottom: 10 }}
                >
                  <Chip
                    color="success"
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {String(unidade.status).toUpperCase()}
                      </Box>
                    }
                    my={2}
                  />
                </Typography>
                <Divider />
              </Box>
              <Box display={"flex"} gap={2}>
                <Button
                  variant="contained"
                  color="info"
                  style={{ height: 30 }}
                  onClick={handleOpenModalDetalhes}
                >
                  <AddIcon /> Informações
                </Button>
                <ModalDetalhesUnidade
                  aberto={isModalDetalhesAbertos}
                  handleClose={handleOpenModalDetalhes}
                  unidade={unidade}
                />
                <Button
                  variant="outlined"
                  color="info"
                  style={{ height: 30 }}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    handleClickSubmenu();
                  }}
                >
                  <ListIcon />
                </Button>
                <Popover
                  open={submenuAberto}
                  anchorEl={anchorEl}
                  onClose={handleCloseSubmenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <List>
                    <ListItem
                      onClick={() => {
                        navigate(`/app/editar-unidade/${id}`);
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          background: "#f9f9f9",
                        },
                      }}
                    >
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText primary="Editar" />
                    </ListItem>
                    <Divider />
                    <ListItem
                      onClick={() => {
                        modalAlert(id, "arquivar");
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          background: "#f9f9f9",
                        },
                      }}
                    >
                      <ListItemIcon>
                        <ArchiveIcon />
                      </ListItemIcon>
                      <ListItemText primary="Arquivar" />
                    </ListItem>
                    <Divider />
                    <ListItem
                      onClick={() => {
                        modalAlert(id, "deletar");
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          background: "#f9f9f9",
                        },
                      }}
                    >
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText primary="Excluir" />
                    </ListItem>
                  </List>
                </Popover>
              </Box>
            </Box>
            <Box my={1}>
              <Box display={"flex"} gap={1}>
                <Typography sx={{ fontWeight: 500 }}>
                  Grupo de Restrição:
                </Typography>
              </Box>
              <Box display={"flex"} gap={1}>
                <Typography sx={{ fontWeight: 500 }}>Endereço:</Typography>
                {unidade.logradouro === "" ? "---" : unidade.logradouro},{" "}
                {unidade.bairro === "" ? "---" : unidade.bairro},{" "}
                {unidade.cidade === "" ? "---" : unidade.cidade}/
                {unidade.estado === "" ? "---" : unidade.estado}, CEP:{" "}
                {unidade.cep === "" ? "---" : unidade.cep}
              </Box>
            </Box>
          </Paper>
          <Paper elevation={1} sx={{ p: 3, mt: 3, background: "white" }}>
            <Grid container>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  p: 1,
                  cursor: "pointer",
                  borderBottom:
                    abaSelecionada === "gestao" && "2px solid #2EB5C9",
                  background: abaSelecionada === "gestao" && "#f9f9f9",
                }}
                onClick={() => handleChangeAba("gestao")}
              >
                <Typography>
                  <DescriptionIcon color="info" />
                </Typography>{" "}
                <Typography variant={"h4"}>Gestão</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  p: 1,
                  cursor: "pointer",
                  borderBottom:
                    abaSelecionada === "docComplementares" &&
                    "2px solid #2EB5C9",
                  background:
                    abaSelecionada === "docComplementares" && "#f9f9f9",
                }}
                onClick={() => handleChangeAba("docComplementares")}
              >
                <Typography>
                  <TopicIcon color="info" />
                </Typography>{" "}
                <Typography variant={"h4"}>Doc. complementares</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  p: 1,
                  cursor: "pointer",
                  borderBottom:
                    abaSelecionada === "condicionantes" && "2px solid #2EB5C9",
                  background: abaSelecionada === "condicionantes" && "#f9f9f9",
                }}
                onClick={() => handleChangeAba("condicionantes")}
              >
                <Typography>
                  <TaskIcon color="info" />
                </Typography>{" "}
                <Typography variant={"h4"}>Condicionantes</Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  p: 1,
                  cursor: "pointer",
                  borderBottom:
                    abaSelecionada === "controleCustos" && "2px solid #2EB5C9",
                  background: abaSelecionada === "controleCustos" && "#f9f9f9",
                }}
                onClick={() => handleChangeAba("controleCustos")}
              >
                <Typography>
                  <PaidIcon color="info" />
                </Typography>{" "}
                <Typography variant={"h4"}>Controle de Custos</Typography>
              </Grid>
            </Grid>
          </Paper>
          <Box
            my={1}
            sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
          >
            <Button
              size="medium"
              variant="contained"
              color="info"
              onClick={handleCloseModalAdicionarDocumentos}
              sx={{ maxWidth: 400 }}
            >
              <AddIcon /> Alvarás e documentos
            </Button>
          </Box>
          <ModalAdicionarDocumentosUnidade
            aberto={isModalAdicionarDocumentosAberto}
            atualizarUnidade={getUnidade}
            handleClose={handleCloseModalAdicionarDocumentos}
            nomeUnidade={unidade.nomeUnidade}
            unidadeId={unidade.id}
            userId={userId}
          />
          <Paper
            elevation={1}
            sx={{
              p: 3,
              my: 1,
              background: "white",
              maxHeight: "300px",
              overflowY: "scroll",
            }}
          >
            {abaSelecionada === "gestao" && <>Em ajuste</>}
            {abaSelecionada === "docComplementares" && <>Em ajuste</>}
            {abaSelecionada === "condicionantes" && <>Em ajuste</>}
          </Paper>
          {isDeleteMode && (
            <Paper
              width={"100%"}
              my={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              }}
            >
              <Stack direction="row" gap={1}>
                <Box>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      modalAlert(id, "deletar");
                    }}
                  >
                    Excluir imóvel
                  </Button>
                </Box>
                <Box>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      navigate(
                        `/app/cliente/unidades/${location?.state?.clienteId}`
                      );
                    }}
                  >
                    Cancelar
                  </Button>
                </Box>
              </Stack>
            </Paper>
          )}
        </>
      )}
    </JumboContentLayout>
  );
};

export default UnidadeDetails;
