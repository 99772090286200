
import {
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useCallback, useContext } from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MUIDataTable from "mui-datatables";
import MetricCard from "../MetricCard/MetricCard";

const RegimeCompetencia = ({ data, indicators, setSortByFluxo, setSortOrderFluxo, handleSearchFluxo }) => {
  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "codigo",
      label: "Código",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "descricao",
      label: "Descrição",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "entrada",
      label: "Total de Entrada",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "saida",
      label: "Total saída",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(value);
        },
      },
    },
    {
      name: "tipo",
      label: "Tipo",
      options: {
        filter: true,
        sort: true,
        display: false
      },
    },
    {
      name: "entrada",
      label: "Valor",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const isInput = tableMeta.rowData[5] == 'Entrada';
          
          let monthValue = 0;
          if(isInput){
            monthValue = tableMeta.rowData[3]
          } else {
            monthValue = tableMeta.rowData[4]
          }
          
          const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          });
    
          return formatter.format(monthValue);
        },
      },
    },
    {
      name: "porcentagem_total_tipo",
      label: "100%",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue, rowData) => {
          const totalValue = value ?? 0;
          return totalValue.toFixed(2)+'%';
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    filter: false,
    selectableRows: "none",
    searchable: true,
    serverSide: true,
    selectToolbarPlacement: "above",
    pagination: false,

    onTableChange: (action, tableState) => {
      // console.log(action);
      // console.log('tableState.sortOrder.name 2', tableState.sortOrder.name);
      // console.log('tableState.sortOrder.direction 2', tableState.sortOrder.direction);
      switch (action) {
        case "sort":
          setSortByFluxo(tableState.sortOrder.name);
          setSortOrderFluxo(tableState.sortOrder.direction);
          break;
        case "search":
          handleSearchFluxo(tableState.searchText);
          break;
        default:
          // console.log("action not handled.");
      }
    },
    textLabels: {
      body: {
        noMatch: "Nenhum resultado encontrado",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
    },
    setRowProps: row => { 
      var bgColor = "#fff";
      const length = row[1].length;

      switch (length) {
        case 1:
          bgColor = "#d1d1d1"
          break;
        case 2:
          bgColor = "#e8e8e8"
          break;
        default:
          bgColor = "#fff"
      }

      if(row[1] == '30'){
        bgColor = "#d9d9d9"
      }

      return {
        style: { background: bgColor }
      };
    }
  };

    return (
        <Accordion defaultExpanded disableGutters sx={{ marginBottom: '15px' }}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography>Regime de Competência</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Resultado líquido s/ vendas"} value={indicators.resultadoLiquidoSemVendas} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Margem líquida"} value={indicators.margemLiquida} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Margem de contribuição"} value={indicators.margemContribuicao} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"MC%"} value={indicators.margemContribuicaoPorcentagem} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Representatividade custo fixo"} value={indicators.representatividadeCustoFixo} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio"} value={indicators.pontoEquilibrio} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio econômico 15%"} value={indicators.pontoEquilibrio15} />
              </Grid>
              <Grid item xs={12} md={6} lg={3} >
                <MetricCard title={"Ponto de equilíbrio econômico 20%"} value={indicators.pontoEquilibrio20} />
              </Grid>
            </Grid>

            <MUIDataTable
              title={""}
              data={data}
              columns={columns}
              options={options}
            />
          </AccordionDetails>
        </Accordion>
    )
}

export default RegimeCompetencia;
