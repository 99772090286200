import ApiService from "app/services/config";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import moment from "moment";
import mapeamentoNomeFiltros from "../mapeamentoNomeFiltros";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const RESPONSAVEIS = {
  ambiental: "Ambiental",
  cliente: "Cliente",
  orgao: "Órgão",
  protocolado: "Protocolado",
};

const gerarResumoCompletoPDF = async (filtros, roleId, userId) => {
  const obterUrlImagem = async (path) => {
    const blogImage = await fetch(process.env.REACT_APP_ROOT_URL + path).then(
      (data) => data.blob()
    );
    let dataUrl = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blogImage);
    });
    return dataUrl;
  };

  const getColaboradores = async () => {
    try {
      let url = "/users/colaboradores?filtroRoles=financeiro_admin";
      const response = await ApiService.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const colaboradores = await getColaboradores();

  const getSetores = async () => {
    try {
      let url = "/setores";
      const response = await ApiService.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const setores = await getSetores();

  const getStatusServico = async () => {
    try {
      const response = await ApiService.get("/statusservico");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const statusServicos = await getStatusServico();

  const imagemIcone = await obterUrlImagem("/images/pdf/icone.png");
  const imagemTexto = await obterUrlImagem("/images/pdf/nome.png");

  const getProcessosAdministrativos = async () => {
    try {
      let filtrosPesquisa = {};
      let url = `/processos-contrato/pdf`;

      if (filtros.depende.length > 0) {
        filtrosPesquisa.depende = filtros.depende;
      }

      if (filtros.colaboradoresServico.length > 0) {
        filtrosPesquisa.colaboradores = filtros.colaboradoresServico.map(
          (colaborador) => colaborador.id
        );
      }

      if (filtros.setores.length > 0) {
        filtrosPesquisa.setores = filtros.setores.map((setor) => setor.id);
      }

      if (filtros.situacao) {
        filtrosPesquisa.situacao = filtros.situacao.id;
      }

      if (filtros.data_inicio) {
        filtrosPesquisa.data_inicio = filtros.data_inicio;
      }

      if (filtros.data_final) {
        filtrosPesquisa.data_final = filtros.data_final;
      }

      if (filtros.status) {
        filtrosPesquisa.status = filtros.status;
      }

      if (
        filtros.periodo !== "selecionar" &&
        filtros.data_inicio !== null &&
        filtros.data_final !== null
      ) {
        filtrosPesquisa.periodo = filtros.periodo;
        filtrosPesquisa.data_inicio = filtros.data_inicio;
        filtrosPesquisa.data_final = filtros.data_final;
      }

      if(filtros.searchTerm && filtros.searchTerm !== "") {
        filtrosPesquisa.searchTerm = filtros.searchTerm;
      }

      filtrosPesquisa.roleId = roleId;
      filtrosPesquisa.userId = userId;

      const response = await ApiService.post(url, filtrosPesquisa);
      return response.data.processos;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const processosAdministrativos = await getProcessosAdministrativos();

  const documentDefinition = {
    content: [
      {
        columns: [
          {
            image: imagemIcone,
            alignment: "left",
            width: 55,
          },
          {
            text: "",
            width: "*",
          },
          {
            image: imagemTexto,
            alignment: "right",
            width: 150,
          },
        ],
        margin: [0, 0, 0, 10],
      },
      {
        text: "\n",
      },
      {
        text: "\n",
      },
      {
        text: "Relatório completo de processos administrativos",
        fontSize: 14,
        bold: true,
      },
      {
        text: "\n",
      },
    ],
    pageOrientation: "landscape",
    fontSize: 10,
    styles: {
      header: {
        bold: true,
        fontSize: 10,
        margin: [0, 10, 0, 5],
        color: "white",
        fillColor: "#024900",
        alignment: "left",
        padding: [6, 4, 0, 4],
      },
      subheader: {
        bold: true,
        fontSize: 10,
        margin: [0, 5, 0, 5],
        color: "white",
        fillColor: "#0B9300",
        alignment: "left",
        padding: [6, 4, 0, 4],
      },
      tableHeader: {
        fillColor: "#2BC900",
        color: "white",
        bold: true,
        alignment: "center",
        margin: [0, 5, 0, 5],
        padding: [6, 4],
        fontSize: 10,
      },
      tableCell: {
        margin: [0, 15, 0, 15],
        padding: [8, 4],
        fontSize: 10,
        alignment: "left",
        baseline: "middle",
      },
      bordered: {
        margin: [0, 0, 0, 0],
        table: {
          widths: ["20%", "20%", "20%", "20%", "20%"],
          body: [],
        },
        layout: {
          hLineWidth: () => 1,
          vLineWidth: () => 1,
          hLineColor: () => "#000",
          vLineColor: () => "#000",
          paddingLeft: () => 4,
          paddingRight: () => 4,
          paddingTop: () => 6,
          paddingBottom: () => 6,
        },
      },
    },
  };

  Object.entries(filtros).forEach(([chave, valor]) => {
    let chaveDinamica = "";
    if (chave === "status") {
      return;
    }
    if (chave === "situacao" && valor) {
      const statusServico = valor;
      chaveDinamica += `${
        statusServicos?.find((status) => status?.id === statusServico?.id)
          ?.descricao
      }`;
      documentDefinition.content.push({
        text: [
          {
            text: `${mapeamentoNomeFiltros.chaves[chave]}: `,
            bold: true,
            fontSize: 9,
          },
          {
            text: chaveDinamica,
            bold: false,
            fontSize: 9,
          },
          "\n",
        ],
      });
    }
    if (
      (Array.isArray(valor) && valor.length > 0) ||
      (typeof valor === "string" &&
        valor !== "selecionar" &&
        valor.trim() !== "" &&
        valor !== undefined &&
        valor !== "") ||
      (valor !== null && typeof valor !== "object" && chave !== "prazo")
    ) {
      switch (mapeamentoNomeFiltros.chaves[chave]) {
        case "Filtro por período":
          chaveDinamica = mapeamentoNomeFiltros.valores[valor];
          break;
        case "Data de início":
          chaveDinamica = moment.utc(valor).format("DD/MM/YYYY");
          break;
        case "Data final":
          chaveDinamica = moment.utc(valor).format("DD/MM/YYYY");
          break;
        case "Elaborador":
          const elaborador =
            processosAdministrativos[0]?.contrato?.elaboradores_orcamento.find(
              (elaborador) => elaborador?.usuario?.id === valor
            );
          chaveDinamica = elaborador?.usuario?.name || "Desconhecido";
          break;
        case "Tipo de Orçamento":
          chaveDinamica = mapeamentoNomeFiltros.tipoOrcamento[valor];
          break;
        case "Status Orçamentário":
          chaveDinamica = mapeamentoNomeFiltros.statusOrcamento[valor];
          break;
        case "Termo de busca":
          chaveDinamica = valor !== "" && `'${valor}'` !== undefined ? valor : "\" \"";
          break;
        case "Depende":
          if (valor.length > 1) {
            const dependeValores = String(valor).split(",");
            for (const depende of dependeValores) {
              chaveDinamica += `${mapeamentoNomeFiltros.valores[depende]}, `;
            }
            chaveDinamica = chaveDinamica.slice(0, -2);
          } else {
            chaveDinamica = mapeamentoNomeFiltros.valores[valor];
          }
          break;
        case "Colaboradores":
        case "Colaboradores":
          chaveDinamica = "";
          if (valor.length > 0) {
            for (const depende of valor) {
              const colaborador = colaboradores.find(
                (elaborador) => elaborador?.id === depende?.id
              );
              if (colaborador) {
                chaveDinamica += colaborador.name + ", ";
              }
            }
            chaveDinamica = chaveDinamica.slice(0, -2);
          }
          break;
        case "Setor (es)":
          const setoresValores = valor;
          for (const setor of setoresValores) {
            chaveDinamica += `${
              setores?.find((set) => set?.id === setor?.id)?.descricao
            }, `;
          }
          chaveDinamica = chaveDinamica.slice(0, -2);
          break;
        default:
          chaveDinamica = mapeamentoNomeFiltros.valores[valor];
      }
      documentDefinition.content.push({
        text: [
          {
            text: `${mapeamentoNomeFiltros.chaves[chave]}: `,
            bold: true,
            fontSize: 9,
          },
          {
            text: chaveDinamica,
            bold: false,
            fontSize: 9,
          },
          "\n",
        ],
      });
    }
  });

  documentDefinition.content.push({
    text: "\n",
  });

  documentDefinition.content.push(
    {
      text: "MATRIZ DE SERVIÇOS",
      fontSize: 10,
      bold: true,
    },
    {
      text: "\n",
    }
  );

  if (processosAdministrativos && processosAdministrativos.length > 0) {
    const tableBody = [];
    const contratosProcessados = new Set();
    const processosAdicionados = new Set();

    processosAdministrativos.forEach((processoAdministrativo) => {
      const contratoDescricao = processoAdministrativo?.contrato?.descricao;
      const contratoId = processoAdministrativo?.contrato?.id;

      if (!contratosProcessados.has(contratoId)) {
        contratosProcessados.add(contratoId);

        processoAdministrativo?.contrato?.unidades?.forEach((unidade) => {
          const unidadeNome = unidade?.nomeUnidade;
          if (unidade?.imoveis?.length > 0) {
           


            unidade.imoveis.forEach((imovel) => {
              const processos = imovel?.processos_contratos.filter(
                (processo) => processo?.servicos && processo.servicos.length > 0
              );

              processos.forEach((processo) => {
                if (
                  processo?.contrato_id === contratoId &&
                  !processosAdicionados.has(processo?.id)
                ) {
                  processosAdicionados.add(processo?.id);

                  if(processo?.contrato_id) {
                    tableBody.push([
                      {
                        text: [
                          { text: "Contrato: ", fontWeight: 1200 },
                          { text: contratoDescricao, bold: false },
                          "\n",
                          { text: "Unidade: ", fontWeight: 1200 },
                          { text: unidadeNome, bold: false },
                        ],
                        style: "header",
                        colSpan: 5,
                        alignment: "left",
                      },
                      {},
                      {},
                      {},
                      {},
                    ]);
                  }

                  tableBody.push([
                    {
                      text: [
                        { text: "Processo Administrativo: ", fontWeight: 1200 },
                        {
                          text: processo?.processo_administrativo?.descricao,
                          bold: false,
                        },
                        "\n",
                        { text: "Imóvel: ", fontWeight: 1200 },
                        { text: imovel?.descricao, bold: false },
                      ],
                      style: "subheader",
                      colSpan: 5,
                      alignment: "left",
                    },
                    {},
                    {},
                    {},
                    {},
                  ]);
                  tableBody.push([
                    { text: "Tarefa", style: "tableHeader" },
                    { text: "Situação", style: "tableHeader" },
                    { text: "Datas", style: "tableHeader" },
                    { text: "Pendente", style: "tableHeader" },
                    { text: "Colaboradores", style: "tableHeader" },
                  ]);

                  processo?.servicos.forEach((servico) => {
                    tableBody.push([
                      {
                        text: servico?.tarefas_servico[0]?.descricao ?? "N/I",
                        style: "tableCell",
                      },
                      {
                        text:
                          servico?.status_andamento_servico?.descricao ?? "N/I",
                        style: "tableCell",
                      },
                      {
                        text: [
                          { text: "Aprovação: ", bold: 1200 },
                          processoAdministrativo?.contrato?.data_aprovacao
                            ? moment
                                .utc(
                                  processoAdministrativo?.contrato
                                    ?.data_aprovacao
                                )
                                .format("DD/MM/YYYY")
                            : "Sem data",
                          "\n",
                          { text: "Início: ", bold: 1200 },
                          servico.data_inicio
                            ? moment
                                .utc(servico.data_inicio)
                                .format("DD/MM/YYYY")
                            : "Sem data",
                          "\n",
                          { text: "Conclusão: ", bold: 1200 },
                          servico.data_conclusao
                            ? moment
                                .utc(servico.data_conclusao)
                                .format("DD/MM/YYYY")
                            : "Sem data",
                          "\n",
                          { text: "Prazo: ", bold: 1200 },
                          servico.prazo
                            ? `${moment
                                .utc(servico.prazo)
                                .format("DD/MM/YYYY")} (${moment
                                .utc(servico.prazo)
                                .startOf("day")
                                .diff(
                                  moment().utc().startOf("day"),
                                  "days"
                                )} dias)`
                            : "Sem data",
                        ],
                        style: "tableCell",
                        options: {
                          baseline: "middle",
                        },
                      },
                      {
                        text: RESPONSAVEIS[servico.responsavel] ?? "N/I",
                        style: "tableCell",
                      },
                      {
                        text:
                          servico?.servicocolaborador?.length > 0
                            ? servico.servicocolaborador
                                .map((colaborador) => colaborador?.users?.name)
                                .join(", ")
                            : "N/I",
                        style: "tableCell",
                      },
                    ]);
                  });
                }
              });
            });
          }
        });
      }
    });
    documentDefinition.content.push({
      style: "bordered",
      table: {
        widths: ["*", "*", "*", "*", "*"],
        body: [...tableBody],
      },
    });
  } else {
    documentDefinition.content.push({
      text: "Nenhum contrato disponível",
      style: "subheader",
    });
  }

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  pdfDocGenerator.getBlob((blob) => {
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  });
};

export default gerarResumoCompletoPDF;
