import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
  InputAdornment,
  Card,
  Badge,
  Chip,
  PaginationItem,
  Paper,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReplayIcon from "@mui/icons-material/Replay";
import StarIcon from "@mui/icons-material/Star";
import { gerarPDF } from "app/utils/pdfGenerator";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { exportarContratoPDF } from "app/utils/exportarContratoPDF";
import EditIcon from "@mui/icons-material/Edit";
import Description from "@mui/icons-material/Description";
import { Visibility } from "@mui/icons-material";
import { DateRangePicker } from "react-dates";
import gerarRelatorioTodosContratos from "app/utils/pdfs/gerarRelatorioTodosContratos";
import gerarRelatorioContratosFixosPDF from "app/utils/pdfs/gerarRelatorioContratosFixosPDF";
import gerarRelatorioContratosRenovacaoPDF from "app/utils/pdfs/gerarRelatorioContratosRenovacaoPDF";
import gerarPDFContratoPadraoIndividual from "app/utils/pdfs/gerarPDFContratoPadraoIndividual";
import gerarPDFContratoFixoIndividual from "app/utils/pdfs/gerarPDFContratoFixoIndividual";
import gerarPDFContratoRenovacaoIndividual from "app/utils/pdfs/gerarPDFContratoRenovacaoIndividual";
import gerarPDFContratoFixoRenovacaoIndividual from "app/utils/pdfs/gerarPDFContratoFixoRenovacaoIndividual";
import gerarRelatorioContratosPadraoPDF from "app/utils/pdfs/gerarRelatorioContratosPadraoPDF";

export const ContratoList = () => {
  const [contratos, setContratos] = useState([]);
  const [selectedContratosIds, setSelectedContratosIds] = useState([]);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [filtroSelecionarContratos, setFiltroSelecionarContratos] =
    useState("todos");
  const [filtroSituacaoContratos, setFiltroSituacaoContratos] =
    useState("padrao");
  const [statusOrcamentarioSelecionado, setStatusOrcamentarioSelecionado] =
    useState("selecionar");
  const [tipoOrcamentoSelecionado, setTipoOrcamentoSelecionado] =
    useState("selecionar");
  const [count, setCount] = useState("");
  const { theme } = useJumboTheme();
  const [expand, setExpand] = useState(false);
  const [todosContratosSelecionados, setTodosContratosSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [exportarParaPDFSelecionado, setExportarParaPDFSelecionado] =
    useState("selecionar");
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const userId = Number(localStorage.getItem("id"));
  const roleId = Number(localStorage.getItem("role_id"));
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [statusOrcamento, setStatusOrcamento] = useState([]);
  const [colaboradores, setcolaboradores] = useState([]);
  const [colaboradorSelecionado, setColaboradorSelecionado] =
    useState("selecionar");
  const [periodoPesquisa, setPeriodoPesquisa] = useState("selecionar");

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  let filtros = {};

  if (filtroSelecionarContratos !== "todos") {
    filtros = {
      ...filtros,
      tipoContrato: filtroSelecionarContratos,
    };
  }

  if (filtroSituacaoContratos !== "padrao") {
    filtros = {
      ...filtros,
      situacao: filtroSituacaoContratos,
    };
  }

  if (statusOrcamentarioSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      statusOrcamento: statusOrcamentarioSelecionado,
    };
  }

  if (colaboradorSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      elaborador: colaboradorSelecionado,
    };
  }

  if (tipoOrcamentoSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      tipoOrcamento: tipoOrcamentoSelecionado,
    };
  }

  if (periodoPesquisa !== "selecionar") {
    filtros = {
      ...filtros,
      periodo: periodoPesquisa,
      startDate: moment.utc(startDate).format('YYYY-MM-DD'),
      endDate: moment.utc(endDate).format('YYYY-MM-DD'),
    };
  }

  filtros.searchTerm = searchTerm;
 
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const handleSelecionarTodosContratos = () => {
    setSelectedContratosIds(
      todosContratosSelecionados ? [] : contratos.map((contrato) => contrato.id)
    );
    setTodosContratosSelecionados(!todosContratosSelecionados);
  };

  const handleDeleteMuitosContratos = () => {
    Swal.fire({
      title: "Tem certeza que deseja apagar os contratos selecionados?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMuitosContratos();
      }
    });
  };

  const handleExportarContratosParaPDF = (tipoContrato) => {
    switch (tipoContrato) {
      case "todos":
        gerarRelatorioTodosContratos(filtros);
        break;
      case "padrao":
        gerarRelatorioContratosPadraoPDF(filtros, true);
        break;
      case "fixo":
        gerarRelatorioContratosFixosPDF(filtros);
        break;
      case "renovacao":
        gerarRelatorioContratosRenovacaoPDF(filtros);
        break;
    }
  };

  const handleExportarContratoIndividualParaPDF = (
    idContrato,
    tipoContrato
  ) => {
    console.log(`ID do Contrato: ${idContrato}`);
    console.log(tipoContrato);
    switch (tipoContrato) {
      case "renovacaoFixoIndividual":
        gerarPDFContratoFixoRenovacaoIndividual(idContrato);
        break;
      case "padraoIndividual":
        gerarPDFContratoPadraoIndividual(idContrato);
        break;
      case "fixoIndividual":
        gerarPDFContratoFixoIndividual(idContrato);
        break;
      case "renovacaoIndividual":
        gerarPDFContratoRenovacaoIndividual(idContrato);
        break;
    }
  };

  const deleteMuitosContratos = useCallback(async () => {
    try {
      await ApiService.delete("/contratos/delete/many", {
        data: { ids: selectedContratosIds },
      }).then((response) => {
        if (response.status === 200) {
          toast("success", "Contratos removidos com sucesso!");
          getContratos();
          setSelectedContratosIds([]);
          setTodosContratosSelecionados(false);
        }
      });
    } catch (error) {
      toast("error", "Não foi possível deletar os contratos selecionados");
      console.log(error);
    }
  }, [selectedContratosIds]);

  const handleCheckboxChange = (contratoId) => {
    setSelectedContratosIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(contratoId)) {
        return prevSelectedIds.filter((id) => id !== contratoId);
      } else {
        return [...prevSelectedIds, contratoId];
      }
    });
    setTodosContratosSelecionados(false);
  };

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const getStatusOrcamento = async () => {
    try {
      await ApiService.get("/statusorcamento").then((response) => {
        if (response.status === 200) {
          setStatusOrcamento(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getColaboradores = async () => {
    try {
      await ApiService.get(
        "/users/colaboradores?filtroRoles=financeiro_admin"
      ).then((response) => {
        if (response.status === 200) {
          setcolaboradores(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getContratos = useCallback(async () => {
    try {
      let apiUrl = `/contratos?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&tipoContrato=${filtroSelecionarContratos}&situacao=${filtroSituacaoContratos}&userId=${userId}&roleId=${roleId}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      if (startDate && endDate) {
        apiUrl += `&dataInicio=${moment(startDate._d).format(
          "YYYY-MM-DD"
        )}&dataFim=${moment(endDate._d).format("YYYY-MM-DD")}`;
      }

      if (statusOrcamentarioSelecionado !== "selecionar") {
        apiUrl += `&statusOrcamento=${statusOrcamentarioSelecionado}`;
      }

      if (colaboradorSelecionado && colaboradorSelecionado !== "selecionar") {
        apiUrl += `&idElaborador=${colaboradorSelecionado}`;
      }

      if (periodoPesquisa !== "selecionar") {
        apiUrl += `&periodo=${periodoPesquisa}`;
      }

      if (tipoOrcamentoSelecionado !== "selecionar") {
        apiUrl += `&tipoOrcamento=${Number(tipoOrcamentoSelecionado)}`;
      }

      ApiService.get(apiUrl)
        .then((response) => {
          const initialData = response.data.contratos.map((contrato) => ({
            ...contrato,
            isChecked: false,
          }));
          console.log("contratos", initialData);
          setContratos(initialData);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [
    page,
    perPage,
    sortBy,
    sortOrder,
    searchTerm,
    filtroSelecionarContratos,
    filtroSituacaoContratos,
    statusOrcamentarioSelecionado,
    startDate,
    endDate,
    colaboradorSelecionado,
    periodoPesquisa,
    tipoOrcamentoSelecionado,
  ]);

  const handleLimparFiltros = () => {
    setPage(0);
    setPerPage(10);
    setSortBy("id");
    setSortOrder("desc");
    setSearchTerm("");
    setFiltroSelecionarContratos("todos");
    setFiltroSituacaoContratos("padrao");
    setStatusOrcamentarioSelecionado("selecionar");
    setColaboradorSelecionado("selecionar");
    setStartDate(null);
    setEndDate(null);
    setPeriodoPesquisa("selecionar");
    setTipoOrcamentoSelecionado("selecionar");
  };

  useEffect(() => {
    getContratos();
    getStatusOrcamento();
    getColaboradores();
  }, [getContratos]);
  return (
    <JumboContentLayout
      header={<PageHeader title={"Contratos"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Link
          to="/app/novo-contrato"
          state={{ backUrl: location.pathname }}
          style={{ textDecoration: "none" }}
        >
          <LoadingButton
            color="success"
            type="submit"
            variant="contained"
            size="large"
            sx={{
              maxWidth: { md: "200px", mt: "1rem" },
              marginBottom: "2rem",
            }}
          >
            Novo Contrato
          </LoadingButton>
        </Link>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          {selectedContratosIds.length > 0 && (
            <Grid item>
              <span style={{ margin: 10 }}>
                {selectedContratosIds.length} contrato (s) selecionado(s)
              </span>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteMuitosContratos}
              >
                Excluir Selecionados
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSelecionarTodosContratos}
            >
              {todosContratosSelecionados
                ? "Desmarcar Todos"
                : "Selecionar Todos"}
            </Button>
          </Grid>
        </Grid>
        <React.Fragment>
          <Grid
            container
            p={3}
            mt={3}
            gap={3}
            style={{ background: "white", minHeight: "120px" }}
          >
            <Grid item>
              <Box mt={2} mb={2}>
                <TextField
                  label="Buscar"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleSearch(searchTerm)}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Grid item my={-0.6}>
                <InputLabel>PDF:</InputLabel>
                <Select
                  id="exportar-pdf-select"
                  value={exportarParaPDFSelecionado}
                  onChange={(event) => {
                    const value = event.target.value;
                    setExportarParaPDFSelecionado("selecionar");
                    handleExportarContratosParaPDF(value);
                  }}
                >
                  <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                  <MenuItem value={"todos"}>Todos os contratos</MenuItem>
                  <MenuItem value={"padrao"}>Contratos Padrão</MenuItem>
                  <MenuItem value={"fixo"}>Contratos Fixos</MenuItem>
                  <MenuItem value={"renovacao"}>
                    Contratos de Renovação
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Selecionar:</InputLabel>
              <Select
                id="selecionar-contratos-select"
                value={filtroSelecionarContratos}
                onChange={(event) => {
                  const value = event.target.value;
                  setFiltroSelecionarContratos(value);
                }}
              >
                <MenuItem value={"todos"}>Todos os contratos</MenuItem>
                <MenuItem value={"fixo"}>Contratos Fixos</MenuItem>
                <MenuItem value={"renovacao"}>Contratos de Renovação</MenuItem>
              </Select>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Situação:</InputLabel>
              <Select
                id="situacao-contratos-select"
                value={filtroSituacaoContratos}
                onChange={(event) => {
                  const value = event.target.value;
                  setFiltroSituacaoContratos(value);
                }}
              >
                <MenuItem value={"padrao"}>Contratos Padrão</MenuItem>
                <MenuItem value={"aguardando_analise"}>
                  Contratos a serem revisados
                </MenuItem>
              </Select>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Tipo de Orçamento:</InputLabel>
              <Select
                id="elaborador-contratos-select"
                value={tipoOrcamentoSelecionado}
                onChange={(event) => {
                  const value = event.target.value;
                  setTipoOrcamentoSelecionado(value);
                }}
              >
                <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                <MenuItem value={1}>Agronegócio</MenuItem>
                <MenuItem value={2}>Mineração</MenuItem>
                <MenuItem value={3}>Postos de Combustíveis</MenuItem>
                <MenuItem value={4}>Prestação de Serviços</MenuItem>
              </Select>
            </Grid>
            <Grid item lg={4} my={-0.8}>
              <Typography>Filtrar por período</Typography>
              <FormControl
                fullWidth
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Box>
                  <Select
                    value={periodoPesquisa ?? "selecionar"}
                    onChange={(event) => {
                      setPeriodoPesquisa(event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                    <MenuItem value="createdAt">Data de Criação</MenuItem>
                    <MenuItem value="data_aprovacao">
                      Data de Aprovação
                    </MenuItem>
                  </Select>
                </Box>
                <Box>
                  <DateRangePicker
                    small={true}
                    startDate={startDate}
                    startDatePlaceholderText="Data inicial"
                    startDateId="data_inicio"
                    endDate={endDate}
                    endDateId="data_final"
                    endDatePlaceholderText="Data final"
                    onDatesChange={handleDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={handleFocusChange}
                    isOutsideRange={() => false}
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Status Orçamentário:</InputLabel>
              <Select
                id="status-orcamentario-contratos-select"
                value={statusOrcamentarioSelecionado}
                onChange={(event) => {
                  const value = event.target.value;
                  setStatusOrcamentarioSelecionado(value);
                }}
              >
                <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                {statusOrcamento &&
                  statusOrcamento.length > 0 &&
                  statusOrcamento.map((status) => (
                    <MenuItem value={status.id}>{status.descricao}</MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Elaborador:</InputLabel>
              <Select
                id="elaborador-contratos-select"
                value={colaboradorSelecionado}
                onChange={(event) => {
                  const value = event.target.value;
                  setColaboradorSelecionado(value);
                }}
              >
                <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                {colaboradores &&
                  colaboradores.length > 0 &&
                  colaboradores.map((colaborador) => (
                    <MenuItem value={colaborador.id}>
                      {colaborador.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Filtros:</InputLabel>
              <Button onClick={handleLimparFiltros} variant="outlined">
                Limpar Filtros
              </Button>
            </Grid>
            {contratos.length > 0 ? (
              <>
                {contratos.map((contrato, key) => (
                  <Card
                    sx={{ mb: 1, p: 2 }}
                    style={{ width: "100%" }}
                    key={key}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item lg={1}>
                        <Checkbox
                          key={contrato.id}
                          checked={selectedContratosIds.includes(contrato.id)}
                          onChange={() => handleCheckboxChange(contrato.id)}
                        />
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "500" }}
                          >
                            <Chip
                              color="success"
                              label={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography fontSize={18} fontWeight={500}>
                                    {contrato.descricao}
                                  </Typography>
                                  {contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() ===
                                      "Renovação".toLowerCase()
                                  ) && (
                                    <ReplayIcon fontSize={"small"} gap={10} />
                                  )}
                                  {contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() ===
                                      "Contrato fixo".toLowerCase()
                                  ) && <StarIcon fontSize={"small"} gap={10} />}
                                </Box>
                              }
                              my={2}
                            />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={"16px"}>Cliente</Typography>
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "500" }}
                          >
                            {contrato.Cliente?.nome ?? "N/I"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={"16px"}>
                            Data criação
                          </Typography>
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "500" }}
                          >
                            {moment(contrato.createdAt).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography fontSize={"16px"}>
                            Elaborador (es)
                          </Typography>
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "500" }}
                          >
                            <Stack direction={"row"} gap={1}>
                              {contrato?.elaboradores_orcamento?.length > 0 && (
                                <>
                                  <Chip
                                    label={
                                      contrato?.elaboradores_orcamento[0]
                                        ?.usuario?.name
                                    }
                                  />
                                  {contrato?.elaboradores_orcamento?.length >
                                    1 && (
                                    <Chip
                                      label={`+${
                                        contrato?.elaboradores_orcamento
                                          ?.length - 1
                                      }`}
                                    />
                                  )}
                                </>
                              )}
                            </Stack>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box style={{ display: "flex", gap: "10px" }}>
                          <Tooltip title="Exportar para PDF">
                            <PictureAsPdfIcon
                              cursor="pointer"
                              sx={{ color: "#F30744" }}
                              onClick={() => {
                                // exportarContratoPDF(contrato.id);
                                const temRenova =
                                  contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() ===
                                      "Renovação".toLowerCase()
                                  );

                                const temFixo =
                                  contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() ===
                                      "Contrato fixo".toLowerCase()
                                  );

                                const temPadrao =
                                  contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() === "PADRÃO".toLowerCase()
                                  );

                                let tipoContrato = null;
                                if (temFixo && temRenova) {
                                  tipoContrato = "renovacaoFixoIndividual";
                                } else if (temFixo) {
                                  tipoContrato = "fixoIndividual";
                                } else if (temRenova) {
                                  tipoContrato = "renovacaoIndividual";
                                } else if (temPadrao) {
                                  tipoContrato = "padraoIndividual";
                                } else {
                                  tipoContrato = "renovacaoIndividual";
                                }

                                // window.open(
                                //   `/pdf/contrato?tipoContrato=${tipoContrato}&contratoId=${contrato.id}`,
                                //   "_blank"
                                // );
                                handleExportarContratoIndividualParaPDF(
                                  contrato?.id,
                                  tipoContrato
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Ver detalhes">
                            <Visibility
                              cursor="pointer"
                              color="success"
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contrato.id}/detalhes`
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Processos Administrativos">
                            <Description
                              cursor="pointer"
                              sx={{ color: "#45E658" }}
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contrato.id}/cliente/${contrato?.Cliente?.id}/processos`,
                                  {
                                    state: {
                                      descricao: contrato.descricao,
                                      id: contrato.id,
                                    },
                                  }
                                );
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Editar">
                            <EditIcon
                              cursor="pointer"
                              color="info"
                              onClick={() => {
                                navigate(`/app/editar-contrato/${contrato.id}`);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Excluir">
                            <DeleteIcon
                              cursor="pointer"
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contrato.id}/deletar`
                                );
                              }}
                              color="error"
                            />
                          </Tooltip>
                          {/* <Link
                            to={`/app/editar-contrato/${contrato.id}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              color="info"
                              variant="contained"
                              size="small"
                              disableElevation
                            ></Button>
                          </Link>
                          <Button
                            color="error"
                            variant="contained"
                            size="small"
                            onClick={() => modalAlert(contrato.id)}
                          ></Button> */}
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </>
            ) : (
              <Grid container>
                <Grid item style={{ width: "100%" }}>
                  <Box my={5} textAlign={"center"}>
                    <Typography>Nenhum contrato cadastrado.</Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
            <Paper style={{ background: "white", width: "100%" }}>
              <FormControl>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  display={"flex"}
                  justifyContent={"space-evenly"}
                >
                  <Grid item>
                    <Box my={2}>
                      <Typography>Página</Typography>
                      <Pagination
                        count={Math.ceil(count / perPage)}
                        page={page + 1}
                        onChange={(event, value) => setPage(value - 1)}
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                        style={{ marginTop: 10 }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Itens por página</Typography>
                      <TextField
                        select
                        label={"Itens por página"}
                        value={perPage}
                        onChange={(e) => setPerPage(parseInt(e.target.value))}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Ordenar por</Typography>
                      <TextField
                        select
                        label={"Ordenação"}
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={"id"}>ID</MenuItem>
                        <MenuItem value={"createdAt"}>Data de Criação</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Ordem</Typography>
                      <TextField
                        select
                        label={"Ordem"}
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={"asc"}>Ascendente</MenuItem>
                        <MenuItem value={"desc"}>Descendente</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
          </Grid>
        </React.Fragment>
      </Grid>
    </JumboContentLayout>
  );
};
