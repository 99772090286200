import React from "react";

const ContratoPadraoIndividualPDF = ({ contrato }) => {
  let valorTotalGeral = 0; 

  return (
    <table
      className="tabela-contratos"
      style={{ width: "100mm", tableLayout: "auto" }}
    >
      {contrato && contrato.unidades && contrato.unidades.length > 0 ? (
        contrato.unidades.map((unidade) =>
          unidade.imoveis.map((imovel) =>
            imovel.processos_contratos
              .filter(
                (processo) => processo.servicos && processo.servicos.length > 0
              ) 
              .map((processo, index) => {
                let valorTotalProcesso = 0;

                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td
                        colSpan={5}
                        style={{ background: "#024900", color: "white" }}
                      >
                        Processo Administrativo:{" "}
                        {processo.processo_administrativo?.descricao}
                      </td>
                    </tr>
                    <tr>
                      <td
                        colSpan={5}
                        style={{ background: "#0B9300", color: "white" }}
                      >
                        Imóvel: {imovel?.descricao}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ background: "#2BC900", color: "white" }}>
                        Contrato
                      </td>
                      <td style={{ background: "#2BC900", color: "white" }}>
                        Tarefa
                      </td>
                      <td style={{ background: "#2BC900", color: "white" }}>
                        Quantidade
                      </td>
                      <td style={{ background: "#2BC900", color: "white" }}>
                        Valor
                      </td>
                      <td style={{ background: "#2BC900", color: "white" }}>
                        Valor Total
                      </td>
                    </tr>
                    {processo.servicos.map((servico, servicoIndex) => {
                      const valorServicoTotal =
                        Number(servico?.valor) * Number(servico?.quantidade);
                      valorTotalProcesso += valorServicoTotal; 
                      valorTotalGeral += valorServicoTotal; 

                      return (
                        <tr key={servicoIndex}>
                          <td>{contrato?.descricao}</td>
                          <td>
                            {servico?.tarefas_servico[0]?.descricao ?? "N/I"}
                          </td>
                          <td>{servico?.quantidade}</td>
                          <td>
                            {Number(servico?.valor).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                          <td>
                            {valorServicoTotal.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                        </tr>
                      );
                    })}
                  </React.Fragment>
                );
              })
          )
        )
      ) : (
        <tr>
          <td colSpan={1}>Nenhum contrato disponível</td>
        </tr>
      )}
      <tfoot>
        <tr>
          <td colSpan={4} style={{ background: "green", color: "white" }}>
            Total:
          </td>
          <td colSpan={1} style={{ background: "green", color: "white", textAlign: "right" }}>
            {valorTotalGeral.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default ContratoPadraoIndividualPDF;
