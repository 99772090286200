import moment from "moment";
import React from "react";

const CabecalhoDocumentoPDF = ({objetoFiltros, contratos, contrato, filtros, FILTROS_CHAVES_VALORES, tituloDocumento, nomeSetor}) => {
  return (
    <>
      {objetoFiltros?.tipoContrato !== "renovacaoIndividual" &&
      objetoFiltros?.tipoContrato !== "fixoIndividual" &&
      objetoFiltros?.tipoContrato !== "renovacaoFixoIndividual" &&
      objetoFiltros?.tipoContrato !== "padraoIndividual" ? (
        <>
          <h1 className="titulo-contratos">{objetoFiltros?.tipoContrato === 'financeiro' ? 'Relatório Financeiro' : tituloDocumento}</h1>
          {filtros && filtros.length > 0 ? (
            <>
              <p>
                <span className="filtros-contratos-pdf">Filtros:</span>
              </p>
              {filtros.map((objetoFiltro) => {
                const objetosFiltrosEntries =
                  Object.entries(objetoFiltro).flat();
                const idElaboradorFiltro = objetoFiltro?.elaborador;

                const elaborador = contratos[0]?.elaboradores_orcamento?.find(
                  (elaboradorOrcamento) =>
                    String(elaboradorOrcamento?.usuario?.id) ===
                    String(idElaboradorFiltro)
                );

                return (
                  <p key={objetosFiltrosEntries[0]}>
                    <span className="nome-filtro">
                      {FILTROS_CHAVES_VALORES.chaves[objetosFiltrosEntries[0]]}:
                    </span>
                    {"  "}
                    {FILTROS_CHAVES_VALORES.valores[objetosFiltrosEntries[1]]}
                    {FILTROS_CHAVES_VALORES.chaves[
                      objetosFiltrosEntries[0]
                    ] === "Setor" ? nomeSetor : null}
                    {console.log(FILTROS_CHAVES_VALORES.chaves[
                      objetosFiltrosEntries[0]
                    ])}
                    {FILTROS_CHAVES_VALORES.valores[
                      objetosFiltrosEntries[1]
                    ] === "Data de criação" ||
                    FILTROS_CHAVES_VALORES.valores[objetosFiltrosEntries[1]] ===
                      "Data de aprovação" ||
                      FILTROS_CHAVES_VALORES.valores[objetosFiltrosEntries[1]] ===
                      "Data de registro" ||
                      FILTROS_CHAVES_VALORES.valores[objetosFiltrosEntries[1]] ===
                      "Data de conclusão" ||
                      FILTROS_CHAVES_VALORES.valores[objetosFiltrosEntries[1]] ===
                      "Prazo"
                      ? ` - ${moment
                          .utc(objetoFiltros?.dataInicio)
                          .format("DD/MM/YYYY")} à ${moment
                          .utc(objetoFiltros?.dataFim)
                          .format("DD/MM/YYYY")}`
                      : null}
                    {FILTROS_CHAVES_VALORES.chaves[objetosFiltrosEntries[0]] ===
                    "Elaborador"
                      ? `${elaborador?.usuario?.name}`
                      : null}
                  </p>
                );
              })}
            </>
          ) : null}
        </>
      ) : (
        <>
          <p>
            <span className="filtros-contratos-pdf">
              PROPOSTA DE ORÇAMENTO - {contrato?.descricao} - 
              {FILTROS_CHAVES_VALORES.valores[objetoFiltros?.tipoContrato]}
            </span>
          </p>
          <p>
            <span className="filtros-contratos-pdf">Cliente:</span>
            {contrato?.Cliente?.nome}
          </p>
          {objetoFiltros?.tipoContrato !== "fixoIndividual" &&
            <p>
              <span className="filtros-contratos-pdf">Data de vencimento:</span>
              {moment
                .utc(contrato?.dataVencimento)
                .format("D [de] MMMM [de] YYYY")}
            </p>
          }
        </>
      )}
    </>
  );
};

export default CabecalhoDocumentoPDF;
