import React from "react";
import Page from "@jumbo/shared/Page";
import UserList from "app/pages/users/UserList";
import UserForm from "app/pages/users/UserForm";
import login from "app/pages/login";
import Home from "app/pages/home";
import ClientesList from "app/pages/clientes/ClientesList";
import ClienteForm from "app/pages/clientes/ClienteForm";
import SegmentosList from "app/pages/segmentos/SegmentosList";
import SegmentosForm from "app/pages/segmentos/SegmentosForm";
import CentroDeCustosList from "app/pages/centrosDeCustos/CentroDeCustosList";
import CentrosDeCustosForm from "app/pages/centrosDeCustos/CentrosDeCustosForm";
import TarefasList from "app/pages/tarefas/TarefasList";
import TarefasForm from "app/pages/tarefas/TarefasForm";
import TiposServicoList from "app/pages/tiposServico/TiposServicoList";
import TiposServicoForm from "app/pages/tiposServico/TiposServicoForm";
import StatusOrcamentoList from "app/pages/statusOrcamento/StatusOrcamentoList";
import StatusOrcamentoForm from "app/pages/statusOrcamento/StatusOrcamentoForm";
import ProcessosAdministrativosList from "app/pages/processosAdministrativos/ProcessosAdministrativosList";
import ProcessosAdministrativosForm from "app/pages/processosAdministrativos/ProcessosAdministrativosForm";
import ServicosList from "app/pages/servicos/ServicosList";
import { ServicosForm } from "app/pages/servicos/ServicosForm";
import ContratoForm from "app/pages/contratos/ContratoForm";
import TipoOrcamentoList from "app/pages/tipoOrcamento/TipoOrcamentoList";
import TipoOrcamentoForm from "app/pages/tipoOrcamento/TipoOrcamentoForm";
import TipoContratoList from "app/pages/tipoContrato/TipoContratoList";
import TipoContratoForm from "app/pages/tipoContrato/TipoContratoForm";
import SetoresList from "app/pages/setores/SetoresList";
import SetoresForm from "app/pages/setores/SetoresForm";
import PlanoContasList from "app/pages/planoContas/PlanoContasList";
import PlanoContasForm from "app/pages/planoContas/PlanoContasForm";
import ControleCaixaList from "app/pages/controleCaixa/ControleCaixaList";
import ControleCaixaForm from "app/pages/controleCaixa/ControleCaixaForm";
import ConsolidacaoFcList from "app/pages/consolidacaoFc/ConsolidacaoFcList";


import { ContratoList } from "app/pages/contratos/ContratoList";
import { ServicoFinanceiro } from "app/pages/servicos/ServicoFinanceiro";
import LAYOUT_NAMES from "app/layouts/layouts";
import PlanoAcao from "app/pages/dashboard/Dashboard";
import Dashboard from "app/pages/dashboard/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import StatusServicoList from "app/pages/statusServico/StatusServicoList";
import StatusServicoForm from "app/pages/statusServico/StatusServicoForm";
import Licencas from "app/pages/licencas/Licencas";
import LicencaForm from "app/pages/licencas/LicencaForm";
import AlvaraLicencaList from "app/pages/alvaraLicenca/AlvaraLicencaList";
import AlvaraLicencaForm from "app/pages/alvaraLicenca/AlvaraLicencaForm";
import Unidades from "app/pages/unidades/Unidades";
import UnidadeForm from "app/pages/unidades/UnidadeForm";
import AlvaraLicencaComplementarList from "app/pages/alvaraLicencaComplementar/AlvaraLicencaComplementarList";
import AlvaraLicencaComplementarForm from "app/pages/alvaraLicencaComplementar/AlvaraLicencaComplementarForm";
import TipoUnidadeList from "app/pages/tipoUnidade/TipoUnidadeList";
import TipoUnidadeForm from "app/pages/tipoUnidade/TipoUnidadeForm";
import GrupoRestricaoList from "app/pages/grupoRestricao/GrupoRestricaoList";
import GrupoRestricaoForm from "app/pages/grupoRestricao/GrupoRestricaoForm";
import DocumentosList from "app/pages/documentos/DocumentosList";
import DocumentoForm from "app/pages/documentos/DocumentoForm";
import UnidadeDetails from "app/pages/unidades/UnidadeDetails";
import { LicencasDetails } from "app/pages/licencas/LicencasDetails";
import CondicionantesForm from "app/pages/condicionantes/CondicionantesForm";
import CondicionantesList from "app/pages/condicionantes/CondicionantesList";
import CondicionanteLicencaForm from "app/pages/licencas/CondicionanteLicencaForm";
import CaixaEntradaList from "app/pages/caixaEntrada/CaixaEntradaList";
import EsqueceuSenha from "app/pages/esqueceu-senha/EsqueceuSenha";
import { ResetSenha } from "app/pages/reset-senha/ResetSenha";
import UsuariosClientes from "app/pages/clientes/UsuariosClientes";
import UsuariosClienteForm from "app/pages/clientes/UsuariosClienteForm";
import { FinanceiroList } from "app/pages/financeiro/FinanceiroList";
import UnidadesClienteList from "app/pages/clientes/UnidadesClienteList";
import UnidadeClienteForm from "app/pages/clientes/UnidadeClienteForm";
import ImoveisCliente from "app/pages/clientes/ImoveisCliente";
import ImovelClienteForm from "app/pages/clientes/ImovelClienteForm";
import ProcessosContrato from "app/pages/contratos/ProcessosContrato";
import ProcessosContratoForm from "app/pages/contratos/ProcessosContratoForm";
import ServicosContratoList from "app/pages/contratos/ServicosContratoList";
import ServicosContratoForm from "app/pages/contratos/ServicosContratoForm";
import OrgaosList from "app/pages/orgaos/OrgaosList";
import OrgaoForm from "app/pages/orgaos/OrgaoForm";
import DocumentosServicosList from "app/pages/contratos/DocumentosServicosList";
import DocumentosServicosForm from "app/pages/contratos/DocumentosServicosForm";
import ContratoDetalhes from "app/pages/contratos/ContratoDetalhes";
import ProtocolosList from "app/pages/contratos/ProtocolosList";
import ProtocolosForm from "app/pages/contratos/ProtocolosForm";
import LicencasContratoList from "app/pages/contratos/LicencasContratoList";
import LicencasContratoForm from "app/pages/contratos/LicencasContratoForm";
import ContasAPagar from "app/pages/contasAPagar/ContasAPagar";
import ContasAPagarForm from "app/pages/contasAPagar/ContasAPagarForm";
import FornecedoresList from "app/pages/fornecedores/FornecedoresList";
import FornecedorForm from "app/pages/fornecedores/FornecedoresForm";
import CategoriasContaList from "app/pages/categoriasConta/CategoriasContaList";
import CategoriasContaForm from "app/pages/categoriasConta/CategoriasContaForm";
import AgendaContaForm from "app/pages/contasAPagar/AgendaContaForm";
import ServicoContratoDetalhes from "app/pages/contratos/ServicoContratoDetalhes";
import PDFContratos from "app/pages/PDFs/PDFContratos";
import PDFPaisagem from "app/pages/PDFs/PDFPaisagem";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    {
      path: "/reset-senha/:token",
      element: <Page component={ResetSenha} layout={"solo-page"} />,
    },
    {
      path: "/esqueceu-senha",
      element: <Page component={EsqueceuSenha} layout={"solo-page"} />,
    },
    {
      path: "/login",
      element: <Page component={login} layout={"solo-page"} />,
    },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  {
    path: "/",
    element: <Page component={Dashboard} />,
  },
  {
    path: "/app/listar-colaboradores",
    element: <Page component={UserList} />,
  },
  {
    path: "/app/novo-colaborador",
    element: (
      <Page
        component={UserForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-colaborador/:id",
    element: (
      <Page
        component={UserForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-clientes",
    element: <Page component={ClientesList} />,
  },
  {
    path: "/app/novo-cliente",
    element: <Page component={UsuariosClienteForm} />,
  },
  {
    path: "/app/editar-cliente/:id",
    element: <Page component={UsuariosClienteForm} />,
  },
  {
    path: "/app/cliente/:id/deletar",
    element: <Page component={UsuariosClienteForm} />,
  },
  {
    path: "/app/cliente/unidades/:clienteId",
    element: <Page component={UnidadesClienteList} />,
  },
  {
    path: "/app/cliente/:clienteId/unidades/nova/",
    element: <Page component={UnidadeClienteForm} />,
  },
  {
    path: "/app/cliente/:clienteId/unidades/editar/:id",
    element: <Page component={UnidadeClienteForm} />,
  },
  {
    path: "/app/cliente/:clienteId/unidades/:id/deletar",
    element: <Page component={UnidadeDetails} />,
  },
  {
    path: "/app/cliente/:clienteId/unidade/:unidadeId/imoveis",
    element: <Page component={ImoveisCliente} />,
  },
  {
    path: "/app/cliente/:clienteId/unidade/:unidadeId/imoveis/novo",
    element: <Page component={ImovelClienteForm} />,
  },
  {
    path: "/app/cliente/:clienteId/unidade/:unidadeId/imovel/:id/editar",
    element: <Page component={ImovelClienteForm} />,
  },
  {
    path: "/app/cliente/:clienteId/unidade/:unidadeId/imovel/:id/deletar",
    element: <Page component={ImovelClienteForm} />,
  },
  {
    path: "/app/listar-segmentos",
    element: (
      <Page
        component={SegmentosList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-segmento",
    element: (
      <Page
        component={SegmentosForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-segmento/:id",
    element: (
      <Page
        component={SegmentosForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-centros-de-custos",
    element: (
      <Page
        component={CentroDeCustosList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-centro-de-custos/:id",
    element: (
      <Page
        component={CentrosDeCustosForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-centro-de-custos",
    element: (
      <Page
        component={CentrosDeCustosForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-documentos-licencas",
    element: (
      <Page
        component={DocumentosList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-documento-licenca",
    element: (
      <Page
        component={DocumentoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-documento-licenca/:id",
    element: (
      <Page
        component={DocumentoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-tarefas",
    element: <Page component={TarefasList} permission={"Administrativo"} requiredRole={"read"} />,
  },
  {
    path: "/app/editar-tarefa/:id",
    element: <Page component={TarefasForm} />,
  },
  {
    path: "/app/nova-tarefa/",
    element: <Page component={TarefasForm} />,
  },
  {
    path: "/app/listar-servicos",
    element: <Page component={ServicosList} />,
  },
  {
    path: "/app/novo-servico",
    element: <Page component={ServicosForm} />,
  },
  {
    path: "/app/editar-servico/:id",
    element: <Page component={ServicosForm} />,
  },
  {
    path: "/app/listar-tipos-servico",
    element: (
      <Page
        component={TiposServicoList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-tipo-servico",
    element: (
      <Page
        component={TiposServicoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-tipo-servico/:id",
    element: (
      <Page
        component={TiposServicoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/plano-de-contas",
    element: (
      <Page
        component={PlanoContasList}
        permission={"Financeiro"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-plano-conta",
    element: (
      <Page
        component={PlanoContasForm}
        permission={"Financeiro"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-plano-conta/:id",
    element: (
      <Page
        component={PlanoContasForm}
        permission={"Financeiro"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/controle-de-caixa",
    element: (
      <Page
        component={ControleCaixaList}
        permission={"Financeiro"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-lancamento",
    element: (
      <Page
        component={ControleCaixaForm}
        permission={"Financeiro"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-lancamento/:id",
    element: (
      <Page
        component={ControleCaixaForm}
        permission={"Financeiro"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/consolidacao-fc",
    element: (
      <Page
        component={ConsolidacaoFcList}
        permission={"Financeiro"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/contas-a-pagar",
    element: (
      <Page
        component={ContasAPagar}
        permission={"Financeiro"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/nova-conta-a-pagar",
    element: (
      <Page
        component={ContasAPagarForm}
        permission={"Financeiro"}
        requiredRole={"create"}
      />
    ),
  },
  {
    path: "/app/editar-conta-a-pagar/:id",
    element: (
      <Page
        component={ContasAPagarForm}
        permission={"Financeiro"}
        requiredRole={"update"}
      />
    ),
  },
  {
    path: "/app/conta-a-pagar/:id/agenda",
    element: (
      <Page
        component={AgendaContaForm}
        permission={"Financeiro"}
        requiredRole={"update"}
      />
    ),
  },
  {
    path: "/app/listar-status-orcamento",
    element: (
      <Page
        component={StatusOrcamentoList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-status-orcamento",
    element: (
      <Page
        component={StatusOrcamentoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-status-orcamento/:id",
    element: (
      <Page
        component={StatusOrcamentoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-processos-admin",
    element: (
      <Page
        component={ProcessosAdministrativosList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-processo-admin/:id",
    element: (
      <Page
        component={ProcessosAdministrativosForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-processo-admin",
    element: (
      <Page
        component={ProcessosAdministrativosForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/fornecedores",
    element: (
      <Page
        component={FornecedoresList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-fornecedor",
    element: (
      <Page
        component={FornecedorForm}
        permission={"Administrativo"}
        requiredRole={"create"}
      />
    ),
  },
  {
    path: "/app/editar-fornecedor/:id",
    element: (
      <Page
        component={FornecedorForm}
        permission={"Administrativo"}
        requiredRole={"update"}
      />
    ),
  },
  {
    path: "/app/categorias-conta",
    element: (
      <Page
        component={CategoriasContaList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/nova-categoria-conta",
    element: (
      <Page
        component={CategoriasContaForm}
        permission={"Administrativo"}
        requiredRole={"create"}
      />
    ),
  },
  {
    path: "/app/editar-categoria-conta/:id",
    element: (
      <Page
        component={CategoriasContaForm}
        permission={"Administrativo"}
        requiredRole={"update"}
      />
    ),
  },
  {
    path: "/app/novo-contrato",
    element: <Page component={ContratoForm} />,
  },
  {
    path: "/app/listar-contratos",
    element: <Page component={ContratoList} />,
  },
  {
    path: "/app/editar-contrato/:id",
    element: <Page component={ContratoForm} />,
  },
  {
    path: "/app/contrato/:id/deletar",
    element: <Page component={ContratoForm} />,
  },
  {
    path: "/app/contrato/:id/detalhes",
    element: <Page component={ContratoDetalhes} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos",
    element: <Page component={ProcessosContrato} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/novo",
    element: <Page component={ProcessosContratoForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/editar/:processoId",
    element: <Page component={ProcessosContratoForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/deletar",
    element: <Page component={ProcessosContratoForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/servicos",
    element: <Page component={ServicosContratoList} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/servicos/novo",
    element: <Page component={ServicosContratoForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/servicos/editar/:servicoId",
    element: <Page component={ServicosContratoForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/servicos/:servicoId/deletar",
    element: <Page component={ServicosContratoForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/servicos/:servicoId/detalhes",
    element: <Page component={ServicoContratoDetalhes} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/servicos/:servicoId/documentos",
    element: <Page component={DocumentosServicosList} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/servicos/:servicoId/documentos/novo",
    element: <Page component={DocumentosServicosForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/servicos/:servicoId/documentos/:documentoId/editar",
    element: <Page component={DocumentosServicosForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/protocolos",
    element: <Page component={ProtocolosList} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/protocolos/novo",
    element: <Page component={ProtocolosForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/protocolos/editar/:protocoloId",
    element: <Page component={ProtocolosForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/protocolos/deletar/:protocoloId",
    element: <Page component={ProtocolosForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/licencas",
    element: <Page component={LicencasContratoList} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/licencas/nova",
    element: <Page component={LicencasContratoForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/licencas/:licencaId/editar",
    element: <Page component={LicencasContratoForm} />,
  },
  {
    path: "/app/contrato/:contratoId/cliente/:clienteId/processos/:processoId/procadmin/:procadmin/licencas/:licencaId/deletar",
    element: <Page component={LicencasContratoForm} />,
  },
  {
    path: "/app/novo-processo-admin",
    element: (
      <ProtectedRoute
        component={ProcessosAdministrativosForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/orgaos",
    element: (
      <ProtectedRoute
        component={OrgaosList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-orgao",
    element: (
      <ProtectedRoute
        component={OrgaoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-orgao/:id",
    element: (
      <ProtectedRoute
        component={OrgaoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-tipo-orcamento",
    element: (
      <ProtectedRoute
        component={TipoOrcamentoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-tipo-orcamento",
    element: (
      <ProtectedRoute
        component={TipoOrcamentoList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-tipo-orcamento/:id",
    element: (
      <ProtectedRoute
        component={TipoOrcamentoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-setor",
    element: (
      <ProtectedRoute
        component={SetoresForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-setores",
    element: (
      <ProtectedRoute
        component={SetoresList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-setor/:id",
    element: (
      <ProtectedRoute
        component={SetoresForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-tipo-contrato",
    element: (
      <ProtectedRoute
        component={TipoContratoList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-tipo-contrato",
    element: (
      <ProtectedRoute
        component={TipoContratoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-tipo-contrato/:id",
    element: (
      <ProtectedRoute
        component={TipoContratoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-status-servicos/",
    element: (
      <ProtectedRoute
        component={StatusServicoList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-status-servico/",
    element: (
      <ProtectedRoute
        component={StatusServicoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-status-servico/:id",
    element: (
      <ProtectedRoute
        component={StatusServicoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/admin/licencas/",
    element: (
      <ProtectedRoute
        component={AlvaraLicencaList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/admin/nova-licenca/",
    element: (
      <ProtectedRoute
        component={AlvaraLicencaForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/admin/editar-licenca/:id",
    element: (
      <ProtectedRoute
        component={AlvaraLicencaForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-tipo-unidade/",
    element: (
      <ProtectedRoute
        component={TipoUnidadeList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-tipo-unidade/",
    element: (
      <ProtectedRoute
        component={TipoUnidadeForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-tipo-unidade/:id",
    element: (
      <ProtectedRoute
        component={TipoUnidadeForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/listar-alvara-licenca-complementar",
    element: (
      <ProtectedRoute
        component={AlvaraLicencaComplementarList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-alvara-licenca-complementar",
    element: (
      <ProtectedRoute
        component={AlvaraLicencaComplementarForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-alvara-licenca-complementar/:id",
    element: (
      <ProtectedRoute
        component={AlvaraLicencaComplementarForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/contrato/:contratoId/financeiro",
    element: <Page component={ServicoFinanceiro} />
  },
  {
    path: "/app/listar-grupo-restricao/",
    element: (
      <ProtectedRoute
        component={GrupoRestricaoList}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/novo-grupo-restricao/",
    element: (
      <ProtectedRoute
        component={GrupoRestricaoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/editar-grupo-restricao/:id",
    element: (
      <ProtectedRoute
        component={GrupoRestricaoForm}
        permission={"Administrativo"}
        requiredRole={"read"}
      />
    ),
  },
  {
    path: "/app/dashboard",
    element: <Page component={Dashboard} />
  },
  {
    path: "/app/licencas",
    element: <Page component={Licencas} />
  },
  {
    path: "/app/nova-licenca",
    element: <Page component={LicencaForm} />
  },
  {
    path: "/app/editar-licenca/:id",
    element: <Page component={LicencaForm} />
  },
  {
    path: "/app/licenca/:id",
    element: <Page component={LicencasDetails} />
  },
  {
    path: "/app/unidades",
    element: <Page component={Unidades} />
  },
  {
    path: "/app/nova-unidade",
    element: <Page component={UnidadeForm} />
  },
  {
    path: "/app/unidade/:id",
    element: <Page component={UnidadeDetails} />
  },
  {
    path: "/app/editar-unidade/:id",
    element: <Page component={UnidadeForm} />
  },
  {
    path: "/app/condicionantes",
    element: <Page component={CondicionantesList} />
  },
  {
    path: "/app/nova-condicionante/:unidadeId",
    element: <Page component={CondicionantesForm} />
  },
  {
    path: "/app/editar-condicionante/:unidadeId/:condicionanteId",
    element: <Page component={CondicionantesForm} />
  },
  {
    path: "/app/nova-condicionante-licenca/:licencaId/",
    element: <Page component={CondicionanteLicencaForm} />
  },
  {
    path: "/app/editar-condicionante-licenca/:licencaId/:condicionanteId",
    element: <Page component={CondicionanteLicencaForm} />
  },
  {
    path: "/app/caixa-entrada",
    element: <Page component={CaixaEntradaList} />
  },
  {
    path: "/app/financeiro",
    element: <Page component={FinanceiroList} />
  },
  {
    path: '/pdf/contrato/',
    element: <Page component={PDFContratos} />
  },
  {
    path: '/pdf/paisagem/contrato/',
    element: <Page component={PDFPaisagem} />
  }
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
