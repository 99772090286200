import React from "react";

const FixoRenovacaoIndividualPDF = ({ contrato }) => {
  return (
    <table
      className="tabela-contratos"
      style={{ width: "100mm", tableLayout: "auto" }}
    >
      {contrato && contrato.unidades && contrato.unidades.length > 0 ? (
        contrato.unidades.map((unidade) =>
          unidade.imoveis.map((imovel) =>
            imovel.processos_contratos
              .filter((processo) => processo.servicos && processo.servicos.length > 0) // Filtrando processos com serviços
              .map((processo, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td
                      colSpan={5}
                      style={{ background: "#024900", color: "white" }}
                    >
                      Processo Administrativo:{" "}
                      {processo.processo_administrativo?.descricao}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={5}
                      style={{ background: "#0B9300", color: "white" }}
                    >
                      Imóvel: {imovel?.descricao}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ background: "#2BC900", color: "white" }}>
                      Contrato
                    </td>
                    <td style={{ background: "#2BC900", color: "white" }}>
                      Tarefa
                    </td>
                    <td style={{ background: "#2BC900", color: "white" }}>
                      Quantidade
                    </td>
                  </tr>
                  {processo.servicos.map((servico, servicoIndex) => (
                    <tr key={servicoIndex}>
                      <td>{contrato?.descricao}</td>
                      <td>{servico?.tarefas_servico[0]?.descricao ?? "N/I"}</td>
                      <td>{servico?.quantidade}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
          )
        )
      ) : (
        <tr>
          <td colSpan={1}>Nenhum contrato disponível</td>
        </tr>
      )}
    </table>
  );
};

export default FixoRenovacaoIndividualPDF;
