import {
  Box,
  Chip,
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import moment from "moment";

const ContratoHeader = ({
  descricao,
  elaboradores,
  dataCriacao,
  dataAprovacao,
  statusOrcamentos,
  statusOrcamentoId,
  handleAtualizarStatusOrcamentario,
  contratoId,
  cliente,
}) => {
  return (
    <Box style={{ width: "100%" }}>
      <Paper elevation={2} style={{ padding: 20 }}>
        <Box display={"inline-flex"} gap={2}>
          <Typography variant={"h1"}>Contrato: </Typography>
          <Chip color="success" label={descricao} />
        </Box>
        <Box my={1}>
          <ListItem>
            <ListItemIcon>
              <PersonIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h4">
                <span style={{ fontWeight: 500 }}>Elaborador (es): </span>
                {elaboradores?.map((elaborador) => (
                  <>
                    {elaborador?.usuario?.name}
                    {", "}
                  </>
                ))}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </Box>
        <Box my={1}>
          <ListItem>
            <ListItemIcon>
              <CalendarMonthIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h4">
                <span style={{ fontWeight: 500 }}>Data de Elaboração:</span>{" "}
                {moment(dataCriacao).format("DD/MM/YYYY")}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </Box>
        {dataAprovacao ? (
          <Box my={1}>
            <ListItem>
              <ListItemIcon>
                <CalendarMonthIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="h4">
                  <span style={{ fontWeight: 500 }}>Data de Aprovação:</span>{" "}
                  {dataAprovacao
                    ? moment(dataAprovacao).format("DD/MM/YYYY")
                    : null}
                </Typography>
              </ListItemText>
            </ListItem>
            <Divider />
          </Box>
        ) : null}

        <Box my={1}>
          <ListItem>
            <ListItemIcon>
              <AttachMoneyIcon fontSize="medium" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h4">
                <span style={{ fontWeight: 500 }}>Status Orçamentário:</span>{" "}
                <Select
                  value={statusOrcamentoId}
                  onChange={(event) => {
                    const statusId = event.target.value;
                    handleAtualizarStatusOrcamentario(contratoId, statusId);
                  }}
                >
                  {statusOrcamentos &&
                    statusOrcamentos.length > 0 &&
                    statusOrcamentos.map((status) => (
                      <MenuItem value={status.id}>{status.descricao}</MenuItem>
                    ))}
                </Select>
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
        </Box>
        <Box my={1}>
          <Stack width="100%" gap={4} direction="row" alignItems={"center"}>
            <Box>
              <Typography variant="h4" fontWeight={500}>
                Dados do Cliente:
              </Typography>
            </Box>
            <Stack direction="column" gap={1}>
              <Typography variant="h5" fontWeight={500}>
                Nome:
              </Typography>
              <Typography>{cliente?.nome}</Typography>
            </Stack>
            <Stack direction="column" gap={1}>
              <Typography variant="h5" fontWeight={500}>
                Tipo pessoa:
              </Typography>
              <Typography>
                {cliente?.tipo === "fisica" ? "Física" : "Jurídica"}
              </Typography>
            </Stack>
            <Stack direction="column" gap={1}>
              <Typography variant="h5" fontWeight={500}>
                {cliente?.tipo_pessoa === "fisica" ? "CPF:" : "CNPJ:"}
              </Typography>
              <Typography>
                {cliente?.tipo_pessoa === "fisica"
                  ? cliente?.cpf
                  : cliente?.cnpj}
              </Typography>
            </Stack>
            <Stack direction="column" gap={1}>
              <Typography variant="h5" fontWeight={500}>
                E-mail:
              </Typography>
              <Typography>{cliente?.email}</Typography>
            </Stack>
            <Stack direction="column" gap={1}>
              <Typography variant="h5" fontWeight={500}>
                Telefone:
              </Typography>
              <Typography>{cliente?.telefone ?? "N/I"}</Typography>
            </Stack>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};

export default ContratoHeader;
