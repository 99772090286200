import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { useJumboTheme } from "@jumbo/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  ListItem,
  List,
  ListItemText,
  ListItemAvatar,
  MenuItem,
  Alert,
  InputLabel,
  Breadcrumbs,
  Tooltip,
  Slider,
  RadioGroup,
  Radio,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Select from "react-select";
import { Select as SelectMUI } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import moment from "moment";
import { PermissionContext } from "app/contexts/PermissionContext";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Article, MedicalServicesOutlined } from "@mui/icons-material";
import { v4 } from "uuid";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const protocoloSchema = yup.object({
  orgao_id: yup.number().required("Campo obrigatório"),
  status: yup.number().required("Campo obrigatório"),
  data: yup.date().required("Campo obrigatório").typeError("Data inválida"),
  prazo: yup.date().required("Campo obrigatório").typeError("Data inválida"),
  processo_contrato_id: yup.number().required("Campo obrigatório"),
});

const ProtocolosForm = () => {
  const userId = Number(localStorage.getItem("id"));
  const roleId = Number(localStorage.getItem("role_id"));

  const { id, contratoId, processoId, clienteId, protocoloId, procadmin } =
    useParams();
  const initialValues = {
    orgao_id: "",
    data: null,
    prazo: null,
    status: 0,
    processo_contrato_id: Number(processoId),
  };
  const { theme } = useJumboTheme();
  const formRef = useRef();
  const colaboradorId = Number(localStorage.getItem("id"));
  const [protocolo, setProtocolo] = useState(initialValues);
  const [dadosCarregados, setDadosCarregados] = useState(false);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [orgaos, setOrgaos] = useState([]);
  const [tiposServicos, setTiposServicos] = useState([]);
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [statusOrcamentos, setStatusOrcamentos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [tipoOrcamentos, setTiposOrcamentos] = useState([]);
  const [tiposContrato, setTiposContrato] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [setores, setSetores] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [isContratoFixo, setIsContratoFixo] = useState(false);
  const [
    valoresFinaisServicosCadastrados,
    setValoresFinaisServicosCadastrados,
  ] = useState([]);
  const [
    valoresFinaisServicosNaoCadastrados,
    setValoresFinaisNaoServicosCadastrados,
  ] = useState([]);
  const [clienteIdSelecionado, setClienteIdSelecionado] = useState(null);
  const [modalAberto, setModalAberto] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const Swal = useSwalWrapper();
  const location = useLocation();
  const [valorServico, setValorServico] = useState(0);

  const [novoNomeCliente, setNovoNomeCliente] = useState("");
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const handleOpenModal = () => {
    setModalAberto(true);
  };

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getProtocolo = useCallback(async () => {
    try {
      const response = await ApiService.get(`/protocolos/${protocoloId}`);
      if (response.status === 200) {
        setProtocolo({
          ...response.data,
          data: moment.utc(response.data?.data).format("YYYY-MM-DD"),
          prazo: moment.utc(response.data?.prazo).format("YYYY-MM-DD"),
        });
      }
    } catch (error) {
      console.log(error);
    }
  });

  const getOrgaos = useCallback(async () => {
    try {
      await ApiService.get("/orgaos/all").then((response) => {
        setOrgaos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposServicos = useCallback(async () => {
    try {
      await ApiService.get("/tiposservico").then((response) => {
        setTiposServicos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getProcessosAdministrativos = useCallback(async () => {
    try {
      await ApiService.get("/processosadmin/all").then((response) => {
        setProcessosAdministrativos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTarefas = useCallback(async () => {
    try {
      await ApiService.get("/tarefas/all").then((response) => {
        setTarefas(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getStatusOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/statusorcamento").then((response) => {
        setStatusOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const filteredStatusOrcamentos = statusOrcamentos?.filter(
    (status) =>
      String(status.descricao).toLowerCase() !== "finalizado" &&
      String(status.descricao).toLowerCase() !== "finalizado contrato" &&
      String(status.descricao).toLowerCase() !== "finalizado serviço"
  );

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    if (protocoloId) {
      const newValues = {
        ...values,
        processo_contrato_id: Number(processoId),
      };

      delete newValues.servico_contrato_id;
      delete newValues.tarefas_servico;
      delete newValues.contratoId;

      delete newValues.Cliente;
      delete newValues.cliente_id;
      delete newValues.tipo_orcamento;
      delete newValues.elaborador_orcamento;
      delete newValues.Imovel;
      delete newValues.id;
      delete newValues.createdAt;
      delete newValues.updatedAt;
      delete newValues.deletedAt;
      delete newValues.colaborador_que_criou_id;

      try {
        await ApiService.put(`/protocolos/${protocoloId}`, newValues).then(
          (response) => {
            if (response.status === 200) {
              toast("success", "Atualizado com sucesso!");
              navigate(
                `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos`
              );
            }
          }
        );
      } catch (error) {
        console.log(error);
        toast("error", "Ocorreu um erro");
      }
    } else {
      const newValues = {
        ...values,
      };
      try {
        await ApiService.post("/protocolos", newValues)
          .then((response) => {
            if (response.status === 201) {
              toast("success", "Criado com sucesso");
              navigate(
                `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos`
              );
              resetForm();
            }
          })
          .catch((error) => {
            console.log(error);
            let message = error;
            toast("error", message);

            if (error.response.data) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      } catch (error) {
        console.log(error);
        toast("error", "Ocorreu um erro");
      }
    }
  };

  const handleDeleteProtocolo = useCallback(async (id) => {
    try {
      ApiService.delete(`/protocolos/${protocoloId}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso!");
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos`
            );
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (novoNomeCliente !== "") {
      handleOpenModal();
    }
  }, [novoNomeCliente]);

  const handleCreateOrgao = useCallback(async (descricao) => {
    const response = await ApiService.post("/orgaos", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getOrgaos();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const modalAlertProtocolo = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o protocolo?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleDeleteProtocolo(id);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (protocoloId) {
        getProtocolo();
      }
      await Promise.all([getOrgaos()]);
      setDadosCarregados(true);
    };
    fetchData();
    if (location && String(location.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [id]);

  //   useEffect(() => {
  //     if (dadosCarregados && protocolo) {
  //       const temPermissaoDeAcessarAPagina = hasPermission("Contratos", "read");
  //       const contratoCriadoPeloUsuarioAtual =
  //         contrato.colaborador_que_criou_id === userId;
  //       console.log(servico.colaborador_que_criou_id, userId);
  //       if (!temPermissaoDeAcessarAPagina && !contratoCriadoPeloUsuarioAtual) {
  //         navigate("/app");
  //       }
  //     }
  //     if (location && String(location?.pathname).includes("deletar")) {
  //       setIsDeleteMode(true);
  //     }
  //   }, [dadosCarregados, servico]);

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}&protocoloId=${protocoloId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <>
      {dadosCarregados && (
        <JumboContentLayout layoutOptions={layoutOptions}>
          {lg && <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }} />}
          <Stack width={"100%"}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                to={"/app/listar-contratos"}
                color="success"
                style={linkStyle}
              >
                Contratos
              </Link>
              {breadCrumbsInfo && (
                <Link
                  to={`/app/contrato/${contratoId}/detalhes`}
                  color="success"
                  style={linkStyle}
                >
                  {breadCrumbsInfo?.descricao}
                </Link>
              )}
              <Link
                to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
                color="primary"
                style={linkStyle}
              >
                Processos Administrativos
              </Link>
              {breadCrumbsInfo && (
                <Link
                  to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/editar/${processoId}`}
                  color="primary"
                  style={linkStyle}
                >
                  {
                    breadCrumbsInfo?.processos_contratos?.[0]
                      ?.processo_administrativo?.descricao
                  }{" "}
                </Link>
              )}
              <Link
                to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos`}
                color="primary"
                style={linkStyle}
              >
                Protocolos
              </Link>
              {breadCrumbsInfo && (
                <Link
                  to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos/editar/${protocoloId}`}
                  color="success"
                  style={linkStyle}
                >
                  {`Protocolo ID - ${breadCrumbsInfo?.processos_contratos?.[0]?.protocolos?.[0]?.id}`}
                </Link>
              )}
              <Link
                to={
                  protocoloId
                    ? `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos/editar/${protocoloId}`
                    : `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos/novo`
                }
                color="primary"
                style={linkStyle}
              >
                {protocoloId ? "Editar" : "Novo"} Protocolo
              </Link>
            </Breadcrumbs>
          </Stack>

          {isDeleteMode && (
            <Stack width={"100%"} p={1}>
              <Alert severity="error">
                Você está prestes a excluir o (s) protocolo (s) abaixo:
              </Alert>
            </Stack>
          )}

          <Paper sx={{ p: "15px" }}>
            <Formik
              innerRef={formRef}
              initialValues={protocolo}
              validationSchema={protocoloSchema}
              enableReinitialize
              validateOnChange
              validateOnBlur
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleBlur,
                isSubmitting,
                handleChange,
                setFieldValue,
                errors,
              }) => (
                <Form style={{ width: "100%" }}>
                  <Grid container alignContent={"center"} spacing={2}>
                    <Grid item lg={12}>
                      <Divider />

                      <>
                        <Card sx={{ mb: 1, p: 2 }} style={{ width: "100%" }}>
                          <Grid container alignContent={"center"} spacing={2}>
                            <Grid item lg={6} xs={12}>
                              <Typography my={2}>Órgão</Typography>
                              <div style={{ position: "relative" }}>
                                <CreatableSelect
                                  isClearable
                                  styles={customStyles}
                                  isDisabled={isDeleteMode}
                                  name={`orgao_id`}
                                  onChange={(option) => {
                                    setFieldValue(
                                      `orgao_id`,
                                      option ? option.id : ""
                                    );
                                  }}
                                  onCreateOption={async (value) => {
                                    const { id } = await handleCreateOrgao(
                                      value
                                    );
                                    setFieldValue(`orgao_id`, id);
                                  }}
                                  options={orgaos}
                                  getOptionLabel={(option) =>
                                    option.__isNew__
                                      ? option.label
                                      : option.descricao
                                  }
                                  value={orgaos.find(
                                    (orgao) => orgao.id === values.orgao_id
                                  )}
                                  placeholder={"Selecione um Órgão"}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name={`orgao_id`}
                                  style={{ color: "red" }}
                                />
                              </div>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <Typography my={2}>Data:</Typography>
                              <TextField
                                name="data"
                                type="date"
                                disabled={isDeleteMode}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                                value={values.data}
                              />
                              <ErrorMessage
                                component={"div"}
                                name={`data`}
                                style={{ color: "red" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} my={1}>
                            <Grid item lg={6}>
                              <Typography my={1}>Prazo</Typography>
                              <TextField
                                name="prazo"
                                type="date"
                                disabled={isDeleteMode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.prazo}
                                variant="outlined"
                                fullWidth
                              />
                              <ErrorMessage
                                component={"div"}
                                name={`prazo`}
                                style={{ color: "red" }}
                              />
                            </Grid>
                            <Grid item lg={6}>
                              <Typography my={1}>Status</Typography>
                              <TextField
                                name="status"
                                type="number"
                                disabled={isDeleteMode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.status}
                                variant="outlined"
                                fullWidth
                              />
                              <ErrorMessage
                                component={"div"}
                                name={`status`}
                                style={{ color: "red" }}
                              />
                            </Grid>
                          </Grid>
                        </Card>
                      </>
                    </Grid>

                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      marginTop={3}
                    >
                      {isDeleteMode ? (
                        <Stack direction="row" gap={2} display={"flex"}>
                          <Box>
                            <Button
                              color="error"
                              variant="contained"
                              onClick={() => {
                                modalAlertProtocolo(processoId);
                              }}
                            >
                              Excluir protocolo (s)
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/protocolos`
                                );
                              }}
                            >
                              Cancelar
                            </Button>
                          </Box>
                        </Stack>
                      ) : (
                        <LoadingButton
                          loading={isSubmitting}
                          type="submit"
                          style={{ margin: "auto", display: "block" }}
                          variant="contained"
                          color="primary"
                        >
                          {protocoloId ? "Atualizar" : "Criar"}
                        </LoadingButton>
                      )}
                    </Box>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </JumboContentLayout>
      )}
    </>
  );
};
export default ProtocolosForm;
