import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
  InputAdornment,
  Card,
  Badge,
  Chip,
  PaginationItem,
  Paper,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReplayIcon from "@mui/icons-material/Replay";
import StarIcon from "@mui/icons-material/Star";
import { gerarPDF } from "app/utils/pdfGenerator";
import { PermissionContext } from "app/contexts/PermissionContext";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-dates";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import gerarPDFContratosFinanceiro from "app/utils/pdfs/gerarPDFContratosFinanceiro";

export const FinanceiroList = () => {
  const [contratos, setContratos] = useState([]);
  const [valorTotalContratos, setValorTotalContratos] = useState(0);
  const [selectedContratosIds, setSelectedContratosIds] = useState([]);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("desc");
  const [searchTerm, setSearchTerm] = useState("");
  const [filtroSelecionarContratos, setFiltroSelecionarContratos] =
    useState("todos");
  const [filtroSituacaoContratos, setFiltroSituacaoContratos] =
    useState("padrao");
  const [tipoOrcamentoSelecionado, setTipoOrcamentoSelecionado] =
    useState("selecionar");
  const [count, setCount] = useState("");
  const { theme } = useJumboTheme();
  const [expand, setExpand] = useState(false);
  const [todosContratosSelecionados, setTodosContratosSelecionados] =
    useState(false);
  const [mostrarBotaoDeletarMuitos, setMostrarBotaoDeletarMuitos] =
    useState(false);
  const [exportarParaPDFSelecionado, setExportarParaPDFSelecionado] =
    useState("selecionar");
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const userId = Number(localStorage.getItem("id"));
  const roleId = Number(localStorage.getItem("role_id"));
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [statusOrcamento, setStatusOrcamento] = useState([]);
  const [colaboradores, setcolaboradores] = useState([]);
  const [colaboradorSelecionado, setColaboradorSelecionado] =
    useState("selecionar");
  const [statusOrcamentarioSelecionado, setStatusOrcamentarioSelecionado] =
    useState("selecionar");
  const [periodoPesquisa, setPeriodoPesquisa] = useState("selecionar");

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Financeiro Contratos", "read")) {
    navigate("/app");
  }

  let filtros = {
  
  };
  
  if(filtroSelecionarContratos !== "todos") {
    filtros = {
      ...filtros,
      tipoContrato: filtroSelecionarContratos,
    }
  }

  if (filtroSituacaoContratos !== "padrao") {
    filtros = {
      ...filtros,
      situacao: filtroSituacaoContratos,
    };
  }
  
  if (statusOrcamentarioSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      statusOrcamento: statusOrcamentarioSelecionado,
    };
  }

  if(colaboradorSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      elaborador: colaboradorSelecionado,
    }
  }

  if(tipoOrcamentoSelecionado !== "selecionar") {
    filtros = {
      ...filtros,
      tipoOrcamento: tipoOrcamentoSelecionado
    }
  }

  if (periodoPesquisa !== "selecionar") {
    filtros = {
      ...filtros,
      periodo: periodoPesquisa,
      startDate: moment.utc(startDate).format('YYYY-MM-DD'),
      endDate: moment.utc(endDate).format('YYYY-MM-DD'),
    };
  }

  filtros.searchTerm = searchTerm;

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSearch = async (newSearchTerm) => {
    if (searchTerm === "" || searchTerm === null || searchTerm === undefined) {
      setPage(1);
    }
    setSearchTerm(newSearchTerm);
    setPage(0);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const getStatusOrcamento = async () => {
    try {
      await ApiService.get("/statusorcamento").then((response) => {
        if (response.status === 200) {
          setStatusOrcamento(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getColaboradores = async () => {
    try {
      await ApiService.get(
        "/users/colaboradores?filtroRoles=financeiro_admin"
      ).then((response) => {
        if (response.status === 200) {
          setcolaboradores(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportarContratosParaPDF = () => {
    let url = `/pdf/contrato?formatoContrato=financeiro`;
    if (filtroSelecionarContratos === "selecionar") {
      setExportarParaPDFSelecionado("selecionar");
      return;
    }

    if (filtroSelecionarContratos !== "todos") {
      url += `?tipoContrato=${filtroSelecionarContratos}`;
    }

    if (filtroSituacaoContratos !== "padrao") {
      url += `?situacao=${filtroSituacaoContratos}`;
    }

    if (tipoOrcamentoSelecionado !== "selecionar") {
      let tipo = "";
      switch (tipoOrcamentoSelecionado) {
        case 1:
          tipo = "agronegocio";
          break;
        case 2:
          tipo = "mineracao";
          break;
        case 3:
          tipo = "postos";
          break;
        case 4:
          tipo = "servicos";
          break;
        default:
          tipo = "todos";
      }
      url += `?tipoOrcamento=${tipo}`;
    }

    if (periodoPesquisa !== "selecionar" && startDate && endDate) {
      url += `?periodo=${periodoPesquisa}&dataInicio=${startDate}&dataFim=${endDate}`;
    }

    if (statusOrcamentarioSelecionado !== "selecionar") {
      let idStatusOrcamento = null;
      switch (statusOrcamentarioSelecionado) {
        case 1:
          idStatusOrcamento = "aprovado";
          break;
        case 2:
          idStatusOrcamento = "nao_aprovado";
          break;
        case 3:
          idStatusOrcamento = "finalizado";
          break;
        case 5:
          idStatusOrcamento = "aguardando_resposta";
          break;
        case 7:
          idStatusOrcamento = "finalizado_completo";
          break;
        case 8:
          idStatusOrcamento = "cancelado";
          break;
        default:
          idStatusOrcamento = "aprovado";
      }
      url += `?status=${idStatusOrcamento}`;
    }

    if (colaboradorSelecionado !== "selecionar") {
      url += `?elaborador=${colaboradorSelecionado}`;
    }

    url = url.replace(/\?(?=.*=)/g, (match, offset) =>
      offset === url.indexOf("?") ? "?" : "&"
    );

    // navigate(`/pdf/contrato?tipoContrato=${tipoContrato}`);
    window.open(url, "_blank");

    // `/pdf/contrato?tipoContrato=fixoIndividual&contratoId=${contrato.id}`
    // gerarPDF(tipoContrato);
  };

  const handleLimparFiltros = () => {
    setPage(0);
    setPerPage(10);
    setSortBy("id");
    setSortOrder("desc");
    setSearchTerm("");
    setFiltroSelecionarContratos("todos");
    setFiltroSituacaoContratos("padrao");
    setStatusOrcamentarioSelecionado("selecionar");
    setColaboradorSelecionado("selecionar");
    setStartDate(null);
    setEndDate(null);
    setPeriodoPesquisa("selecionar");
    setTipoOrcamentoSelecionado("selecionar");
  };

  const getContratos = useCallback(async () => {
    try {
      let apiUrl = `/contratos/financeiro?page=${
        page + 1
      }&perPage=${perPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&tipoContrato=${filtroSelecionarContratos}&userId=${userId}&roleId=${roleId}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      if(filtroSituacaoContratos !== "padrao") {
        apiUrl += `&situacao=${filtroSituacaoContratos}`;
      }

      if (startDate && endDate) {
        apiUrl += `&dataInicio=${moment(startDate._d).format(
          "YYYY-MM-DD"
        )}&dataFim=${moment(endDate._d).format("YYYY-MM-DD")}`;
      }

      if (statusOrcamentarioSelecionado !== "selecionar") {
        apiUrl += `&statusOrcamento=${statusOrcamentarioSelecionado}`;
      }

      if (colaboradorSelecionado && colaboradorSelecionado !== "selecionar") {
        apiUrl += `&idElaborador=${colaboradorSelecionado}`;
      }

      if (periodoPesquisa !== "selecionar") {
        apiUrl += `&periodo=${periodoPesquisa}`;
      }

      if (tipoOrcamentoSelecionado !== "selecionar") {
        apiUrl += `&tipoOrcamento=${Number(tipoOrcamentoSelecionado)}`;
      }

      ApiService.get(apiUrl)
        .then((response) => {

          let valorTotalDeTodosContratos = 0;
          const contratosComValorTotal = response.data.servicos.map(
            (contrato) => {
              const valorTotal =
            contrato?.renegociacoescontrato?.length > 0
              ? Number(contrato.renegociacoescontrato[0].valor)
              : contrato?.servicos?.reduce(
                  (total, servico) => total + Number(servico.quantidade) * Number(servico.valor),
                  0
                );
              
                valorTotalDeTodosContratos += valorTotal;
              
              const pagamentoRecebido = contrato.pagamentoscontrato.reduce(
                (total, pagamento) => {
                  return total + pagamento.valor;
                },
                0
              );

              return {
                ...contrato,
                valorTotal,
                pagamentoRecebido,
                isChecked: false,
              };
            }
          );

          setValorTotalContratos(valorTotalDeTodosContratos);
          setContratos(contratosComValorTotal);
          setCount(response.data.total);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [
    page,
    perPage,
    sortBy,
    sortOrder,
    searchTerm,
    filtroSelecionarContratos,
    filtroSituacaoContratos,
    statusOrcamentarioSelecionado,
    startDate,
    endDate,
    colaboradorSelecionado,
    periodoPesquisa,
    tipoOrcamentoSelecionado,
  ]);

  const getValorTotalContratos = async () => {
    try {
      let apiUrl = `/contratos/soma/valor/total?tipoContrato=${filtroSelecionarContratos}&situacao=${filtroSituacaoContratos}&userId=${userId}&roleId=${roleId}`;

      if (searchTerm) {
        apiUrl += `&searchTerm=${searchTerm}`;
      }

      if (startDate && endDate) {
        apiUrl += `&dataInicio=${moment(startDate._d).format(
          "YYYY-MM-DD"
        )}&dataFim=${moment(endDate._d).format("YYYY-MM-DD")}`;
      }

      if (statusOrcamentarioSelecionado !== "selecionar") {
        apiUrl += `&statusOrcamento=${statusOrcamentarioSelecionado}`;
      }

      if (colaboradorSelecionado && colaboradorSelecionado !== "selecionar") {
        apiUrl += `&idElaborador=${colaboradorSelecionado}`;
      }

      if (periodoPesquisa !== "selecionar") {
        apiUrl += `&periodo=${periodoPesquisa}`;
      }

      if (tipoOrcamentoSelecionado !== "selecionar") {
        apiUrl += `&tipoOrcamento=${Number(tipoOrcamentoSelecionado)}`;
      }

     
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getContratos();
    getStatusOrcamento();
    getColaboradores();
  }, [getContratos]);

  useEffect(() => {
    getValorTotalContratos();
  }, [
    valorTotalContratos,
    startDate,
    endDate,
    statusOrcamentarioSelecionado,
    periodoPesquisa,
    searchTerm,
    tipoOrcamentoSelecionado,
  ]);

  return (
    <JumboContentLayout
      header={<PageHeader title={"Contratos - Financeiro"} />}
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <React.Fragment>
          <Grid
            container
            p={3}
            mt={3}
            gap={3}
            style={{ background: "white", minHeight: "120px" }}
          >
            <Grid item>
              <Box mt={2} mb={2}>
                <TextField
                  label="Buscar"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleSearch(searchTerm)}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Selecionar:</InputLabel>
              <Select
                id="selecionar-contratos-select"
                value={filtroSelecionarContratos}
                onChange={(event) => {
                  const value = event.target.value;
                  setFiltroSelecionarContratos(value);
                }}
              >
                <MenuItem value={"todos"}>Todos os contratos</MenuItem>
                <MenuItem value={"fixo"}>Contratos Fixos</MenuItem>
                <MenuItem value={"renovacao"}>Contratos de Renovação</MenuItem>
              </Select>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Situação:</InputLabel>
              <Select
                id="situacao-contratos-select"
                value={filtroSituacaoContratos}
                onChange={(event) => {
                  const value = event.target.value;
                  setFiltroSituacaoContratos(value);
                }}
              >
                <MenuItem value={"padrao"}>Contratos Padrão</MenuItem>
                <MenuItem value={"aguardando_analise"}>
                  Contratos Aguardando Análise
                </MenuItem>
              </Select>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Status Orçamentário:</InputLabel>
              <Select
                id="status-orcamentario-contratos-select"
                value={statusOrcamentarioSelecionado}
                onChange={(event) => {
                  const value = event.target.value;
                  setStatusOrcamentarioSelecionado(value);
                }}
              >
                <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                {statusOrcamento &&
                  statusOrcamento.length > 0 &&
                  statusOrcamento.map((status) => (
                    <MenuItem value={status.id}>{status.descricao}</MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Elaborador:</InputLabel>
              <Select
                id="elaborador-contratos-select"
                value={colaboradorSelecionado}
                onChange={(event) => {
                  const value = event.target.value;
                  setColaboradorSelecionado(value);
                }}
              >
                <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                {colaboradores &&
                  colaboradores.length > 0 &&
                  colaboradores.map((colaborador) => (
                    <MenuItem value={colaborador.id}>
                      {colaborador.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Tipo de Orçamento:</InputLabel>
              <Select
                id="elaborador-contratos-select"
                value={tipoOrcamentoSelecionado}
                onChange={(event) => {
                  const value = event.target.value;
                  setTipoOrcamentoSelecionado(value);
                }}
              >
                <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                <MenuItem value={1}>Agronegócio</MenuItem>
                <MenuItem value={2}>Mineração</MenuItem>
                <MenuItem value={3}>Postos de Combustíveis</MenuItem>
                <MenuItem value={4}>Prestação de Serviços</MenuItem>
              </Select>
            </Grid>
            <Grid item lg={4} my={-0.8}>
              <Typography>Filtrar por período</Typography>
              <FormControl
                fullWidth
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Box>
                  <Select
                    value={periodoPesquisa ?? "selecionar"}
                    onChange={(event) => {
                      setPeriodoPesquisa(event.target.value);
                    }}
                    fullWidth
                  >
                    <MenuItem value={"selecionar"}>Selecionar</MenuItem>
                    <MenuItem value="createdAt">Data de Criação</MenuItem>
                    <MenuItem value="data_aprovacao">
                      Data de Aprovação
                    </MenuItem>
                  </Select>
                </Box>
                <Box>
                  <DateRangePicker
                    small={true}
                    startDate={startDate}
                    startDatePlaceholderText="Data inicial"
                    startDateId="data_inicio"
                    endDate={endDate}
                    endDateId="data_final"
                    endDatePlaceholderText="Data final"
                    onDatesChange={handleDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={handleFocusChange}
                    isOutsideRange={() => false}
                  />
                </Box>
              </FormControl>
            </Grid>
            <Grid item my={-0.6}>
              <InputLabel>Filtros:</InputLabel>
              <Button onClick={handleLimparFiltros} variant="outlined">
                Limpar Filtros
              </Button>
            </Grid>

            <Box display="flex" width="100%" justifyContent="flex-end">
              <Tooltip title="Exportar para PDF">
                <PictureAsPdfIcon
                  cursor="pointer"
                  sx={{ color: "#F30744", marginLeft: 4 }}
                  onClick={() => {
                    gerarPDFContratosFinanceiro(filtros);
                    // handleExportarContratosParaPDF();
                    // let url = `/pdf/contrato?tipoContrato=financeiro`;

                    // if (searchTerm) {
                    //   url += `&searchTerm=${searchTerm}`;
                    // }

                    // if (startDate && endDate) {
                    //   url += `&dataInicio=${moment(startDate._d).format(
                    //     "YYYY-MM-DD"
                    //   )}&dataFim=${moment(endDate._d).format("YYYY-MM-DD")}`;
                    // }

                    // if (statusOrcamentarioSelecionado !== "selecionar") {
                    //   let idStatusOrcamento = null;
                    //   switch (statusOrcamentarioSelecionado) {
                    //     case 1:
                    //       idStatusOrcamento = "aprovado";
                    //       break;
                    //     case 2:
                    //       idStatusOrcamento = "nao_aprovado";
                    //       break;
                    //     case 3:
                    //       idStatusOrcamento = "finalizado";
                    //       break;
                    //     case 5:
                    //       idStatusOrcamento = "aguardando_resposta";
                    //       break;
                    //     case 7:
                    //       idStatusOrcamento = "finalizado_completo";
                    //       break;
                    //     case 8:
                    //       idStatusOrcamento = "cancelado";
                    //       break;
                    //     default:
                    //       idStatusOrcamento = "aprovado";
                    //   }
                    //   url += `&statusOrcamento=${idStatusOrcamento}`;
                    // }

                    // if (colaboradorSelecionado && colaboradorSelecionado !== "selecionar") {
                    //   const colaborador = colaboradores.find(c => c.id === colaboradorSelecionado)?.name;

                    //   url += `&elaborador=${colaborador}`;
                    // }

                    // if (periodoPesquisa !== "selecionar") {
                    //   url += `&periodo=${periodoPesquisa}`;
                    // }

                    // if (tipoOrcamentoSelecionado !== "selecionar") {
                    //   let tipo = "";
                    //   switch (tipoOrcamentoSelecionado) {
                    //     case 1:
                    //       tipo = "agronegocio";
                    //       break;
                    //     case 2:
                    //       tipo = "mineracao";
                    //       break;
                    //     case 3:
                    //       tipo = "postos";
                    //       break;
                    //     case 4:
                    //       tipo = "servicos";
                    //       break;
                    //     default:
                    //       tipo = "todos";
                    //   }
                    //   url += `&tipoOrcamento=${tipo}`;
                    // }

                    // window.open(url, "_blank");
                  }}
                />
              </Tooltip>
            </Box>
            {contratos.length > 0 ? (
              <>
                {contratos.map((contrato, key) => (
                  <Card
                    sx={{ mb: 1, p: 2 }}
                    style={{ width: "100%" }}
                    key={key}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 4
                          }}
                        >
                          <Typography fontSize={"16px"}>Contrato</Typography>
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "500" }}
                          >
                            <Chip
                              color={"success"}
                              label={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Typography fontSize={18} fontWeight={500}>
                                    {contrato.descricao}
                                  </Typography>
                                  {contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() ===
                                      "Renovação".toLowerCase()
                                  ) && (
                                    <ReplayIcon fontSize={"small"} gap={10} />
                                  )}
                                  {contrato.tipocontratocontrato.some(
                                    (tipoContrato) =>
                                      String(
                                        tipoContrato.tipos_contratos.descricao
                                      ).toLowerCase() ===
                                      "Contrato fixo".toLowerCase()
                                  ) && <StarIcon fontSize={"small"} gap={10} />}
                                </Box>
                              }
                              my={2}
                            />
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={"16px"}>Cliente</Typography>
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "500" }}
                          >
                            {contrato?.Cliente?.nome}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={1.5}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={"16px"}>Criação</Typography>
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "500" }}
                          >
                            {moment(contrato.createdAt).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={1.5}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography fontSize={"16px"}>Aprovação</Typography>
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "500" }}
                          >
                            {contrato?.tipocontratocontrato.some(
                              (tipoContrato) =>
                                String(
                                  tipoContrato.tipos_contratos.descricao
                                ).toLowerCase() ===
                                "Contrato fixo".toLowerCase()
                            ) ? (
                              <Chip
                                label={
                                  <Box display="flex" alignItems="center">
                                    Contrato Fixo{" "}
                                    <StarIcon
                                      fontSize="small"
                                      style={{ marginLeft: 4 }}
                                    />
                                  </Box>
                                }
                              />
                            ) : contrato?.data_aprovacao ? (
                              moment(contrato?.data_aprovacao).format(
                                "DD/MM/YYYY"
                              )
                            ) : (
                              "N/I"
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography fontSize={"16px"}>Valor</Typography>
                          <Typography
                            fontSize={"18px"}
                            style={{ fontWeight: "500" }}
                          >
                            {contrato?.tipocontratocontrato.some(
                              (tipoContrato) =>
                                String(
                                  tipoContrato.tipos_contratos.descricao
                                ).toLowerCase() ===
                                "Contrato fixo".toLowerCase()
                            ) ? (
                              <Chip
                                label={
                                  <Box display="flex" alignItems="center">
                                    Contrato Fixo{" "}
                                    <StarIcon
                                      fontSize="small"
                                      style={{ marginLeft: 4 }}
                                    />
                                  </Box>
                                }
                              />
                            ) : (
                              Number(contrato?.valorTotal).toLocaleString(
                                "pt-BR",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                }
                              )
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Typography fontSize={"16px"}>Recebido</Typography>
                          {contrato?.tipocontratocontrato.some(
                            (tipoContrato) =>
                              String(
                                tipoContrato.tipos_contratos.descricao
                              ).toLowerCase() === "Contrato fixo".toLowerCase()
                          ) ? (
                            <Chip
                              label={
                                <Box display="flex" alignItems="center">
                                  Contrato Fixo{" "}
                                  <StarIcon
                                    fontSize="small"
                                    style={{ marginLeft: 4 }}
                                  />
                                </Box>
                              }
                            />
                          ) : (
                            <>
                              <Typography
                                fontSize={"18px"}
                                style={{ fontWeight: "500" }}
                              >
                                {Number(
                                  contrato?.pagamentoRecebido
                                ).toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </Typography>
                              <Stack direction="row" gap={1}>
                                <Typography fontWeight={"bold"}>
                                  Falta:{" "}
                                </Typography>
                                <Typography>
                                  {contrato?.servicos?.length > 0 ? (
                                    Number(
                                      contrato?.valorTotal -
                                        contrato?.pagamentoRecebido
                                    ) > 1 ? (
                                      Number(
                                        contrato?.valorTotal -
                                          contrato?.pagamentoRecebido
                                      ).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      })
                                    ) : (
                                      <>R$ 0,00</>
                                    )
                                  ) : (
                                    <>R$ 0,00</>
                                  )}
                                </Typography>
                              </Stack>
                            </>
                          )}
                        </Box>
                      </Grid>
                      <Grid item lg={0.5}>
                        <Box style={{ display: "flex", gap: "10px" }}>
                          <Tooltip title="Editar">
                            <VisibilityIcon
                              cursor="pointer"
                              color="info"
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contrato?.id}/financeiro`
                                );
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </>
            ) : (
              <Grid container>
                <Grid item style={{ width: "100%" }}>
                  <Box my={5} textAlign={"center"}>
                    <Typography>Nenhum contrato cadastrado.</Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
            <Paper
              style={{ background: "white", width: "100%" }}
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box width="50%">
                <Typography fontSize={20} fontWeight={500}>
                  Valor total:
                </Typography>
              </Box>
              <Box width="50%">
                <Typography fontSize={20} fontWeight={500}>
                  {Number(valorTotalContratos).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Typography>
              </Box>
            </Paper>
            <Paper style={{ background: "white", width: "100%" }}>
              <FormControl>
                <Grid
                  container
                  spacing={2}
                  alignItems={"center"}
                  display={"flex"}
                  justifyContent={"space-evenly"}
                >
                  <Grid item>
                    <Box my={2}>
                      <Typography>Página</Typography>
                      <Pagination
                        count={Math.ceil(count / perPage)}
                        page={page + 1}
                        onChange={(event, value) => setPage(value - 1)}
                        variant="outlined"
                        shape="rounded"
                        size="large"
                        renderItem={(item) => (
                          <PaginationItem
                            slots={{
                              previous: ArrowBackIcon,
                              next: ArrowForwardIcon,
                            }}
                            {...item}
                          />
                        )}
                        style={{ marginTop: 10 }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Itens por página</Typography>
                      <TextField
                        select
                        label={"Itens por página"}
                        value={perPage}
                        onChange={(e) => setPerPage(parseInt(e.target.value))}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Ordenar por</Typography>
                      <TextField
                        select
                        label={"Ordenação"}
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={"id"}>ID</MenuItem>
                        <MenuItem value={"createdAt"}>Data de Criação</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box>
                      <Typography>Ordem</Typography>
                      <TextField
                        select
                        label={"Ordem"}
                        value={sortOrder}
                        onChange={(e) => setSortOrder(e.target.value)}
                        style={{ marginTop: 10 }}
                      >
                        <MenuItem value={"asc"}>Ascendente</MenuItem>
                        <MenuItem value={"desc"}>Descendente</MenuItem>
                      </TextField>
                    </Box>
                  </Grid>
                </Grid>
              </FormControl>
            </Paper>
          </Grid>
        </React.Fragment>
      </Grid>
    </JumboContentLayout>
  );
};
