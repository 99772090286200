import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Alert,
  InputLabel,
  Breadcrumbs,
  IconButton,
  FormHelperText,
} from "@mui/material";
import HeaderBreadcrumbs from "app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs";
import ApiService from "app/services/config";
import { useMediaQuery } from "beautiful-react-hooks";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Select from "react-select";
import { Select as SelectMUI } from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import CreatableSelect from "react-select/creatable";
import InputAdornment from "@mui/material/InputAdornment";
import * as yup from "yup";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import TaskIcon from "@mui/icons-material/Task";
import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import { PermissionContext } from "app/contexts/PermissionContext";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Article } from "@mui/icons-material";
import CurrencyInput from "react-currency-input-field";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const style = {
  width: 200,
  height: 54,
  padding: "8px",
  fontSize: "1rem",
  borderRadius: 4,
  border: "1px solid #ced4da",
  backgroundColor: "#fff",
  boxSizing: "border-box",
  marginRight: "10px",
  marginTop: 3,
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const servicoSchema = yup.object({
  observacoes: yup
    .array()
    .of(
      yup.object().shape({
        descricao: yup
          .string("Campo obrigatório")
          .required("Campo obrigatório"),
      })
    )
    .optional(),
  processoAdministrativoId: yup
    .number("Campo obrigatório")
    .required("Campo obrigatório"),

  tarefa: yup
    .object()
    .shape({
      id: yup.number(),
      descricao: yup.string(),
    })
    .required("Campo obrigatório")
    .typeError("Selecione uma tarefa"),

  setorId: yup.number().required("Campo obrigatório"),
  tipoServicoId: yup.number().required("Campo obrigatório"),
  quantidade: yup
    .number("Campo obrigatório")
    .required("Campo quantidade obrigatório")
    .min(1, "Quantidade mínina é 1"),
  valor: yup.string().required("Campo valor obrigatório"),
  colaboradores: yup
    .array("Insira ao menos 1 colaborador")
    .of(
      yup.object().shape({
        id: yup.number("Campo obrigatório"),
        name: yup.string(),
      })
    )
    .optional(),
  prazo: yup.date().optional().typeError("Data inválida"),
  contrato_id: yup
    .number()
    .required("Selecione um contrato")
    .typeError("Campo obrigatório"),
  processo_id: yup
    .number()
    .required("Selecione um contrato")
    .typeError("Campo obrigatório"),
});

const ServicosContratoForm = () => {
  const userId = Number(localStorage.getItem("id"));
  const roleId = Number(localStorage.getItem("role_id"));

  const { id, contratoId, processoId, clienteId, servicoId, procadmin } =
    useParams();
  const initialValues = {
    observacoes: [],
    processoAdministrativoId: Number(procadmin),
    tarefa: "",
    setorId: "",
    tipoServicoId: "",
    quantidade: 1,
    valor: 0,
    colaboradores: [],
    is_paralisado: "nao",
    is_em_revisao: "nao",
    prazo: "",
    porcentagem: 0,
    responsavel: "ambiental",
    contrato_id: contratoId !== "null" ? Number(contratoId) : "",
    processo_id: processoId !== "null" ? Number(contratoId) : "",
    clienteId: "",
  };
  const { theme } = useJumboTheme();
  const formRef = useRef();
  const colaboradorId = Number(localStorage.getItem("id"));
  const [servico, setServico] = useState(initialValues);
  const [dadosCarregados, setDadosCarregados] = useState(false);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [centroDeCustos, setCentroDeCustos] = useState([]);
  const [tiposServicos, setTiposServicos] = useState([]);
  const [processosAdministrativos, setProcessosAdministrativos] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [statusOrcamentos, setStatusOrcamentos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [tipoOrcamentos, setTiposOrcamentos] = useState([]);
  const [tiposContrato, setTiposContrato] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [setores, setSetores] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [isContratoFixo, setIsContratoFixo] = useState(false);
  const [
    valoresFinaisServicosCadastrados,
    setValoresFinaisServicosCadastrados,
  ] = useState([]);
  const [
    valoresFinaisServicosNaoCadastrados,
    setValoresFinaisNaoServicosCadastrados,
  ] = useState([]);
  const [clienteIdSelecionado, setClienteIdSelecionado] = useState(null);
  const [modalAberto, setModalAberto] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const Swal = useSwalWrapper();
  const location = useLocation();
  const [valorServico, setValorServico] = useState(0);
  const [listaContratosSelect, setListaContratosSelect] = useState([]);
  const [contratoSelecionadoSelect, setContratoSelecionadoSelect] =
    useState(null);
  const [processosAdminProcesso, setProcessosAdminProcesso] = useState([]);

  const [novoNomeCliente, setNovoNomeCliente] = useState("");
  const [novaObservacao, setNovaObservacao] = useState("");
  const [listaAuxiliarObservacoes, setListaAuxiliarObservacoes] = useState([]);
  const [isCampoValorVisivel, setIsCampoValorVisivel] = useState(false);
  const navigate = useNavigate();

  const { hasPermission } = useContext(PermissionContext);

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const handleOpenModal = () => {
    setModalAberto(true);
  };

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const getServico = useCallback(async () => {
    try {
      const response = await ApiService.get(`/servicos/${servicoId}`);
      if (response.status === 200) {
        const { tarefas_servico, servicocolaborador, valor, quantidade } =
          response.data;

        if (valor > 0) {
          setIsCampoValorVisivel(true);
        }

        handleCalculateValue(valor, quantidade);

        const tarefasObject = tarefas_servico?.map((tarefa) => ({
          id: tarefa?.id,
          descricao: tarefa?.descricao,
        }));

        const colaboradoresObject = servicocolaborador?.map((colaborador) => ({
          idRelacaoServicoColaborador: colaborador?.id,
          id: colaborador?.users?.id,
          name: colaborador?.users?.name,
          avatarUrl: colaborador?.users?.avatarUrl,
        }));

        let servicoData = {
          ...response.data,
          contrato_id: response.data?.contratoId,
          processo_id: response.data?.processo_contrato_id,
          tarefa: tarefasObject[0],
          colaboradores: colaboradoresObject,
          prazo: response.data?.prazo
            ? moment.utc(response.data?.prazo).format("YYYY-MM-DD")
            : "",
          processoAdministrativoId: Number(procadmin),
          observacoes: response.data?.observacoes,
        };

        setServico(servicoData);
      }
    } catch (error) {
      console.log(error);
    }
  });

  const getCentroDeCustos = useCallback(async () => {
    try {
      await ApiService.get("/centrosdecustos/all").then((response) => {
        setCentroDeCustos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposServicos = useCallback(async () => {
    try {
      await ApiService.get("/tiposservico").then((response) => {
        setTiposServicos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getProcessosAdministrativos = useCallback(async () => {
    try {
      await ApiService.get("/processosadmin/all").then((response) => {
        setProcessosAdministrativos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTarefas = useCallback(async () => {
    try {
      await ApiService.get("/tarefas/all").then((response) => {
        setTarefas(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getStatusOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/statusorcamento").then((response) => {
        setStatusOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const filteredStatusOrcamentos = statusOrcamentos?.filter(
    (status) =>
      String(status.descricao).toLowerCase() !== "finalizado" &&
      String(status.descricao).toLowerCase() !== "finalizado contrato" &&
      String(status.descricao).toLowerCase() !== "finalizado serviço"
  );

  const handleCalcularValorServicos = useCallback(async () => {
    try {
      if (servico && servico?.servicos?.length > 0) {
        const { servicos: servicosContrato } = servico;

        const valoresFinaisServico = servicosContrato?.map((servico) => {
          return {
            id: servico.id,
            valorFinal: servico.valor * servico.quantidade,
          };
        });
        setValoresFinaisServicosCadastrados(valoresFinaisServico);
      }
    } catch (error) {
      console.log(error);
    }
  });

  const getColaboradores = useCallback(async () => {
    try {
      await ApiService.get("/users/colaboradores").then((response) => {
        setColaboradores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposOrcamento = useCallback(async () => {
    try {
      await ApiService.get("/tipoorcamento").then((response) => {
        setTiposOrcamentos(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getTiposContrato = useCallback(async () => {
    try {
      await ApiService.get("/tipocontrato").then((response) => {
        setTiposContrato(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getClientes = useCallback(async () => {
    try {
      await ApiService.get("/clientes/all").then((response) => {
        setClientes(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const getUnidades = async () => {
    try {
      await ApiService.get(
        `/unidade/cliente/select/${clienteIdSelecionado}`
      ).then((response) => {
        if (response.status === 200) {
          setUnidades(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getSetores = useCallback(async () => {
    try {
      await ApiService.get("/setores").then((response) => {
        setSetores(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  });

  const handleCalculateValue = useCallback(
    (valor, quantidade, id) => {
      const valorFinal = valor * quantidade;

      setValorServico(
        Number(valorFinal).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      );
    },
    [valoresFinaisServicosCadastrados, valoresFinaisServicosNaoCadastrados]
  );

  const handleSubmit = async (
    values,
    { setSubmitting, resetForm, setFieldValue }
  ) => {
    if (servicoId && servicoId !== "null") {
      const newValues = {
        ...values,
        contrato_id: Number(contratoId),
        processo_id: Number(processoId),
        is_paralisado: values.is_paralisado === "sim" ? true : false,
        is_em_revisao: values.is_em_revisao === "sim" ? true : false,
        userId,
        valor:
          typeof values.valor === "string"
            ? !isNaN(parseFloat(values.valor.replace(",", ".")))
              ? parseFloat(values.valor.replace(",", "."))
              : 0
            : values.valor,
      };

      delete newValues.servico_contrato_id;
      delete newValues.processo_contrato_id;
      delete newValues.tarefas_servico;
      delete newValues.contratoId;
      delete newValues.centrosDeCustoId;
      delete newValues.Contrato;
      delete newValues.setor_responsavel;
      delete newValues.documentos;

      if (
        values.tipocontratocontrato?.every(
          (tipoContrato) =>
            tipoContrato.descricao !== "RENOVAÇÃO" &&
            (!tipoContrato.tipos_contratos ||
              tipoContrato.tipos_contratos.descricao !== "RENOVAÇÃO")
        )
      ) {
        delete values.dataVencimento;
        delete values.dataRenovacao;
      }

      delete newValues.Cliente;
      delete newValues.cliente_id;
      delete newValues.tipo_orcamento;
      delete newValues.elaborador_orcamento;
      delete newValues.Imovel;
      delete newValues.id;
      delete newValues.createdAt;
      delete newValues.updatedAt;
      delete newValues.deletedAt;
      delete newValues.colaborador_que_criou_id;
      delete newValues.status_andamento_servico;
      delete newValues.data_inicio;
      delete newValues.data_conclusao;

      try {
        await ApiService.put(`/servicos/${servicoId}`, newValues).then(
          (response) => {
            if (response.status === 200) {
              toast("success", "Atualizado com sucesso!");
              navigate(
                `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`
              );
            }
          }
        );
      } catch (error) {
        console.log(error);
        toast("error", "Ocorreu um erro");
      }
    } else {
      const newValues = {
        ...values,
        contrato_id:
          contratoId !== "null"
            ? Number(contratoId)
            : Number(values.contrato_id),
        processo_id:
          processoId !== "null"
            ? Number(processoId)
            : Number(values.processo_id),
        is_paralisado: values.is_paralisado === "sim" ? true : false,
        is_em_revisao: values.is_em_revisao === "sim" ? true : false,
        userId,
        valor:
          typeof values.valor === "string"
            ? !isNaN(parseFloat(values.valor.replace(",", ".")))
              ? parseFloat(values.valor.replace(",", "."))
              : 0
            : values.valor,
      };
      try {
        await ApiService.post("/servicos", newValues)
          .then((response) => {
            if (response.status === 201) {
              toast("success", "Criado com sucesso");
              if (
                contratoId === "null" &&
                processoId === "null" &&
                procadmin === "null"
              ) {
                navigate(
                  `/app/contrato/${values.contrato_id}/cliente/${values.clienteId}/processos/${values.processo_id}/procadmin/${values.processoAdministrativoId}/servicos`
                );
              } else {
                navigate(
                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`
                );
              }
              resetForm();
            }
          })
          .catch((error) => {
            console.log(error);
            let message = error;
            toast("error", message);

            if (error.response.data) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("error", error.message);
            }
          });
      } catch (error) {
        console.log(error);
        toast("error", "Ocorreu um erro");
      }
    }
  };

  const handleDeleteServico = useCallback(async (id) => {
    try {
      ApiService.delete(`/servicos/${processoId}`)
        .then((response) => {
          toast("success", "Removido com sucesso!");
          const servicosFiltrados = servicos?.filter(
            (servico) => servico.id !== id
          );
          setServico((prevContrato) => ({
            ...prevContrato,
            servicos: servicosFiltrados,
          }));
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleDeleteContrato = useCallback(async (id) => {
    try {
      ApiService.delete(`/servicos/${servicoId}`)
        .then((response) => {
          if (response.status === 200) {
            toast("success", "Removido com sucesso!");
            navigate(
              `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`
            );
          }
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // Criação de novos itens no CreateSelect

  const handleCreateCliente = (value) => {
    setNovoNomeCliente(value);
  };

  useEffect(() => {
    if (novoNomeCliente !== "") {
      handleOpenModal();
    }
  }, [novoNomeCliente]);

  const handleCreateTipoOrcamento = useCallback(async (descricao) => {
    const response = await ApiService.post("/tipoorcamento", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getTiposOrcamento();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateCentroDeCustos = useCallback(async (descricao) => {
    const response = await ApiService.post("/centrosdecustos", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getCentroDeCustos();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateTipoServico = useCallback(async (descricao) => {
    const response = await ApiService.post("/tiposservico", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getTiposServicos();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateTarefa = useCallback(async (descricao) => {
    const response = await ApiService.post("/tarefas", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getTarefas();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const handleCreateSetor = useCallback(async (descricao) => {
    const response = await ApiService.post("/setores", { descricao })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getSetores();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  const modalAlertDeleteContrato = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar o contrato?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleDeleteContrato(id);
      }
    });
  };

  const handleDesconectarTarefaServico = async (tarefaId, servicoId) => {
    try {
      await ApiService.put(
        `/servicos/tarefa/${tarefaId}/desconectar/${servicoId}`
      ).then((response) => {
        if (response.status === 200) {
          console.log("Tarefa desconectada do serviço!");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const modalAlert = (idObservacao, values) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
      didOpen: () => {
        Swal.getContainer().style.zIndex = "9999";
      },
    }).then((result) => {
      if (result.value) {
        handleDeleteObservacao(idObservacao, values);
      }
    });
  };

  const handleDeleteObservacao = async (observacaoId, values) => {
    try {
      const response = await ApiService.delete(
        `/servicos/deletar/observacao/${observacaoId}`
      );

      if (response.status === 200) {
        toast(null, "Removido com sucesso!", "success");

        const observacoesFiltradas = values?.observacoes?.filter(
          (observacao) => observacao.id !== observacaoId
        );
        setServico(() => ({
          ...values,
          observacoes: observacoesFiltradas,
        }));
      }
    } catch (error) {
      toast(null, "Ocorreu um erro", "error");
      console.log(error);
    }
  };

  const getContratosSelect = async () => {
    try {
      await ApiService.get("/contratos/all").then((response) => {
        if (response.status === 200) {
          setListaContratosSelect(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const handleChangeContratoSelecionadoSelect = (id) => {
  //   const contrato = listaContratosSelect.find(
  //     (contrato) => contrato.id === id
  //   );
  //   setContratoSelecionadoSelect(contrato);
  // };

  const getProcessosAdminProcessoContrato = useCallback(
    async (processoId) => {
      try {
        await ApiService.get(
          `/processosadmin/processo-contrato/procadmins/${processoId}`
        ).then((response) => {
          if (response.status === 200) {
            setProcessosAdminProcesso(response.data);
            console.log(response.data);
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    [contratoSelecionadoSelect]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (servicoId) {
        getServico();
        handleCalcularValorServicos();
      }
      await Promise.all([
        getCentroDeCustos(),
        getTiposServicos(),
        getProcessosAdministrativos(),
        getTarefas(),
        getStatusOrcamento(),
        getColaboradores(),
        getClientes(),
        getTiposContrato(),
        getTiposOrcamento(),
        getSetores(),
      ]);
      setDadosCarregados(true);
    };
    fetchData();
    if (location && String(location.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
    if (
      processoId === "null" &&
      contratoId === "null" &&
      procadmin === "null"
    ) {
      getContratosSelect();
    }
  }, [id]);

  useEffect(() => {
    if (clienteIdSelecionado) {
      getUnidades();
    }
  }, [clienteIdSelecionado]);

  useEffect(() => {
    if (dadosCarregados && servico) {
      const temPermissaoDeAcessarAPagina = hasPermission("Financeiro", "read");
      const contratoCriadoPeloUsuarioAtual =
        servico.colaborador_que_criou_id === userId;
      console.log(servico.colaborador_que_criou_id, userId);
      if (!temPermissaoDeAcessarAPagina && !contratoCriadoPeloUsuarioAtual) {
        navigate("/app");
      }
      if (breadCrumbsInfo) {
        const isFixo = breadCrumbsInfo?.tipocontratocontrato?.some(
          (tipoContrato) =>
            String(tipoContrato?.tipos_contratos?.descricao)
              .toLowerCase()
              .includes("fixo")
        );
        setIsContratoFixo(isFixo);
      }
    }
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [dadosCarregados, servico, breadCrumbsInfo]);

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}&servicoId=${servicoId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId && contratoId !== "null") {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <>
      {dadosCarregados && (
        <JumboContentLayout layoutOptions={layoutOptions}>
          {lg && <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }} />}
          <Stack width={"100%"}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                to={"/app/listar-contratos"}
                color="success"
                style={linkStyle}
              >
                Contratos
              </Link>
              {breadCrumbsInfo && (
                <Link
                  to={`/app/contrato/${contratoId}/detalhes`}
                  color="success"
                  style={linkStyle}
                >
                  {breadCrumbsInfo?.descricao}
                </Link>
              )}
              <Link
                to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
                color="primary"
                style={linkStyle}
              >
                Processos Administrativos
              </Link>
              {breadCrumbsInfo && (
                <Link
                  to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/editar/${processoId}`}
                  color="primary"
                  style={linkStyle}
                >
                  {
                    breadCrumbsInfo?.processos_contratos?.[0]
                      ?.processo_administrativo?.descricao
                  }
                </Link>
              )}
              <Link
                to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`}
                color="primary"
                style={linkStyle}
              >
                Serviços
              </Link>
              {servicoId && (
                <Link
                  to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/editar/${servicoId}`}
                  color="success"
                  style={linkStyle}
                >
                  {`Serviço ID - ${servicoId}`}
                </Link>
              )}
              <Link
                to={
                  servicoId
                    ? `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/servicos/editar/${servicoId}`
                    : `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/servicos/novo`
                }
                color="primary"
                style={linkStyle}
              >
                {servicoId ? "Editar" : "Novo"} Serviço
              </Link>
            </Breadcrumbs>
          </Stack>

          {isDeleteMode && (
            <Stack width={"100%"} p={1}>
              <Alert severity="error">
                Você está prestes a excluir o (s) serviço (s) abaixo:
              </Alert>
            </Stack>
          )}

          <Paper sx={{ p: "15px" }}>
            <Formik
              innerRef={formRef}
              initialValues={servico}
              validationSchema={servicoSchema}
              enableReinitialize
              validateOnChange
              validateOnBlur
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleBlur,
                isSubmitting,
                handleChange,
                setFieldValue,
                errors,
              }) => (
                <Form style={{ width: "100%" }}>
                  <Grid container alignContent={"center"} spacing={2}>
                    <Grid item lg={12}>
                      <Divider />

                      <>
                        <Card sx={{ mb: 1, p: 2 }} style={{ width: "100%" }}>
                          <Grid
                            container
                            width={"100%"}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid
                              item
                              width="100%"
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flenx-end",
                              }}
                            >
                              {servicoId && (
                                <Button
                                  variant="outlined"
                                  color="info"
                                  startIcon={<Article />}
                                  onClick={() => {
                                    navigate(
                                      `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos/${servicoId}/documentos`,
                                      {
                                        state: {
                                          contrato: location?.state?.descricao,
                                          processo:
                                            location?.state?.processoDescricao,
                                        },
                                      }
                                    );
                                  }}
                                >
                                  Documentos
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                          <Grid container alignContent={"center"} spacing={2}>
                            {processoId === "null" &&
                              contratoId === "null" &&
                              procadmin === "null" && (
                                <>
                                  <Grid item lg={4} xs={12}>
                                    <Typography my={2}>Contrato</Typography>
                                    <SelectMUI
                                      name="contrato_id"
                                      value={values.contrato_id}
                                      onChange={(event) => {
                                        const id = event.target.value;
                                        setFieldValue("contrato_id", id);
                                        const contrato =
                                          listaContratosSelect?.find(
                                            (contrato) => contrato.id === id
                                          );
                                        setContratoSelecionadoSelect(contrato);
                                        setFieldValue(
                                          "clienteId",
                                          contratoSelecionadoSelect?.Cliente?.id
                                        );
                                      }}
                                      fullWidth
                                    >
                                      {listaContratosSelect.length > 0 &&
                                        listaContratosSelect.map((contrato) => (
                                          <MenuItem value={contrato.id}>
                                            {contrato.descricao}
                                          </MenuItem>
                                        ))}
                                    </SelectMUI>
                                    <ErrorMessage
                                      name="contrato_id"
                                      style={{ color: "red" }}
                                      component="div"
                                    />
                                  </Grid>
                                  {console.log(contratoSelecionadoSelect)}
                                  <Grid item lg={4} xs={12}>
                                    <Typography my={2}>
                                      Processo do Contrato
                                    </Typography>
                                    <SelectMUI
                                      name="processo_id"
                                      value={values.processo_id}
                                      disabled={!values.contrato_id}
                                      onChange={(event) => {
                                        const id = event.target.value;
                                        setFieldValue("processo_id", id);
                                        getProcessosAdminProcessoContrato(id);
                                      }}
                                      fullWidth
                                    >
                                      {contratoSelecionadoSelect &&
                                        contratoSelecionadoSelect.processos_contratos.map(
                                          (processo) => (
                                            <MenuItem value={processo.id}>
                                              Proc. Admin:{" "}
                                              {
                                                processo
                                                  ?.processo_administrativo
                                                  ?.descricao
                                              }{" "}
                                              - Imóvel:{" "}
                                              {processo?.imovel?.descricao}
                                            </MenuItem>
                                          )
                                        )}
                                    </SelectMUI>
                                    <ErrorMessage
                                      name="processo_id"
                                      style={{ color: "red" }}
                                      component="div"
                                    />
                                    <FormHelperText>
                                      Caso não haja processos cadastrados para o
                                      contrato, será preciso cadastrar pelo
                                      menos um na página de cadastro de
                                      processos de um contrato.
                                    </FormHelperText>
                                  </Grid>
                                  <Grid item lg={4} xs={12}>
                                    <Typography my={2}>
                                      Processo Administrativo
                                    </Typography>
                                    <SelectMUI
                                      name="processoAdministrativoId"
                                      value={values.processoAdministrativoId}
                                      disabled={!values.processo_id}
                                      onChange={handleChange}
                                      fullWidth
                                    >
                                      {processosAdminProcesso &&
                                        processosAdminProcesso?.map(
                                          (processo) => (
                                            <MenuItem value={processo.id}>
                                              {processo.descricao}
                                            </MenuItem>
                                          )
                                        )}
                                    </SelectMUI>
                                    <ErrorMessage
                                      name="processoAdministrativoId"
                                      style={{ color: "red" }}
                                      component="div"
                                    />
                                  </Grid>
                                </>
                              )}
                            <Grid item lg={6} xs={12}>
                              <Typography my={2}>Tipo de Serviço</Typography>
                              <div style={{ position: "relative" }}>
                                <CreatableSelect
                                  isClearable
                                  isDisabled={isDeleteMode}
                                  styles={customStyles}
                                  name={`tipoServicoId`}
                                  onChange={(option) => {
                                    setFieldValue(
                                      `tipoServicoId`,
                                      option ? option.id : ""
                                    );
                                  }}
                                  onCreateOption={async (value) => {
                                    const { id } =
                                      await handleCreateTipoServico(value);
                                    setFieldValue(`tipoServicoId`, id);
                                  }}
                                  options={tiposServicos}
                                  getOptionLabel={(option) =>
                                    option.__isNew__
                                      ? option.label
                                      : option.descricao
                                  }
                                  value={tiposServicos.find(
                                    (tipoServico) =>
                                      tipoServico.id === values.tipoServicoId
                                  )}
                                  placeholder={"Selecione um Tipo de Serviço"}
                                />

                                <ErrorMessage
                                  component={"div"}
                                  name={`tipoServicoId`}
                                  style={{ color: "red" }}
                                />
                              </div>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                              <Typography my={2}>Tarefa</Typography>
                              <CreatableSelect
                                isClearable
                                styles={customStyles}
                                isDisabled={isDeleteMode}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "tarefa",
                                    selectedOption ? selectedOption : null
                                  );
                                }}
                                onCreateOption={async (value) => {
                                  const { id, descricao } =
                                    await handleCreateTarefa(value);
                                  setFieldValue("tarefa", { id, descricao });
                                }}
                                options={tarefas}
                                getOptionLabel={(option) => option?.descricao}
                                getOptionValue={(option) => option?.id}
                                placeholder={"Selecione uma Tarefa"}
                                value={values?.tarefa}
                              />

                              <ErrorMessage
                                component={"div"}
                                name={`tarefa`}
                                style={{ color: "red" }}
                              />
                            </Grid>
                            <Grid item lg={3}>
                              <Typography my={2}>Prazo</Typography>
                              <TextField
                                name="prazo"
                                type="date"
                                disabled={isDeleteMode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.prazo}
                                variant="outlined"
                                fullWidth
                              />
                              <ErrorMessage
                                component={"div"}
                                name={`prazo`}
                                style={{ color: "red" }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container alignContent={"center"} spacing={2}>
                            <Grid item lg={6} xs={12}>
                              <Typography my={2}>Quantidade e Valor</Typography>
                              <div
                                style={{
                                  position: "relative",
                                  marginTop: -8,
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                              >
                                <TextField
                                  type="number"
                                  name={`quantidade`}
                                  placeholder="Quantidade"
                                  InputProps={{
                                    style: {
                                      width: 200,
                                      marginRight: 10,
                                    },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        Qtd
                                      </InputAdornment>
                                    ),
                                  }}
                                  step="any"
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(event) => {
                                    const quantidade = Number(
                                      event.target.value
                                    );
                                    if (quantidade < 0) {
                                      setFieldValue(
                                        `quantidade`,
                                        Math.abs(quantidade)
                                      );
                                    } else if (quantidade === "") {
                                      setFieldValue(`quantidade`, 1);
                                    } else {
                                      setFieldValue(`quantidade`, quantidade);
                                    }
                                    handleCalculateValue(
                                      quantidade,
                                      values.valor
                                    );
                                  }}
                                  value={values.quantidade || 0}
                                  disabled={isContratoFixo || isDeleteMode}
                                  variant="outlined"
                                  margin="dense"
                                />
                                {isCampoValorVisivel || values.valor !== 0 ? (
                                  <>
                                    <CurrencyInput
                                      style={style}
                                      id={"valor-servico"}
                                      name={"valor"}
                                      prefix={"R$ "}
                                      decimalsLimit={2}
                                      decimalSeparator=","
                                      groupSeparator="."
                                      disabled={isContratoFixo || isDeleteMode}
                                      step={0.01}
                                      value={values.valor}
                                      onValueChange={(value) => {
                                        const valor = Number(
                                          value.replace(",", ".")
                                        );
                                        if (
                                          value === undefined ||
                                          value === null ||
                                          value === ""
                                        ) {
                                          setFieldValue("valor", 0);
                                        } else {
                                          setFieldValue("valor", value);
                                        }
                                        handleCalculateValue(
                                          values.quantidade,
                                          Number(valor)
                                        );
                                      }}
                                    />
                                    {/* <TextField
                                      type="number"
                                      name={`valor`}
                                      placeholder="Valor"
                                      InputProps={{
                                        style: {
                                          width: 200,
                                          marginRight: 10,
                                        },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            R$
                                          </InputAdornment>
                                        ),
                                      }}
                                      step="any"
                                      onWheel={(e) => e.target.blur()}
                                      onChange={(event) => {
                                        const valor = Number(
                                          event.target.value.replace(",", ".")
                                        );
                                        if (valor < 0) {
                                          setFieldValue(
                                            `valor`,
                                            Math.abs(valor)
                                          );
                                        } else if (valor === "") {
                                          setFieldValue(`valor`, 1);
                                        } else {
                                          setFieldValue(`valor`, valor);
                                        }
                                        handleCalculateValue(
                                          values.quantidade,
                                          valor
                                        );
                                      }}
                                      value={values.valor || 0}
                                      disabled={isContratoFixo || isDeleteMode}
                                      variant="outlined"
                                      margin="dense"
                                    /> */}
                                    <TextField
                                      type="text"
                                      InputProps={{
                                        readOnly: true,
                                        style: {
                                          minWidth: 100,
                                          width: "auto",
                                          maxWidth: "120px",
                                          background: "#cecece",
                                          textAlign: "center",
                                        },
                                      }}
                                      value={valorServico}
                                      variant="outlined"
                                      margin="dense"
                                    />
                                  </>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => {
                                      setIsCampoValorVisivel(true);
                                    }}
                                  >
                                    Adicionar valor
                                  </Button>
                                )}
                              </div>
                              <ErrorMessage
                                name={`valor`}
                                component="div"
                                style={{ color: "red" }}
                              />
                              <ErrorMessage
                                name={`quantidade`}
                                component="div"
                                style={{ color: "red" }}
                              />
                            </Grid>
                            <Grid item lg={3}>
                              <Typography my={2}>Setor responsável</Typography>
                              <div style={{ position: "relative" }}>
                                <CreatableSelect
                                  isClearable
                                  isDisabled={isDeleteMode}
                                  styles={customStyles}
                                  name={`setorId`}
                                  onChange={(option) => {
                                    setFieldValue(
                                      `setorId`,
                                      option ? option.id : ""
                                    );
                                  }}
                                  onCreateOption={async (value) => {
                                    const { id } = await handleCreateSetor(
                                      value
                                    );
                                    setFieldValue(`setorId`, id);
                                  }}
                                  options={setores}
                                  getOptionLabel={(option) =>
                                    option.__isNew__
                                      ? option.label
                                      : option.descricao
                                  }
                                  value={setores.find(
                                    (setor) => setor.id === values.setorId
                                  )}
                                  placeholder={"Selecione um Setor"}
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name={`setorId`}
                                  style={{ color: "red" }}
                                />
                              </div>
                            </Grid>
                            <Grid item lg={3}>
                              <Typography my={2}>Colaboradores</Typography>
                              <FieldArray name={`colaboradores`}>
                                {({ push, remove }) => (
                                  <Box>
                                    <Select
                                      isMulti
                                      isDisabled={isDeleteMode}
                                      styles={customStyles}
                                      options={colaboradores.filter(
                                        (option) =>
                                          !values.colaboradores?.some(
                                            (colaborador) =>
                                              colaborador.id === option.id
                                          )
                                      )}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      onChange={(selectedOptions) => {
                                        const novosColaboradores =
                                          selectedOptions.filter(
                                            (option) =>
                                              !values.colaboradores?.some(
                                                (colaborador) =>
                                                  colaborador.id === option.id
                                              )
                                          );
                                        novosColaboradores.forEach(
                                          (colaborador) => push(colaborador)
                                        );

                                        values.colaboradores.forEach(
                                          (colaborador, index) => {
                                            if (
                                              !selectedOptions.some(
                                                (option) =>
                                                  option.id === colaborador.id
                                              )
                                            ) {
                                              remove(index);
                                            }
                                          }
                                        );
                                      }}
                                      value={values.colaboradores}
                                      placeholder="Selecione um colaborador"
                                    />
                                  </Box>
                                )}
                              </FieldArray>
                              <ErrorMessage
                                component={"div"}
                                name={`colaboradores`}
                                style={{ color: "red" }}
                              />
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid item lg={6} xs={12}>
                              {/* <Grid item lg={6}>
                                <FormLabel id="select-responsavel">
                                  Responsável:
                                </FormLabel>
                                <SelectMUI
                                  name="responsavel"
                                  id="select-responsavel"
                                  fullWidth
                                  onChange={handleChange}
                                  renderValue={(value) => {
                                    const atividade = ATIVIDADES_DEPENDE[value];
                                    return (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: atividade?.cor,
                                            marginRight: "8px",
                                          }}
                                        />
                                        <Typography>
                                          {atividade?.atividade}
                                        </Typography>
                                      </Box>
                                    );
                                  }}
                                  value={values.responsavel ?? "nao_informado"}
                                  sx={{
                                    "& .MuiSelect-selectMenu": {
                                      display: "flex",
                                      alignItems: "center",
                                    },
                                  }}
                                >
                                  {Object.keys(ATIVIDADES_DEPENDE).map(
                                    (key) => {
                                      const atividade = ATIVIDADES_DEPENDE[key];
                                      return (
                                        <MenuItem
                                          key={key}
                                          value={key}
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              width: "10px",
                                              height: "10px",
                                              borderRadius: "50%",
                                              backgroundColor: atividade.cor,
                                              marginRight: "8px",
                                            }}
                                          />
                                          <Typography>
                                            {atividade.atividade}
                                          </Typography>
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </SelectMUI>
                                <ErrorMessage
                                  name="responsavel"
                                  component={"div"}
                                  style={{ color: "red" }}
                                />
                              </Grid> */}
                            </Grid>
                          </Grid>
                          <Grid container my={2}>
                            <Typography variant="h4">Observações</Typography>
                            <FieldArray name="observacoes">
                              {({ push, remove }) => (
                                <Box my={1} width={"100%"}>
                                  {values.observacoes &&
                                  values.observacoes.length === 0 ? (
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                      justifyContent="center"
                                      height={150}
                                    >
                                      <Typography my={1}>
                                        Nenhuma observação registrada
                                      </Typography>
                                    </Box>
                                  ) : (
                                    <>
                                      {values.observacoes &&
                                        values.observacoes.map(
                                          (observacao, index) => (
                                            <>
                                              <Box
                                                key={index}
                                                my={2}
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <Field
                                                  name={`observacoes.${index}.descricao`}
                                                  as={TextField}
                                                  label={`Observação ${
                                                    index + 1
                                                  }`}
                                                  variant="outlined"
                                                  fullWidth
                                                  multiline
                                                />
                                                <IconButton
                                                  variant="outlined"
                                                  color="error"
                                                  onClick={() =>
                                                    observacao.id
                                                      ? modalAlert(
                                                          observacao.id,
                                                          values
                                                        )
                                                      : remove(index)
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Box>
                                              {observacao.id && (
                                                <>
                                                  <Stack
                                                    px={1}
                                                    direction={"row"}
                                                    justifyContent={
                                                      "space-between"
                                                    }
                                                  >
                                                    <Typography color="#909FAE">
                                                      Usuário:{" "}
                                                      {observacao.usuario
                                                        ?.name ?? "N/I"}
                                                    </Typography>
                                                    <Typography color="#909FAE">
                                                      Criado em:{" "}
                                                      {moment(
                                                        observacao.createdAt
                                                      ).format(
                                                        "DD/MM/YY HH:MM"
                                                      )}
                                                    </Typography>
                                                  </Stack>
                                                  <Divider my={4} />
                                                </>
                                              )}
                                            </>
                                          )
                                        )}
                                    </>
                                  )}

                                  <Box
                                    my={2}
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <TextField
                                      label="Nova observação"
                                      variant="outlined"
                                      fullWidth
                                      value={novaObservacao}
                                      onChange={(e) =>
                                        setNovaObservacao(e.target.value)
                                      }
                                      multiline
                                    />
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        if (novaObservacao.trim()) {
                                          push({
                                            descricao: novaObservacao,
                                            userId: Number(userId),
                                          });
                                          setListaAuxiliarObservacoes(
                                            (prevLista) => ({
                                              ...prevLista,
                                              descricao: novaObservacao,
                                              userId: Number(userId),
                                            })
                                          );
                                          setNovaObservacao("");
                                        }
                                      }}
                                      sx={{ ml: 2, width: "350px" }}
                                    >
                                      Adicionar nova observação
                                    </Button>
                                  </Box>
                                </Box>
                              )}
                            </FieldArray>
                          </Grid>

                          <input
                            type="number"
                            name="userId"
                            value={Number(userId)}
                            hidden
                          />
                          <input
                            type="number"
                            name="roleId"
                            value={Number(roleId)}
                            hidden
                          />
                        </Card>
                      </>
                    </Grid>

                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      marginTop={3}
                    >
                      {isDeleteMode ? (
                        <Stack direction="row" gap={2} display={"flex"}>
                          <Box>
                            <Button
                              color="error"
                              variant="contained"
                              onClick={() => {
                                modalAlertDeleteContrato(processoId);
                              }}
                            >
                              Excluir serviço (s)
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                navigate(
                                  `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/servicos`
                                );
                              }}
                            >
                              Cancelar
                            </Button>
                          </Box>
                        </Stack>
                      ) : (
                        <LoadingButton
                          loading={isSubmitting}
                          type="submit"
                          style={{ margin: "auto", display: "block" }}
                          variant="contained"
                          color="primary"
                        >
                          {servicoId ? "Atualizar" : "Criar"}
                        </LoadingButton>
                      )}
                    </Box>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </JumboContentLayout>
      )}
    </>
  );
};
export default ServicosContratoForm;
