import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { Avatar, Box, Breadcrumbs, Button, Chip, Divider } from "@mui/material";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import ContratoDetalhesServicos from "app/components/ContratoDetalhesServicos/ContratoDetalhesServicos";
import DetalhesClienteContrato from "app/components/DetalhesClienteContrato/DetalhesClienteContrato";
import DetalhesContrato from "app/components/DetalhesContrato/DetalhesContrato";
import { PermissionContext } from "app/contexts/PermissionContext";
import PageHeader from "app/layouts/shared/headers/PageHeader/PageHeader";
import ApiService from "app/services/config";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import { Paid, PaidOutlined, Topic } from "@mui/icons-material";
import ModalHistoricoPagamentoContrato from "app/components/ModalHistoricoPagamentoContrato/ModalHistoricoPagamentoContrato";
import ModalPagamentoContrato from "app/components/ModalPagamentoContrato/ModalPagamentoContrato";
import ModalHistoricoRenegociacaoContrato from "app/components/ModalHistoricoRenegociacaoPagamento/ModalHistoricoRenegociacaoPagamento";
import ModalRenegociarContrato from "app/components/ModalRenegociarContrato/ModalRenegociarContrato";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const ContratoDetalhes = () => {
  const { id } = useParams();
  const [contrato, setContrato] = useState([]);
  const [valorTotalContrato, setValorTotalContrato] = useState(0);
  const [isModalRenegociacaoAberto, setIsModalRenegociacaoAberto] =
    useState(false);
  const [valorPago, setValorPago] = useState(0);
  const [valorBase, setValorBase] = useState(0);
  const [valorAtualRenegociacao, setValorAtualRenegociacao] = useState(0);
  const [isModalPagamentoAberto, setIsModalPagamentoAberto] = useState(false);
  const [isModalHistoricoPagamentoAberto, setIsModalHistoricoPagamentoAberto] =
    useState(false);
  const [
    isModalHistoricoRenegociacaoAberto,
    setIsModalHistoricoRenegociacaoAberto,
  ] = useState(false);

  const navigate = useNavigate();
  const Swal = useSwalWrapper();

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const { hasPermission } = useContext(PermissionContext);
  if (!hasPermission("Contratos", "read")) {
    navigate("/app");
  }

  const modalAlert = (id) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        handleDeleteContrato(id);
      }
    });
  };

  const handleOpenModalRenegociacao = () => {
    setIsModalRenegociacaoAberto(!isModalRenegociacaoAberto);
  };

  const handleOpenModalPagamento = () => {
    setIsModalPagamentoAberto(!isModalPagamentoAberto);
  };

  const handleOpenModalHistoricoPagamento = () => {
    setIsModalHistoricoPagamentoAberto(!isModalHistoricoPagamentoAberto);
  };

  const handleOpenModalHistoricoRenegociacao = () => {
    setIsModalHistoricoRenegociacaoAberto(!isModalHistoricoRenegociacaoAberto);
  };

  const handleAtualizarValorPago = (pagamento, acao) => {
    const { valor, id } = pagamento;

    if (acao === "remocao") {
      const novoPagamentosContrato = contrato.pagamentoscontrato.filter(
        (pag) => pag.id !== id
      );
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: novoPagamentosContrato,
      };
      setContrato(novoContrato);
      setValorPago((prev) => prev - valor);
    } else {
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: [...contrato.pagamentoscontrato, pagamento],
      };
      setContrato(novoContrato);
      setValorPago((prev) => prev + valor);
    }
  };

  const handleAtualizarValorBase = (valor) => {
    setValorBase(valor);
  };

  const handleAtualizarValorAtualComRenegociacao = (dados, acao) => {
    switch (acao) {
      case "adicao":
        setContrato((prevContrato) => ({
          ...prevContrato,
          renegociacoescontrato: [...prevContrato.renegociacoescontrato].concat(
            dados
          ),
        }));
        break;
      case "remocao":
        setContrato((prevContrato) => ({
          ...prevContrato,
          renegociacoescontrato: dados,
        }));
        break;
    }
  };

  const handleAtualizarValorPagoAposEdicao = (idPagamento, novoValor) => {
    if (contrato && contrato.pagamentoscontrato) {
      const novoContrato = {
        ...contrato,
        pagamentoscontrato: contrato.pagamentoscontrato.map((pagamento) =>
          pagamento.id === idPagamento
            ? { ...pagamento, valor: novoValor }
            : pagamento
        ),
      };
      setContrato(novoContrato);
    }
  };

  const handleAtualizarRenegociacaoAposEdicao = (
    idRenegociacao,
    valor,
    parcelas
  ) => {
    if (contrato && contrato.renegociacoescontrato) {
      const novoContrato = {
        ...contrato,
        renegociacoescontrato: contrato.renegociacoescontrato.map(
          (renegociacao) =>
            renegociacao.id === idRenegociacao
              ? {
                  ...renegociacao,
                  valor,
                  numeroParcelas: parcelas,
                }
              : renegociacao
        ),
      };
      setContrato(novoContrato);
    }
  };

  const handleGetContratoDetalhes = useCallback(async () => {
    try {
      await ApiService.get(`/contratos/${id}`).then((response) => {
        if (response.status === 200) {
          setContrato(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, [id]);

  const handleDeleteContrato = async () => {
    try {
      await ApiService.delete(`/contratos/${id}`).then((response) => {
        if (response.status === 200) {
          navigate("/app/listar-contratos");
          toast("success", "Contrato excluído!");
        }
      });
    } catch (error) {
      toast("error", "Erro ao excluir o contrato!");
      console.log(error);
    }
  };

  const handleCalcularValorTotalContrato = () => {
    let valorTotal = 0;
  
    if (contrato?.renegociacoescontrato?.length > 0) {
      const ultimaRenegociacao = contrato?.renegociacoescontrato[contrato?.renegociacoescontrato.length - 1];
      valorTotal = ultimaRenegociacao?.valor ?? 0;
    } else {
      contrato?.servicos?.forEach((servico) => {
        const quantidade = servico?.quantidade ?? 0;
        const valor = servico?.valor ?? 0;
        valorTotal += quantidade * valor;
      });
    }
  
    setValorTotalContrato(
      Number(valorTotal).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    );
  };
  

  useEffect(() => {
    handleGetContratoDetalhes();
  }, []);

  useEffect(() => {
    if (contrato) {
      handleCalcularValorTotalContrato();
    }
  }, [contrato]);

  useEffect(() => {
    if (contrato && contrato.pagamentoscontrato) {
      const novoValorPago = contrato.pagamentoscontrato.reduce(
        (total, pagamento) => total + pagamento.valor,
        0
      );
      setValorPago(novoValorPago);
    } else {
      setValorPago(0);
    }
  }, [contrato]);

  useEffect(() => {
    if (contrato && contrato.servicos && contrato.servicos.length > 0) {
      let somaValorBase = 0;
      contrato.servicos.forEach((servico) => {
        somaValorBase += servico.valor * servico.quantidade;
        handleAtualizarValorBase(somaValorBase);
      });
    }
  }, [contrato]);

  useEffect(() => {
    if (
      contrato &&
      contrato.renegociacoescontrato &&
      contrato.renegociacoescontrato.length > 0
    ) {
      const tamanhoListaRenegociacoes =
        contrato.renegociacoescontrato.length - 1;
      const valorRenegociacao =
        contrato.renegociacoescontrato[tamanhoListaRenegociacoes].valor;
      setValorAtualRenegociacao(valorRenegociacao);
    }
  }, [contrato]);

  return (
    <JumboContentLayout>
      <Stack width={"100%"}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to={"/app/listar-contratos"} color="success" style={linkStyle}>
            Contratos
          </Link>
          {contrato && (
            <Link
              to={`/app/contrato/${contrato?.id}/detalhes`}
              color="success"
              style={linkStyle}
            >
              {contrato?.descricao}
            </Link>
          )}
        </Breadcrumbs>
      </Stack>
      <Paper width="100%" sx={{ padding: 3 }}>
        <Grid container spacing={2} flexDirection={"column"}>
          <DetalhesContrato contrato={contrato} />
          <Divider sx={{ my: 2 }} />
          <DetalhesClienteContrato contrato={contrato} />
          <Divider sx={{ my: 2 }} />
          <Grid item xs={12} p={2}>
            <Paper
              width="100%"
              sx={{ justifyContent: "flex-end", alignItems: "flex-end", p: 2 }}
            >
              <Typography variant={"h4"} fontWeight={600}>
                Valor Total do Contrato: {valorTotalContrato}
              </Typography>
              {contrato?.tipocontratocontrato?.some(
                (tipoContrato) =>
                  String(
                    tipoContrato?.tipos_contratos?.descricao
                  ).toLowerCase() === "contrato fixo"
              ) || (
                <Paper elevation={2} style={{ padding: 10, marginTop: 20 }}>
                  <Typography variant={"h4"} fontWeight={600}>
                    Pagamentos e Renegociações
                  </Typography>
                  <Grid container spacing={8}>
                    <Grid
                      item
                      lg={6}
                      display={"flex"}
                      gap={1}
                      justifyContent={"space-between"}
                    >
                      <Box width={"50%"}>
                        <Chip
                          color="warning"
                          icon={
                            <Paid fontSize="large" style={{ color: "white" }} />
                          }
                          style={{
                            width: "100%",
                            height: "60px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            padding: 10,
                          }}
                          label={
                            <Typography fontSize={"14px"}>
                              Valor atual:{" "}
                              {contrato?.renegociacoescontrato?.length > 0 ? (
                                <>
                                  {valorAtualRenegociacao.toLocaleString(
                                    "pt-BR",
                                    {
                                      style: "currency",
                                      currency: "BRL",
                                    }
                                  )}{" "}
                                  em{" "}
                                  {`${
                                    contrato.renegociacoescontrato[
                                      contrato.renegociacoescontrato.length - 1
                                    ].numeroParcelas
                                  }x`}
                                </>
                              ) : (
                                <>
                                  {valorBase.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </>
                              )}
                            </Typography>
                          }
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Stack spacing={1} direction="row">
                          <Button
                            size="medium"
                            variant="contained"
                            color="success"
                            startIcon={<PaidOutlined />}
                            onClick={handleOpenModalRenegociacao}
                          >
                            Renegociar
                          </Button>
                          <Button
                            size="medium"
                            variant="outlined"
                            color="info"
                            startIcon={<Topic />}
                            onClick={handleOpenModalHistoricoRenegociacao}
                          >
                            Histórico
                          </Button>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item lg={6} display={"flex"} gap={2}>
                      <Box width={"50%"}>
                        <Chip
                          color="warning"
                          icon={
                            <Paid fontSize="large" style={{ color: "white" }} />
                          }
                          style={{
                            width: "100%",
                            height: "60px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            padding: 10,
                          }}
                          label={
                            <Typography fontSize={"14px"}>
                              Valor pago:{" "}
                              {valorPago.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            </Typography>
                          }
                        />
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Stack spacing={2} direction="row">
                          <Button
                            size="medium"
                            variant="outlined"
                            color="info"
                            startIcon={<Topic />}
                            onClick={handleOpenModalHistoricoPagamento}
                            isReadOnly={true}
                          >
                            Histórico
                          </Button>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                  <ModalRenegociarContrato
                    aberto={isModalRenegociacaoAberto}
                    handleClose={handleOpenModalRenegociacao}
                    contratoId={Number(id)}
                    atualizarValorRenegociacao={
                      handleAtualizarValorAtualComRenegociacao
                    }
                    valorAtualContrato={valorBase}
                  />
                  <ModalHistoricoRenegociacaoContrato
                    aberto={isModalHistoricoRenegociacaoAberto}
                    handleClose={handleOpenModalHistoricoRenegociacao}
                    contratoId={id}
                    atualizarValorRenegociacao={
                      handleAtualizarValorAtualComRenegociacao
                    }
                    valorBase={valorBase}
                    atualizarValorAposEdicao={
                      handleAtualizarRenegociacaoAposEdicao
                    }
                  />
                  <ModalPagamentoContrato
                    aberto={isModalPagamentoAberto}
                    handleClose={handleOpenModalPagamento}
                    contratoId={id}
                    atualizarValorPago={handleAtualizarValorPago}
                  />
                  <ModalHistoricoPagamentoContrato
                    aberto={isModalHistoricoPagamentoAberto}
                    handleClose={handleOpenModalHistoricoPagamento}
                    contratoId={id}
                    atualizarValorPago={handleAtualizarValorPago}
                    atualizarValorAposEdicao={
                      handleAtualizarValorPagoAposEdicao
                    }
                  />
                </Paper>
              )}
            </Paper>
          </Grid>
          <Divider my={2} />
          <ContratoDetalhesServicos contrato={contrato} />
        </Grid>
        <Grid container my={2}>
          <Stack
            width="100%"
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                modalAlert(id);
              }}
            >
              Excluir contrato
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate(`/app/listar-contratos`);
              }}
            >
              Voltar
            </Button>
          </Stack>
        </Grid>
      </Paper>
    </JumboContentLayout>
  );
};

export default ContratoDetalhes;
