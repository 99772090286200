import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import { useJumboTheme } from "@jumbo/hooks";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {
  Box,
  Breadcrumbs,
  Button,
  FormGroup,
  Grid,
  InputLabel,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ApiService from "app/services/config";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { ErrorMessage, Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import CreatableSelect from "react-select/creatable";
import moment from "moment";

const linkStyle = {
  color: "#302D2E",
  fontSize: 20,
  textDecoration: "none",
  "&:visited": {
    color: "#302D2E",
  },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "transparent" : "white",
    border: state.isFocused
      ? "1px solid #005D5F"
      : "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "8px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "black",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#005D5F" : "white",
    color: state.isSelected ? "white" : "black",
    "&:hover": {
      backgroundColor: "lightblue",
      color: "white",
    },
  }),
};

const validationSchema = yup.object().shape({
  userId: yup.number().required("Campo obrigatório"),
  dataVencimento: yup.string().required("Campo obrigatório"),
  status: yup.string().required("Campo obrigatório"),
  nome_licenca_id: yup.number().required("Campo obrigatório"),
  numero: yup.string().required("Campo obrigatório"),
  data_emissao: yup
    .date()
    .required("Campo obrigatório")
    .typeError("Data inválida"),
  data_expiracao: yup
    .date()
    .required("Campo obrigatório")
    .typeError("Data inválida"),
  observacao: yup.string().required("Campo obrigatório"),
  anexo: yup.string().required("Campo obrigatório"),
  termo_fat: yup.number().required("Campo obrigatório"),
  processo_contrato_id: yup.number().required("Campo obrigatório"),
});

const LicencasContratoForm = () => {
  const { id, contratoId, processoId, clienteId, licencaId, procadmin } =
    useParams();
  const userId = Number(localStorage.getItem("id"));
  const initialValues = {
    userId: userId,
    dataVencimento: null,
    status: "",
    nome_licenca_id: "",
    numero: "",
    data_emissao: null,
    data_expiracao: null,
    observacao: "",
    anexo: "",
    termo_fat: 0,
    processo_contrato_id: Number(processoId),
  };
  const [licenca, setLicenca] = useState(initialValues);
  const [nomesLicencas, setNomesLicencas] = useState([]);
  const [breadCrumbsInfo, setBreadCrumbsInfo] = useState([]);
  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const navigate = useNavigate();
  const Swal = useSwalWrapper();
  const location = useLocation();
  const { theme } = useJumboTheme();

  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up("lg")]: {
            position: "sticky",
            zIndex: 5,
            top: 96,
            minHeight: "auto",
          },
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: "flex-start",
        },
      },
    }),
    [theme]
  );

  const toast = (variant, message, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true,
      closeButtonAriaLabel: "Fechar",
      timer: 3000,
    });
  };

  const handleSubmit = async (values) => {
    try {
      const valuesEdited = values;
      delete valuesEdited.nome_licenca;

      if (licencaId) {
        await ApiService.put(`/licenca/${licencaId}`, valuesEdited).then(
          (response) => {
            if (response.status === 200) {
              toast("success", "Atualizado com sucesso!");
              navigate(
                `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas`
              );
            }
          }
        );
      } else {
        await ApiService.post(`/licenca/criar`, valuesEdited).then(
          (response) => {
            if (response.status === 201) {
              toast("success", "Criado com sucesso!");
              navigate(
                `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas`
              );
            }
          }
        );
      }
    } catch (error) {
      toast("error", "Ocorreu um erro!");
      console.log(error);
    }
  };

  const getLicenca = async () => {
    try {
      await ApiService.get(`/licenca/${licencaId}`).then((response) => {
        if (response.status === 200) {
          setLicenca({
            ...response.data,
            data_emissao: moment
              .utc(response.data?.data_emissao)
              .format("YYYY-MM-DD"),
            data_expiracao: moment
              .utc(response.data?.data_expiracao)
              .format("YYYY-MM-DD"),
            dataVencimento: moment
              .utc(response.data?.dataVencimento)
              .format("YYYY-MM-DD"),
            userId: Number(userId),
            processo_contrato_id: Number(processoId),
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const modalAlertDeleteLicenca = (licencaId) => {
    Swal.fire({
      title: "Tem certeza que deseja apagar a licença?",
      text: "Não será póssível reverter a ação!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim!",
      cancelButtonText: "Não!",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteLicenca(licencaId);
      }
    });
  };

  const deleteLicenca = useCallback(async (licencaId) => {
    try {
      ApiService.delete(`/licenca/${licencaId}`)
        .then((response) => {
          toast("success", "Removido com sucesso");
          navigate(
            `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas`
          );
        })
        .catch((error) => {
          const message = error.response.data.message;
          toast("error", message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("error", error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getNomesLicencas = async () => {
    try {
      await ApiService.get("/nomes/licenca/all").then((response) => {
        if (response.status === 200) {
          setNomesLicencas(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateNomeLicenca = useCallback(async (descricao) => {
    const response = await ApiService.post("/nomes/licenca", {
      nome: descricao,
    })
      .then((response) => {
        if (response.status === 201) {
          toast("success", "Criado com sucesso!");
          getNomesLicencas();
          return response.data;
        }
      })
      .catch((error) => {
        toast("error", error.message);
      });
    return response;
  });

  useEffect(() => {
    if (licencaId) {
      getLicenca();
    }
    getNomesLicencas();
    if (location && String(location?.pathname).includes("deletar")) {
      setIsDeleteMode(true);
    }
  }, [licencaId]);

  const getBreadCrumbsInfo = async () => {
    try {
      return await ApiService.get(
        `/contratos/breadcrumbs?contratoId=${contratoId}&processoId=${processoId}&licenca=${licencaId}`
      ).then((response) => {
        if (response.status === 200) {
          setBreadCrumbsInfo(response.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contratoId) {
      getBreadCrumbsInfo();
    }
  }, [contratoId]);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      {lg && (
        <Stack spacing={2} direction={"row"} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Stack width={"100%"}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to={"/app/listar-contratos"} color="success" style={linkStyle}>
            Contratos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/contrato/${contratoId}/detalhes`}
              color="success"
              style={linkStyle}
            >
              {breadCrumbsInfo?.descricao}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos`}
            color="primary"
            style={linkStyle}
          >
            Processos Administrativos
          </Link>
          {breadCrumbsInfo && (
            <Link
              to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/editar/${processoId}`}
              color="primary"
              style={linkStyle}
            >
              {
                breadCrumbsInfo?.processos_contratos?.[0]
                  ?.processo_administrativo?.descricao
              }{" "}
            </Link>
          )}
          <Link
            to={`/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas/`}
            color="primary"
            style={linkStyle}
          >
            Licenças
          </Link>
          <Link
            to={
              processoId
                ? `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas/${licencaId}/editar`
                : `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas/nova`
            }
            color="primary"
            style={linkStyle}
          >
            {licencaId ? "Editar" : "Nova"} Licença
          </Link>
        </Breadcrumbs>
      </Stack>
      <Paper sx={{ p: "15px" }}>
        <Formik
          initialValues={licenca}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={true}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue, errors }) => (
            <Form style={{ width: "100%" }}>
              <Grid container alignContent={"center"} spacing={2}>
                <Grid item lg={3}>
                  <FormGroup>
                    <InputLabel>Observação</InputLabel>
                    <TextField
                      name="observacao"
                      fullWidth
                      value={values.observacao}
                      disabled={isDeleteMode}
                      onChange={handleChange}
                      placeholder="Observação"
                    />
                    <ErrorMessage
                      name="observacao"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item lg={3} xs={12}>
                  <Typography>Licença</Typography>
                  <div style={{ position: "relative" }}>
                    <CreatableSelect
                      isClearable
                      styles={customStyles}
                      isDisabled={isDeleteMode}
                      name={`nome_licenca_id`}
                      onChange={(option) => {
                        setFieldValue(
                          `nome_licenca_id`,
                          option ? option.id : ""
                        );
                      }}
                      onCreateOption={async (value) => {
                        const { id } = await handleCreateNomeLicenca(value);
                        setFieldValue(`nome_licenca_id`, id);
                      }}
                      options={nomesLicencas}
                      getOptionLabel={(option) =>
                        option.__isNew__ ? option.label : option.nome
                      }
                      value={nomesLicencas.find(
                        (licenca) =>
                          licenca.id ===
                          (values.nome_licenca_id ? values.nome_licenca_id : "")
                      )}
                      placeholder={"Selecione a Licença"}
                    />
                    <ErrorMessage
                      component={"div"}
                      name={`nome_licenca_id`}
                      style={{ color: "red" }}
                    />
                  </div>
                </Grid>
                <Grid item lg={3}>
                  <FormGroup>
                    <InputLabel>Status</InputLabel>
                    <TextField
                      name="status"
                      type="text"
                      fullWidth
                      disabled={isDeleteMode}
                      value={values.status}
                      onChange={handleChange}
                      placeholder="Status"
                    />
                    <ErrorMessage
                      name="status"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item lg={3}>
                  <FormGroup>
                    <InputLabel>Número</InputLabel>
                    <TextField
                      name="numero"
                      type="text"
                      fullWidth
                      disabled={isDeleteMode}
                      value={values.numero}
                      onChange={handleChange}
                      placeholder="Número"
                    />
                    <ErrorMessage
                      name="numero"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item lg={3}>
                  <FormGroup>
                    <InputLabel>Anexo</InputLabel>
                    <TextField
                      name="anexo"
                      type="text"
                      fullWidth
                      disabled={isDeleteMode}
                      value={values.anexo}
                      onChange={handleChange}
                      placeholder="Anexo"
                    />
                    <ErrorMessage
                      name="anexo"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item lg={1}>
                  <FormGroup>
                    <InputLabel>Termo FAT</InputLabel>
                    <TextField
                      name="termo_fat"
                      type="number"
                      fullWidth
                      disabled={isDeleteMode}
                      value={values.termo_fat}
                      onChange={handleChange}
                      placeholder="Termo FAT"
                    />
                    <ErrorMessage
                      name="termo_fat"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item lg={2}>
                  <FormGroup>
                    <InputLabel>Data de Vencimento</InputLabel>
                    <TextField
                      name="dataVencimento"
                      type="date"
                      fullWidth
                      disabled={isDeleteMode}
                      value={values.dataVencimento}
                      onChange={handleChange}
                      placeholder="Data de Vencimento"
                    />
                    <ErrorMessage
                      name="dataVencimento"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item lg={2}>
                  <FormGroup>
                    <InputLabel>Data de Expiração</InputLabel>
                    <TextField
                      name="data_expiracao"
                      type="date"
                      fullWidth
                      disabled={isDeleteMode}
                      value={values.data_expiracao}
                      onChange={handleChange}
                      placeholder="Data de Expiração"
                    />
                    <ErrorMessage
                      name="data_expiracao"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item lg={2}>
                  <FormGroup>
                    <InputLabel>Data de Emissão</InputLabel>
                    <TextField
                      name="data_emissao"
                      type="date"
                      fullWidth
                      disabled={isDeleteMode}
                      value={values.data_emissao}
                      onChange={handleChange}
                      placeholder="Data de Emissão"
                    />
                    <ErrorMessage
                      name="data_emissao"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </FormGroup>
                </Grid>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  marginTop={3}
                >
                  {isDeleteMode ? (
                    <Stack direction="row" gap={2} display={"flex"}>
                      <Box>
                        <Button
                          color="error"
                          variant="contained"
                          onClick={() => {
                            modalAlertDeleteLicenca(licencaId);
                          }}
                        >
                          Excluir processo
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            navigate(
                              `/app/contrato/${contratoId}/cliente/${clienteId}/processos/${processoId}/procadmin/${procadmin}/licencas`
                            );
                          }}
                        >
                          Cancelar
                        </Button>
                      </Box>
                    </Stack>
                  ) : (
                    <LoadingButton
                      loading={isSubmitting}
                      type="submit"
                      style={{ margin: "auto", display: "block" }}
                      variant="contained"
                      color="primary"
                    >
                      {licencaId ? "Atualizar" : "Criar"}
                    </LoadingButton>
                  )}
                </Box>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default LicencasContratoForm;
